import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { grey } from '@mui/material/colors';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Container, IconButton, Box, Stack, Divider, Button } from '@mui/material';
import { Remove, Add } from '@mui/icons-material';
import { CartContext } from '../context/CartContext'; // Import the CartContext

function ProductPage() {
  const { reference } = useParams(); // Use useParams to get category and subcategory
  const [product, setProduct] = useState({});
  const [images, setImages] = useState([]);
  const [number, setNumber] = useState(1);
  const [disabledPlus, setDisabledPlus] = useState(false);
  const [disabledMinus, setDisabledMinus] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { handleOpenCart, refreshCartLenght } = useContext(CartContext); // Use the context

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_ADDRESS}/product/single/${reference}`
      )
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          setProduct(response.data);
          const cart = JSON.parse(localStorage.getItem('cart')) || [];
          const existingProductIndex = cart.findIndex((item) => item.id === response.data.id);
          if (existingProductIndex !== -1) {
            setNumber(cart[existingProductIndex].quantity);
          }

          if (response.data.quantity === 0) {
            setNumber(0);
            setDisabledPlus(true);
            setDisabledMinus(true);
            setDisabledButton(true);
          }
          let imageUrls;
          imageUrls = response.data.images.map((image) => ({
            original: `${process.env.REACT_APP_ADDRESS}/uploads/${image}`,
            thumbnail: `${process.env.REACT_APP_ADDRESS}/thumbnail/${image}`,
          }));
          if (response.data.images.length === 0) {
            imageUrls = [
              {
                original: `${process.env.REACT_APP_ADDRESS}/uploads/indisponible.png`,
                thumbnail: `${process.env.REACT_APP_ADDRESS}/thumbnail/indisponible.png`,
              },
            ];
          }
          setImages(imageUrls);
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle the error, e.g., redirect to an error page or show a relevant message to the Product.
      });
  }, [reference]);

  const jsonData = [
    { title: 'marque', name: product.brand },
    { title: 'type', name: product.type },
    { title: 'categorie', name: product.category },
    { title: 'sous categorie', name: product.subCategory },
  ];

  const orderQuantity = (state) => {
    if (state) {
      // Increase quantity
      if (number < product.quantity) {
        setNumber(number + 1);
        setDisabledMinus(false); // Enable minus button if previously disabled
      }
      if (number + 1 >= product.quantity) {
        setDisabledPlus(true); // Disable plus button if the limit is reached
      }
    } else {
      // Decrease quantity
      if (number > 1) {
        setNumber(number - 1);
        setDisabledPlus(false); // Enable plus button if previously disabled
      }
      if (number - 1 <= 1) {
        setDisabledMinus(true); // Disable minus button if the limit is reached
      }
    }
  };

  const addToCart = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const existingProductIndex = cart.findIndex((item) => item.id === product.id);
    if (existingProductIndex !== -1) {
      const newQuantity = number;
      if (newQuantity <= product.quantity) {
        cart[existingProductIndex].quantity = newQuantity;
      } else {
        cart[existingProductIndex].quantity = product.quantity;
      }
      setNumber(cart[existingProductIndex].quantity);
    } else {
      cart.push({ id: product.id, quantity: number });
    }
    localStorage.setItem('cart', JSON.stringify(cart));
    refreshCartLenght()
  };

  const handleBuyNow = () => {
    addToCart()
    handleOpenCart();
  };

  return (
    <Container sx={{ mt: 2, pl: 0, pr: 0 }}>
      <Paper sx={{ p: 2 }} elevation={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <ImageGallery items={images} />
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="h3">{product.title}</Typography>
            {product.quantity === 0 && (
              <Typography color="error" variant="h6">
                Indisponible
              </Typography>
            )}
            <Typography variant="h6">
              {product.quantity > 0 && product.quantity}{' '}
              {product.quantity === 1 ? 'restant' : product.quantity > 1 && 'restants'}
            </Typography>
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }} mt={2} gutterBottom>
              {product.description}
            </Typography>
            <Divider />
            <Stack sx={{ mb: 1, mt: 1 }} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Quantité:
              </Typography>
              <Box
                sx={{
                  border: '1px solid black', // Add a solid black border
                  borderRadius: '15px', // Optional: Add some border-radius if you like
                  borderColor: grey[300],
                }}
              >
                <IconButton
                  aria-label="delete"
                  sx={{ pr: 1 }}
                  disabled={disabledMinus}
                  onClick={() => {
                    orderQuantity(false);
                  }}
                  size="small"
                >
                  <Remove fontSize="inherit" />
                </IconButton>
                {number}
                <IconButton
                  disabled={disabledPlus}
                  onClick={() => {
                    orderQuantity(true);
                  }}
                  aria-label="delete"
                  sx={{ pl: 1 }}
                  size="small"
                >
                  <Add fontSize="inherit" />
                </IconButton>
              </Box>
            </Stack>
            <Divider />
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={6} lg={6}>
                <Button fullWidth onClick={addToCart} disabled={disabledButton} color="secondary" variant="contained" size="large" sx={{ mr: 1 }}>
                  Ajouter au panier
                </Button>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Button onClick={handleBuyNow} fullWidth disabled={disabledButton} color="warning" variant="contained" size="large" sx={{ ml: 1 }}>
                  Acheter maintenant
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Paper sx={{ mt: 2 }} elevation={8}>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {jsonData.map((row) => (
                  <TableRow key={row.title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Paper>
    </Container>
  );
}

export default ProductPage;
