import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { Paper } from '@mui/material';
import { orange } from '@mui/material/colors';

import backgroundImg from '../../components/images/image.png';
import image from "../../components/images/backgroundr.jpg";
import Nav from './nav';
import Footer from './footer';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  overflow: 'hidden',
  backgroundColor: '#eee9de',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      light: "#fefae0",
      main: "#dda15e",
      dark: "#bc6c25",
      contrastText: "#000",
    },
    secondary: {
      light: "#a3b18a",
      main: "#606c38",
      dark: "#283618",
      contrastText: "#fff",
    },
  },
});

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
      <StyledRoot>
        <CssBaseline />
        <Nav />
        <Main>
          <Outlet />
        </Main>
        <Footer />
      </StyledRoot>
  );
}
