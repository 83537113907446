// ----------------------------------------------------------------------

const communes = [
  { id: '1', code_postal: '01001', nom: 'Adrar', wilaya_id: '1' },
  { id: '2', code_postal: '01002', nom: 'Tamest', wilaya_id: '1' },
  { id: '3', code_postal: '01003', nom: 'Charouine', wilaya_id: '1' },
  { id: '4', code_postal: '01004', nom: 'Reggane', wilaya_id: '1' },
  { id: '5', code_postal: '01005', nom: 'Inozghmir', wilaya_id: '1' },
  { id: '6', code_postal: '01006', nom: 'Tit', wilaya_id: '1' },
  { id: '7', code_postal: '01007', nom: 'Ksar Kaddour', wilaya_id: '1' },
  { id: '8', code_postal: '01008', nom: 'Tsabit', wilaya_id: '1' },
  { id: '9', code_postal: '01009', nom: 'Timimoun', wilaya_id: '1' },
  { id: '10', code_postal: '01010', nom: 'Ouled Said', wilaya_id: '1' },
  { id: '11', code_postal: '01011', nom: 'Zaouiet Kounta', wilaya_id: '1' },
  { id: '12', code_postal: '01012', nom: 'Aoulef', wilaya_id: '1' },
  { id: '13', code_postal: '01013', nom: 'Timokten', wilaya_id: '1' },
  { id: '14', code_postal: '01014', nom: 'Tamentit', wilaya_id: '1' },
  { id: '15', code_postal: '01015', nom: 'Fenoughil', wilaya_id: '1' },
  { id: '16', code_postal: '01016', nom: 'Tinerkouk', wilaya_id: '1' },
  { id: '17', code_postal: '01017', nom: 'Deldoul', wilaya_id: '1' },
  { id: '18', code_postal: '01018', nom: 'Sali', wilaya_id: '1' },
  { id: '19', code_postal: '01019', nom: 'Akabli', wilaya_id: '1' },
  { id: '20', code_postal: '01020', nom: 'Metarfa', wilaya_id: '1' },
  { id: '21', code_postal: '01021', nom: 'O Ahmed Timmi', wilaya_id: '1' },
  { id: '22', code_postal: '01022', nom: 'Bouda', wilaya_id: '1' },
  { id: '23', code_postal: '01023', nom: 'Aougrout', wilaya_id: '1' },
  { id: '24', code_postal: '01024', nom: 'Talmine', wilaya_id: '1' },
  { id: '25', code_postal: '01025', nom: 'B Badji Mokhtar', wilaya_id: '1' },
  { id: '26', code_postal: '01026', nom: 'Sbaa', wilaya_id: '1' },
  { id: '27', code_postal: '01027', nom: 'Ouled Aissa', wilaya_id: '1' },
  { id: '28', code_postal: '01028', nom: 'Timiaouine', wilaya_id: '1' },
  { id: '29', code_postal: '02001', nom: 'Chlef', wilaya_id: '2' },
  { id: '30', code_postal: '02002', nom: 'Tenes', wilaya_id: '2' },
  { id: '31', code_postal: '02003', nom: 'Benairia', wilaya_id: '2' },
  { id: '32', code_postal: '02004', nom: 'El Karimia', wilaya_id: '2' },
  { id: '33', code_postal: '02005', nom: 'Tadjna', wilaya_id: '2' },
  { id: '34', code_postal: '02006', nom: 'Taougrite', wilaya_id: '2' },
  { id: '35', code_postal: '02007', nom: 'Beni Haoua', wilaya_id: '2' },
  { id: '36', code_postal: '02008', nom: 'Sobha', wilaya_id: '2' },
  { id: '37', code_postal: '02009', nom: 'Harchoun', wilaya_id: '2' },
  { id: '38', code_postal: '02010', nom: 'Ouled Fares', wilaya_id: '2' },
  { id: '39', code_postal: '02011', nom: 'Sidi Akacha', wilaya_id: '2' },
  { id: '40', code_postal: '02012', nom: 'Boukadir', wilaya_id: '2' },
  { id: '41', code_postal: '02013', nom: 'Beni Rached', wilaya_id: '2' },
  { id: '42', code_postal: '02014', nom: 'Talassa', wilaya_id: '2' },
  { id: '43', code_postal: '02015', nom: 'Herenfa', wilaya_id: '2' },
  { id: '44', code_postal: '02016', nom: 'Oued Goussine', wilaya_id: '2' },
  { id: '45', code_postal: '02017', nom: 'Dahra', wilaya_id: '2' },
  { id: '46', code_postal: '02018', nom: 'Ouled Abbes', wilaya_id: '2' },
  { id: '47', code_postal: '02019', nom: 'Sendjas', wilaya_id: '2' },
  { id: '48', code_postal: '02020', nom: 'Zeboudja', wilaya_id: '2' },
  { id: '49', code_postal: '02021', nom: 'Oued Sly', wilaya_id: '2' },
  { id: '50', code_postal: '02022', nom: 'Abou El Hassen', wilaya_id: '2' },
  { id: '51', code_postal: '02023', nom: 'El Marsa', wilaya_id: '2' },
  { id: '52', code_postal: '02024', nom: 'Chettia', wilaya_id: '2' },
  { id: '53', code_postal: '02025', nom: 'Sidi Abderrahmane', wilaya_id: '2' },
  { id: '54', code_postal: '02026', nom: 'Moussadek', wilaya_id: '2' },
  { id: '55', code_postal: '02027', nom: 'El Hadjadj', wilaya_id: '2' },
  { id: '56', code_postal: '02028', nom: 'Labiod Medjadja', wilaya_id: '2' },
  { id: '57', code_postal: '02029', nom: 'Oued Fodda', wilaya_id: '2' },
  { id: '58', code_postal: '02030', nom: 'Ouled Ben Abdelkader', wilaya_id: '2' },
  { id: '59', code_postal: '02031', nom: 'Bouzghaia', wilaya_id: '2' },
  { id: '60', code_postal: '02032', nom: 'Ain Merane', wilaya_id: '2' },
  { id: '61', code_postal: '02033', nom: 'Oum Drou', wilaya_id: '2' },
  { id: '62', code_postal: '02034', nom: 'Breira', wilaya_id: '2' },
  { id: '63', code_postal: '02035', nom: 'Ben Boutaleb', wilaya_id: '2' },
  { id: '64', code_postal: '03001', nom: 'Laghouat', wilaya_id: '3' },
  { id: '65', code_postal: '03002', nom: 'Ksar El Hirane', wilaya_id: '3' },
  { id: '66', code_postal: '03003', nom: 'Benacer Ben Chohra', wilaya_id: '3' },
  { id: '67', code_postal: '03004', nom: 'Sidi Makhlouf', wilaya_id: '3' },
  { id: '68', code_postal: '03005', nom: 'Hassi Delaa', wilaya_id: '3' },
  { id: '69', code_postal: '03006', nom: 'Hassi R Mel', wilaya_id: '3' },
  { id: '70', code_postal: '03007', nom: 'Ain Mahdi', wilaya_id: '3' },
  { id: '71', code_postal: '03008', nom: 'Tadjmout', wilaya_id: '3' },
  { id: '72', code_postal: '03009', nom: 'Kheneg', wilaya_id: '3' },
  { id: '73', code_postal: '03010', nom: 'Gueltat Sidi Saad', wilaya_id: '3' },
  { id: '74', code_postal: '03011', nom: 'Ain Sidi Ali', wilaya_id: '3' },
  { id: '75', code_postal: '03012', nom: 'Beidha', wilaya_id: '3' },
  { id: '76', code_postal: '03013', nom: 'Brida', wilaya_id: '3' },
  { id: '77', code_postal: '03014', nom: 'El Ghicha', wilaya_id: '3' },
  { id: '78', code_postal: '03015', nom: 'Hadj Mechri', wilaya_id: '3' },
  { id: '79', code_postal: '03016', nom: 'Sebgag', wilaya_id: '3' },
  { id: '80', code_postal: '03017', nom: 'Taouiala', wilaya_id: '3' },
  { id: '81', code_postal: '03018', nom: 'Tadjrouna', wilaya_id: '3' },
  { id: '82', code_postal: '03019', nom: 'Aflou', wilaya_id: '3' },
  { id: '83', code_postal: '03020', nom: 'El Assafia', wilaya_id: '3' },
  { id: '84', code_postal: '03021', nom: 'Oued Morra', wilaya_id: '3' },
  { id: '85', code_postal: '03022', nom: 'Oued M Zi', wilaya_id: '3' },
  { id: '86', code_postal: '03023', nom: 'El Haouaita', wilaya_id: '3' },
  { id: '87', code_postal: '03024', nom: 'Sidi Bouzid', wilaya_id: '3' },
  { id: '88', code_postal: '04001', nom: 'Oum El Bouaghi', wilaya_id: '4' },
  { id: '89', code_postal: '04002', nom: 'Ain Beida', wilaya_id: '4' },
  { id: '90', code_postal: '04003', nom: 'Ainmlila', wilaya_id: '4' },
  { id: '91', code_postal: '04004', nom: 'Behir Chergui', wilaya_id: '4' },
  { id: '92', code_postal: '04005', nom: 'El Amiria', wilaya_id: '4' },
  { id: '93', code_postal: '04006', nom: 'Sigus', wilaya_id: '4' },
  { id: '94', code_postal: '04007', nom: 'El Belala', wilaya_id: '4' },
  { id: '95', code_postal: '04008', nom: 'Ain Babouche', wilaya_id: '4' },
  { id: '96', code_postal: '04009', nom: 'Berriche', wilaya_id: '4' },
  { id: '97', code_postal: '04010', nom: 'Ouled Hamla', wilaya_id: '4' },
  { id: '98', code_postal: '04011', nom: 'Dhala', wilaya_id: '4' },
  { id: '99', code_postal: '04012', nom: 'Ain Kercha', wilaya_id: '4' },
  { id: '100', code_postal: '04013', nom: 'Hanchir Toumghani', wilaya_id: '4' },
  { id: '101', code_postal: '04014', nom: 'El Djazia', wilaya_id: '4' },
  { id: '102', code_postal: '04015', nom: 'Ain Diss', wilaya_id: '4' },
  { id: '103', code_postal: '04016', nom: 'Fkirina', wilaya_id: '4' },
  { id: '104', code_postal: '04017', nom: 'Souk Naamane', wilaya_id: '4' },
  { id: '105', code_postal: '04018', nom: 'Zorg', wilaya_id: '4' },
  { id: '106', code_postal: '04019', nom: 'El Fedjoudj Boughrar', wilaya_id: '4' },
  { id: '107', code_postal: '04020', nom: 'Ouled Zouai', wilaya_id: '4' },
  { id: '108', code_postal: '04021', nom: 'Bir Chouhada', wilaya_id: '4' },
  { id: '109', code_postal: '04022', nom: 'Ksar Sbahi', wilaya_id: '4' },
  { id: '110', code_postal: '04023', nom: 'Oued Nini', wilaya_id: '4' },
  { id: '111', code_postal: '04024', nom: 'Meskiana', wilaya_id: '4' },
  { id: '112', code_postal: '04025', nom: 'Ain Fekroune', wilaya_id: '4' },
  { id: '113', code_postal: '04026', nom: 'Rahia', wilaya_id: '4' },
  { id: '114', code_postal: '04027', nom: 'Ain Zitoun', wilaya_id: '4' },
  { id: '115', code_postal: '04028', nom: 'Ouled Gacem', wilaya_id: '4' },
  { id: '116', code_postal: '04029', nom: 'El Harmilia', wilaya_id: '4' },
  { id: '117', code_postal: '05001', nom: 'Batna', wilaya_id: '5' },
  { id: '118', code_postal: '05002', nom: 'Ghassira', wilaya_id: '5' },
  { id: '119', code_postal: '05003', nom: 'Maafa', wilaya_id: '5' },
  { id: '120', code_postal: '05004', nom: 'Merouana', wilaya_id: '5' },
  { id: '121', code_postal: '05005', nom: 'Seriana', wilaya_id: '5' },
  { id: '122', code_postal: '05006', nom: 'Menaa', wilaya_id: '5' },
  { id: '123', code_postal: '05007', nom: 'El Madher', wilaya_id: '5' },
  { id: '124', code_postal: '05008', nom: 'Tazoult', wilaya_id: '5' },
  { id: '125', code_postal: '05009', nom: 'Ngaous', wilaya_id: '5' },
  { id: '126', code_postal: '05010', nom: 'Guigba', wilaya_id: '5' },
  { id: '127', code_postal: '05011', nom: 'Inoughissen', wilaya_id: '5' },
  { id: '128', code_postal: '05012', nom: 'Ouyoun El Assafir', wilaya_id: '5' },
  { id: '129', code_postal: '05013', nom: 'Djerma', wilaya_id: '5' },
  { id: '130', code_postal: '05014', nom: 'Bitam', wilaya_id: '5' },
  { id: '131', code_postal: '05015', nom: 'Metkaouak', wilaya_id: '5' },
  { id: '132', code_postal: '05016', nom: 'Arris', wilaya_id: '5' },
  { id: '133', code_postal: '05017', nom: 'Kimmel', wilaya_id: '5' },
  { id: '134', code_postal: '05018', nom: 'Tilatou', wilaya_id: '5' },
  { id: '135', code_postal: '05019', nom: 'Ain Djasser', wilaya_id: '5' },
  { id: '136', code_postal: '05020', nom: 'Ouled Selam', wilaya_id: '5' },
  { id: '137', code_postal: '05021', nom: 'Tigherghar', wilaya_id: '5' },
  { id: '138', code_postal: '05022', nom: 'Ain Yagout', wilaya_id: '5' },
  { id: '139', code_postal: '05023', nom: 'Fesdis', wilaya_id: '5' },
  { id: '140', code_postal: '05024', nom: 'Sefiane', wilaya_id: '5' },
  { id: '141', code_postal: '05025', nom: 'Rahbat', wilaya_id: '5' },
  { id: '142', code_postal: '05026', nom: 'Tighanimine', wilaya_id: '5' },
  { id: '143', code_postal: '05027', nom: 'Lemsane', wilaya_id: '5' },
  { id: '144', code_postal: '05028', nom: 'Ksar Belezma', wilaya_id: '5' },
  { id: '145', code_postal: '05029', nom: 'Seggana', wilaya_id: '5' },
  { id: '146', code_postal: '05030', nom: 'Ichmoul', wilaya_id: '5' },
  { id: '147', code_postal: '05031', nom: 'Foum Toub', wilaya_id: '5' },
  { id: '148', code_postal: '05032', nom: 'Beni Foudhala El Hakania', wilaya_id: '5' },
  { id: '149', code_postal: '05033', nom: 'Oued El Ma', wilaya_id: '5' },
  { id: '150', code_postal: '05034', nom: 'Talkhamt', wilaya_id: '5' },
  { id: '151', code_postal: '05035', nom: 'Bouzina', wilaya_id: '5' },
  { id: '152', code_postal: '05036', nom: 'Chemora', wilaya_id: '5' },
  { id: '153', code_postal: '05037', nom: 'Oued Chaaba', wilaya_id: '5' },
  { id: '154', code_postal: '05038', nom: 'Taxlent', wilaya_id: '5' },
  { id: '155', code_postal: '05039', nom: 'Gosbat', wilaya_id: '5' },
  { id: '156', code_postal: '05040', nom: 'Ouled Aouf', wilaya_id: '5' },
  { id: '157', code_postal: '05041', nom: 'Boumagueur', wilaya_id: '5' },
  { id: '158', code_postal: '05042', nom: 'Barika', wilaya_id: '5' },
  { id: '159', code_postal: '05043', nom: 'Djezzar', wilaya_id: '5' },
  { id: '160', code_postal: '05044', nom: 'Tkout', wilaya_id: '5' },
  { id: '161', code_postal: '05045', nom: 'Ain Touta', wilaya_id: '5' },
  { id: '162', code_postal: '05046', nom: 'Hidoussa', wilaya_id: '5' },
  { id: '163', code_postal: '05047', nom: 'Teniet El Abed', wilaya_id: '5' },
  { id: '164', code_postal: '05048', nom: 'Oued Taga', wilaya_id: '5' },
  { id: '165', code_postal: '05049', nom: 'Ouled Fadel', wilaya_id: '5' },
  { id: '166', code_postal: '05050', nom: 'Timgad', wilaya_id: '5' },
  { id: '167', code_postal: '05051', nom: 'Ras El Aioun', wilaya_id: '5' },
  { id: '168', code_postal: '05052', nom: 'Chir', wilaya_id: '5' },
  { id: '169', code_postal: '05053', nom: 'Ouled Si Slimane', wilaya_id: '5' },
  { id: '170', code_postal: '05054', nom: 'Zanat El Beida', wilaya_id: '5' },
  { id: '171', code_postal: '05055', nom: 'Amdoukal', wilaya_id: '5' },
  { id: '172', code_postal: '05056', nom: 'Ouled Ammar', wilaya_id: '5' },
  { id: '173', code_postal: '05057', nom: 'El Hassi', wilaya_id: '5' },
  { id: '174', code_postal: '05058', nom: 'Lazrou', wilaya_id: '5' },
  { id: '175', code_postal: '05059', nom: 'Boumia', wilaya_id: '5' },
  { id: '176', code_postal: '05060', nom: 'Boulhilat', wilaya_id: '5' },
  { id: '177', code_postal: '05061', nom: 'Larbaa', wilaya_id: '5' },
  { id: '178', code_postal: '06001', nom: 'Bejaia', wilaya_id: '6' },
  { id: '179', code_postal: '06002', nom: 'Amizour', wilaya_id: '6' },
  { id: '180', code_postal: '06003', nom: 'Ferraoun', wilaya_id: '6' },
  { id: '181', code_postal: '06004', nom: 'Taourirt Ighil', wilaya_id: '6' },
  { id: '182', code_postal: '06005', nom: 'Chelata', wilaya_id: '6' },
  { id: '183', code_postal: '06006', nom: 'Tamokra', wilaya_id: '6' },
  { id: '184', code_postal: '06007', nom: 'Timzrit', wilaya_id: '6' },
  { id: '185', code_postal: '06008', nom: 'Souk El Thenine', wilaya_id: '6' },
  { id: '186', code_postal: '06009', nom: 'Mcisna', wilaya_id: '6' },
  { id: '187', code_postal: '06010', nom: 'Thinabdher', wilaya_id: '6' },
  { id: '188', code_postal: '06011', nom: 'Tichi', wilaya_id: '6' },
  { id: '189', code_postal: '06012', nom: 'Semaoun', wilaya_id: '6' },
  { id: '190', code_postal: '06013', nom: 'Kendira', wilaya_id: '6' },
  { id: '191', code_postal: '06014', nom: 'Tifra', wilaya_id: '6' },
  { id: '192', code_postal: '06015', nom: 'Ighram', wilaya_id: '6' },
  { id: '193', code_postal: '06016', nom: 'Amalou', wilaya_id: '6' },
  { id: '194', code_postal: '06017', nom: 'Ighil Ali', wilaya_id: '6' },
  { id: '195', code_postal: '06018', nom: 'Ifelain Ilmathen', wilaya_id: '6' },
  { id: '196', code_postal: '06019', nom: 'Toudja', wilaya_id: '6' },
  { id: '197', code_postal: '06020', nom: 'Darguina', wilaya_id: '6' },
  { id: '198', code_postal: '06021', nom: 'Sidi Ayad', wilaya_id: '6' },
  { id: '199', code_postal: '06022', nom: 'Aokas', wilaya_id: '6' },
  { id: '200', code_postal: '06023', nom: 'Beni Djellil', wilaya_id: '6' },
  { id: '201', code_postal: '06024', nom: 'Adekar', wilaya_id: '6' },
  { id: '202', code_postal: '06025', nom: 'Akbou', wilaya_id: '6' },
  { id: '203', code_postal: '06026', nom: 'Seddouk', wilaya_id: '6' },
  { id: '204', code_postal: '06027', nom: 'Tazmalt', wilaya_id: '6' },
  { id: '205', code_postal: '06028', nom: 'Ait Rizine', wilaya_id: '6' },
  { id: '206', code_postal: '06029', nom: 'Chemini', wilaya_id: '6' },
  { id: '207', code_postal: '06030', nom: 'Souk Oufella', wilaya_id: '6' },
  { id: '208', code_postal: '06031', nom: 'Taskriout', wilaya_id: '6' },
  { id: '209', code_postal: '06032', nom: 'Tibane', wilaya_id: '6' },
  { id: '210', code_postal: '06033', nom: 'Tala Hamza', wilaya_id: '6' },
  { id: '211', code_postal: '06034', nom: 'Barbacha', wilaya_id: '6' },
  { id: '212', code_postal: '06035', nom: 'Beni Ksila', wilaya_id: '6' },
  { id: '213', code_postal: '06036', nom: 'Ouzallaguen', wilaya_id: '6' },
  { id: '214', code_postal: '06037', nom: 'Bouhamza', wilaya_id: '6' },
  { id: '215', code_postal: '06038', nom: 'Beni Melikeche', wilaya_id: '6' },
  { id: '216', code_postal: '06039', nom: 'Sidi Aich', wilaya_id: '6' },
  { id: '217', code_postal: '06040', nom: 'El Kseur', wilaya_id: '6' },
  { id: '218', code_postal: '06041', nom: 'Melbou', wilaya_id: '6' },
  { id: '219', code_postal: '06042', nom: 'Akfadou', wilaya_id: '6' },
  { id: '220', code_postal: '06043', nom: 'Leflaye', wilaya_id: '6' },
  { id: '221', code_postal: '06044', nom: 'Kherrata', wilaya_id: '6' },
  { id: '222', code_postal: '06045', nom: 'Draa Kaid', wilaya_id: '6' },
  { id: '223', code_postal: '06046', nom: 'Tamridjet', wilaya_id: '6' },
  { id: '224', code_postal: '06047', nom: 'Ait Smail', wilaya_id: '6' },
  { id: '225', code_postal: '06048', nom: 'Boukhelifa', wilaya_id: '6' },
  { id: '226', code_postal: '06049', nom: 'Tizi Nberber', wilaya_id: '6' },
  { id: '227', code_postal: '06050', nom: 'Beni Maouch', wilaya_id: '6' },
  { id: '228', code_postal: '06051', nom: 'Oued Ghir', wilaya_id: '6' },
  { id: '229', code_postal: '06052', nom: 'Boudjellil', wilaya_id: '6' },
  { id: '230', code_postal: '07001', nom: 'Biskra', wilaya_id: '7' },
  { id: '231', code_postal: '07002', nom: 'Oumache', wilaya_id: '7' },
  { id: '232', code_postal: '07003', nom: 'Branis', wilaya_id: '7' },
  { id: '233', code_postal: '07004', nom: 'Chetma', wilaya_id: '7' },
  { id: '234', code_postal: '07005', nom: 'Ouled Djellal', wilaya_id: '7' },
  { id: '235', code_postal: '07006', nom: 'Ras El Miaad', wilaya_id: '7' },
  { id: '236', code_postal: '07007', nom: 'Besbes', wilaya_id: '7' },
  { id: '237', code_postal: '07008', nom: 'Sidi Khaled', wilaya_id: '7' },
  { id: '238', code_postal: '07009', nom: 'Doucen', wilaya_id: '7' },
  { id: '239', code_postal: '07010', nom: 'Ech Chaiba', wilaya_id: '7' },
  { id: '240', code_postal: '07011', nom: 'Sidi Okba', wilaya_id: '7' },
  { id: '241', code_postal: '07012', nom: 'Mchouneche', wilaya_id: '7' },
  { id: '242', code_postal: '07013', nom: 'El Haouch', wilaya_id: '7' },
  { id: '243', code_postal: '07014', nom: 'Ain Naga', wilaya_id: '7' },
  { id: '244', code_postal: '07015', nom: 'Zeribet El Oued', wilaya_id: '7' },
  { id: '245', code_postal: '07016', nom: 'El Feidh', wilaya_id: '7' },
  { id: '246', code_postal: '07017', nom: 'El Kantara', wilaya_id: '7' },
  { id: '247', code_postal: '07018', nom: 'Ain Zaatout', wilaya_id: '7' },
  { id: '248', code_postal: '07019', nom: 'El Outaya', wilaya_id: '7' },
  { id: '249', code_postal: '07020', nom: 'Djemorah', wilaya_id: '7' },
  { id: '250', code_postal: '07021', nom: 'Tolga', wilaya_id: '7' },
  { id: '251', code_postal: '07022', nom: 'Lioua', wilaya_id: '7' },
  { id: '252', code_postal: '07023', nom: 'Lichana', wilaya_id: '7' },
  { id: '253', code_postal: '07024', nom: 'Ourlal', wilaya_id: '7' },
  { id: '254', code_postal: '07025', nom: 'Mlili', wilaya_id: '7' },
  { id: '255', code_postal: '07026', nom: 'Foughala', wilaya_id: '7' },
  { id: '256', code_postal: '07027', nom: 'Bordj Ben Azzouz', wilaya_id: '7' },
  { id: '257', code_postal: '07028', nom: 'Meziraa', wilaya_id: '7' },
  { id: '258', code_postal: '07029', nom: 'Bouchagroun', wilaya_id: '7' },
  { id: '259', code_postal: '07030', nom: 'Mekhadma', wilaya_id: '7' },
  { id: '260', code_postal: '07031', nom: 'El Ghrous', wilaya_id: '7' },
  { id: '261', code_postal: '07032', nom: 'El Hadjab', wilaya_id: '7' },
  { id: '262', code_postal: '07033', nom: 'Khanguet Sidinadji', wilaya_id: '7' },
  { id: '263', code_postal: '08001', nom: 'Bechar', wilaya_id: '8' },
  { id: '264', code_postal: '08002', nom: 'Erg Ferradj', wilaya_id: '8' },
  { id: '265', code_postal: '08003', nom: 'Ouled Khoudir', wilaya_id: '8' },
  { id: '266', code_postal: '08004', nom: 'Meridja', wilaya_id: '8' },
  { id: '267', code_postal: '08005', nom: 'Timoudi', wilaya_id: '8' },
  { id: '268', code_postal: '08006', nom: 'Lahmar', wilaya_id: '8' },
  { id: '269', code_postal: '08007', nom: 'Beni Abbes', wilaya_id: '8' },
  { id: '270', code_postal: '08008', nom: 'Beni Ikhlef', wilaya_id: '8' },
  { id: '271', code_postal: '08009', nom: 'Mechraa Houari B', wilaya_id: '8' },
  { id: '272', code_postal: '08010', nom: 'Kenedsa', wilaya_id: '8' },
  { id: '273', code_postal: '08011', nom: 'Igli', wilaya_id: '8' },
  { id: '274', code_postal: '08012', nom: 'Tabalbala', wilaya_id: '8' },
  { id: '275', code_postal: '08013', nom: 'Taghit', wilaya_id: '8' },
  { id: '276', code_postal: '08014', nom: 'El Ouata', wilaya_id: '8' },
  { id: '277', code_postal: '08015', nom: 'Boukais', wilaya_id: '8' },
  { id: '278', code_postal: '08016', nom: 'Mogheul', wilaya_id: '8' },
  { id: '279', code_postal: '08017', nom: 'Abadla', wilaya_id: '8' },
  { id: '280', code_postal: '08018', nom: 'Kerzaz', wilaya_id: '8' },
  { id: '281', code_postal: '08019', nom: 'Ksabi', wilaya_id: '8' },
  { id: '282', code_postal: '08020', nom: 'Tamtert', wilaya_id: '8' },
  { id: '283', code_postal: '08021', nom: 'Beni Ounif', wilaya_id: '8' },
  { id: '284', code_postal: '09001', nom: 'Blida', wilaya_id: '9' },
  { id: '285', code_postal: '09002', nom: 'Chebli', wilaya_id: '9' },
  { id: '286', code_postal: '09003', nom: 'Bouinan', wilaya_id: '9' },
  { id: '287', code_postal: '09004', nom: 'Oued El Alleug', wilaya_id: '9' },
  { id: '288', code_postal: '09007', nom: 'Ouled Yaich', wilaya_id: '9' },
  { id: '289', code_postal: '09008', nom: 'Chrea', wilaya_id: '9' },
  { id: '290', code_postal: '09010', nom: 'El Affroun', wilaya_id: '9' },
  { id: '291', code_postal: '09011', nom: 'Chiffa', wilaya_id: '9' },
  { id: '292', code_postal: '09012', nom: 'Hammam Melouane', wilaya_id: '9' },
  { id: '293', code_postal: '09013', nom: 'Ben Khlil', wilaya_id: '9' },
  { id: '294', code_postal: '09014', nom: 'Soumaa', wilaya_id: '9' },
  { id: '295', code_postal: '09016', nom: 'Mouzaia', wilaya_id: '9' },
  { id: '296', code_postal: '09017', nom: 'Souhane', wilaya_id: '9' },
  { id: '297', code_postal: '09018', nom: 'Meftah', wilaya_id: '9' },
  { id: '298', code_postal: '09019', nom: 'Ouled Selama', wilaya_id: '9' },
  { id: '299', code_postal: '09020', nom: 'Boufarik', wilaya_id: '9' },
  { id: '300', code_postal: '09021', nom: 'Larbaa', wilaya_id: '9' },
  { id: '301', code_postal: '09022', nom: 'Oued Djer', wilaya_id: '9' },
  { id: '302', code_postal: '09023', nom: 'Beni Tamou', wilaya_id: '9' },
  { id: '303', code_postal: '09024', nom: 'Bouarfa', wilaya_id: '9' },
  { id: '304', code_postal: '09025', nom: 'Beni Mered', wilaya_id: '9' },
  { id: '305', code_postal: '09026', nom: 'Bougara', wilaya_id: '9' },
  { id: '306', code_postal: '09027', nom: 'Guerrouaou', wilaya_id: '9' },
  { id: '307', code_postal: '09028', nom: 'Ain Romana', wilaya_id: '9' },
  { id: '308', code_postal: '09029', nom: 'Djebabra', wilaya_id: '9' },
  { id: '309', code_postal: '10001', nom: 'Bouira', wilaya_id: '10' },
  { id: '310', code_postal: '10002', nom: 'El Asnam', wilaya_id: '10' },
  { id: '311', code_postal: '10003', nom: 'Guerrouma', wilaya_id: '10' },
  { id: '312', code_postal: '10004', nom: 'Souk El Khemis', wilaya_id: '10' },
  { id: '313', code_postal: '10005', nom: 'Kadiria', wilaya_id: '10' },
  { id: '314', code_postal: '10006', nom: 'Hanif', wilaya_id: '10' },
  { id: '315', code_postal: '10007', nom: 'Dirah', wilaya_id: '10' },
  { id: '316', code_postal: '10008', nom: 'Ait Laaziz', wilaya_id: '10' },
  { id: '317', code_postal: '10009', nom: 'Taghzout', wilaya_id: '10' },
  { id: '318', code_postal: '10010', nom: 'Raouraoua', wilaya_id: '10' },
  { id: '319', code_postal: '10011', nom: 'Mezdour', wilaya_id: '10' },
  { id: '320', code_postal: '10012', nom: 'Haizer', wilaya_id: '10' },
  { id: '321', code_postal: '10013', nom: 'Lakhdaria', wilaya_id: '10' },
  { id: '322', code_postal: '10014', nom: 'Maala', wilaya_id: '10' },
  { id: '323', code_postal: '10015', nom: 'El Hachimia', wilaya_id: '10' },
  { id: '324', code_postal: '10016', nom: 'Aomar', wilaya_id: '10' },
  { id: '325', code_postal: '10017', nom: 'Chorfa', wilaya_id: '10' },
  { id: '326', code_postal: '10018', nom: 'Bordj Oukhriss', wilaya_id: '10' },
  { id: '327', code_postal: '10019', nom: 'El Adjiba', wilaya_id: '10' },
  { id: '328', code_postal: '10020', nom: 'El Hakimia', wilaya_id: '10' },
  { id: '329', code_postal: '10021', nom: 'El Khebouzia', wilaya_id: '10' },
  { id: '330', code_postal: '10022', nom: 'Ahl El Ksar', wilaya_id: '10' },
  { id: '331', code_postal: '10023', nom: 'Bouderbala', wilaya_id: '10' },
  { id: '332', code_postal: '10024', nom: 'Zbarbar', wilaya_id: '10' },
  { id: '333', code_postal: '10025', nom: 'Ain El Hadjar', wilaya_id: '10' },
  { id: '334', code_postal: '10026', nom: 'Djebahia', wilaya_id: '10' },
  { id: '335', code_postal: '10027', nom: 'Aghbalou', wilaya_id: '10' },
  { id: '336', code_postal: '10028', nom: 'Taguedit', wilaya_id: '10' },
  { id: '337', code_postal: '10029', nom: 'Ain Turk', wilaya_id: '10' },
  { id: '338', code_postal: '10030', nom: 'Saharidj', wilaya_id: '10' },
  { id: '339', code_postal: '10031', nom: 'Dechmia', wilaya_id: '10' },
  { id: '340', code_postal: '10032', nom: 'Ridane', wilaya_id: '10' },
  { id: '341', code_postal: '10033', nom: 'Bechloul', wilaya_id: '10' },
  { id: '342', code_postal: '10034', nom: 'Boukram', wilaya_id: '10' },
  { id: '343', code_postal: '10035', nom: 'Ain Bessam', wilaya_id: '10' },
  { id: '344', code_postal: '10036', nom: 'Bir Ghbalou', wilaya_id: '10' },
  { id: '345', code_postal: '10037', nom: 'Mchedallah', wilaya_id: '10' },
  { id: '346', code_postal: '10038', nom: 'Sour El Ghozlane', wilaya_id: '10' },
  { id: '347', code_postal: '10039', nom: 'Maamora', wilaya_id: '10' },
  { id: '348', code_postal: '10040', nom: 'Ouled Rached', wilaya_id: '10' },
  { id: '349', code_postal: '10041', nom: 'Ain Laloui', wilaya_id: '10' },
  { id: '350', code_postal: '10042', nom: 'Hadjera Zerga', wilaya_id: '10' },
  { id: '351', code_postal: '10043', nom: 'Ath Mansour', wilaya_id: '10' },
  { id: '352', code_postal: '10044', nom: 'El Mokrani', wilaya_id: '10' },
  { id: '353', code_postal: '10045', nom: 'Oued El Berdi', wilaya_id: '10' },
  { id: '354', code_postal: '11001', nom: 'Tamanghasset', wilaya_id: '11' },
  { id: '355', code_postal: '11002', nom: 'Abalessa', wilaya_id: '11' },
  { id: '356', code_postal: '11003', nom: 'In Ghar', wilaya_id: '11' },
  { id: '357', code_postal: '11004', nom: 'In Guezzam', wilaya_id: '11' },
  { id: '358', code_postal: '11005', nom: 'Idles', wilaya_id: '11' },
  { id: '359', code_postal: '11006', nom: 'Tazouk', wilaya_id: '11' },
  { id: '360', code_postal: '11007', nom: 'Tinzaouatine', wilaya_id: '11' },
  { id: '361', code_postal: '11008', nom: 'In Salah', wilaya_id: '11' },
  { id: '362', code_postal: '11009', nom: 'In Amguel', wilaya_id: '11' },
  { id: '363', code_postal: '11010', nom: 'Foggaret Ezzaouia', wilaya_id: '11' },
  { id: '364', code_postal: '12001', nom: 'Tebessa', wilaya_id: '12' },
  { id: '365', code_postal: '12002', nom: 'Bir El Ater', wilaya_id: '12' },
  { id: '366', code_postal: '12003', nom: 'Cheria', wilaya_id: '12' },
  { id: '367', code_postal: '12004', nom: 'Stah Guentis', wilaya_id: '12' },
  { id: '368', code_postal: '12005', nom: 'El Aouinet', wilaya_id: '12' },
  { id: '369', code_postal: '12006', nom: 'Lahouidjbet', wilaya_id: '12' },
  { id: '370', code_postal: '12007', nom: 'Safsaf El Ouesra', wilaya_id: '12' },
  { id: '371', code_postal: '12008', nom: 'Hammamet', wilaya_id: '12' },
  { id: '372', code_postal: '12009', nom: 'Negrine', wilaya_id: '12' },
  { id: '373', code_postal: '12010', nom: 'Bir El Mokadem', wilaya_id: '12' },
  { id: '374', code_postal: '12011', nom: 'El Kouif', wilaya_id: '12' },
  { id: '375', code_postal: '12012', nom: 'Morsott', wilaya_id: '12' },
  { id: '376', code_postal: '12013', nom: 'El Ogla', wilaya_id: '12' },
  { id: '377', code_postal: '12014', nom: 'Bir Dheheb', wilaya_id: '12' },
  { id: '378', code_postal: '12015', nom: 'El Ogla El Malha', wilaya_id: '12' },
  { id: '379', code_postal: '12016', nom: 'Gorriguer', wilaya_id: '12' },
  { id: '380', code_postal: '12017', nom: 'Bekkaria', wilaya_id: '12' },
  { id: '381', code_postal: '12018', nom: 'Boukhadra', wilaya_id: '12' },
  { id: '382', code_postal: '12019', nom: 'Ouenza', wilaya_id: '12' },
  { id: '383', code_postal: '12020', nom: 'El Ma El Biodh', wilaya_id: '12' },
  { id: '384', code_postal: '12021', nom: 'Oum Ali', wilaya_id: '12' },
  { id: '385', code_postal: '12022', nom: 'Thlidjene', wilaya_id: '12' },
  { id: '386', code_postal: '12023', nom: 'Ain Zerga', wilaya_id: '12' },
  { id: '387', code_postal: '12024', nom: 'El Meridj', wilaya_id: '12' },
  { id: '388', code_postal: '12025', nom: 'Boulhaf Dyr', wilaya_id: '12' },
  { id: '389', code_postal: '12026', nom: 'Bedjene', wilaya_id: '12' },
  { id: '390', code_postal: '12027', nom: 'El Mazeraa', wilaya_id: '12' },
  { id: '391', code_postal: '12028', nom: 'Ferkane', wilaya_id: '12' },
  { id: '392', code_postal: '13001', nom: 'Tlemcen', wilaya_id: '13' },
  { id: '393', code_postal: '13002', nom: 'Beni Mester', wilaya_id: '13' },
  { id: '394', code_postal: '13003', nom: 'Ain Tallout', wilaya_id: '13' },
  { id: '395', code_postal: '13004', nom: 'Remchi', wilaya_id: '13' },
  { id: '396', code_postal: '13005', nom: 'El Fehoul', wilaya_id: '13' },
  { id: '397', code_postal: '13006', nom: 'Sabra', wilaya_id: '13' },
  { id: '398', code_postal: '13007', nom: 'Ghazaouet', wilaya_id: '13' },
  { id: '399', code_postal: '13008', nom: 'Souani', wilaya_id: '13' },
  { id: '400', code_postal: '13009', nom: 'Djebala', wilaya_id: '13' },
  { id: '401', code_postal: '13010', nom: 'El Gor', wilaya_id: '13' },
  { id: '402', code_postal: '13011', nom: 'Oued Chouly', wilaya_id: '13' },
  { id: '403', code_postal: '13012', nom: 'Ain Fezza', wilaya_id: '13' },
  { id: '404', code_postal: '13013', nom: 'Ouled Mimoun', wilaya_id: '13' },
  { id: '405', code_postal: '13014', nom: 'Amieur', wilaya_id: '13' },
  { id: '406', code_postal: '13015', nom: 'Ain Youcef', wilaya_id: '13' },
  { id: '407', code_postal: '13016', nom: 'Zenata', wilaya_id: '13' },
  { id: '408', code_postal: '13017', nom: 'Beni Snous', wilaya_id: '13' },
  { id: '409', code_postal: '13018', nom: 'Bab El Assa', wilaya_id: '13' },
  { id: '410', code_postal: '13019', nom: 'Dar Yaghmouracene', wilaya_id: '13' },
  { id: '411', code_postal: '13020', nom: 'Fellaoucene', wilaya_id: '13' },
  { id: '412', code_postal: '13021', nom: 'Azails', wilaya_id: '13' },
  { id: '413', code_postal: '13022', nom: 'Sebbaa Chioukh', wilaya_id: '13' },
  { id: '414', code_postal: '13023', nom: 'Terni Beni Hediel', wilaya_id: '13' },
  { id: '415', code_postal: '13024', nom: 'Bensekrane', wilaya_id: '13' },
  { id: '416', code_postal: '13025', nom: 'Ain Nehala', wilaya_id: '13' },
  { id: '417', code_postal: '13026', nom: 'Hennaya', wilaya_id: '13' },
  { id: '418', code_postal: '13027', nom: 'Maghnia', wilaya_id: '13' },
  { id: '419', code_postal: '13028', nom: 'Hammam Boughrara', wilaya_id: '13' },
  { id: '420', code_postal: '13029', nom: 'Souahlia', wilaya_id: '13' },
  { id: '421', code_postal: '13030', nom: 'Msirda Fouaga', wilaya_id: '13' },
  { id: '422', code_postal: '13031', nom: 'Ain Fetah', wilaya_id: '13' },
  { id: '423', code_postal: '13032', nom: 'El Aricha', wilaya_id: '13' },
  { id: '424', code_postal: '13033', nom: 'Souk Thlata', wilaya_id: '13' },
  { id: '425', code_postal: '13034', nom: 'Sidi Abdelli', wilaya_id: '13' },
  { id: '426', code_postal: '13035', nom: 'Sebdou', wilaya_id: '13' },
  { id: '427', code_postal: '13036', nom: 'Beni Ouarsous', wilaya_id: '13' },
  { id: '428', code_postal: '13037', nom: 'Sidi Medjahed', wilaya_id: '13' },
  { id: '429', code_postal: '13038', nom: 'Beni Boussaid', wilaya_id: '13' },
  { id: '430', code_postal: '13039', nom: 'Marsa Ben Mhidi', wilaya_id: '13' },
  { id: '431', code_postal: '13040', nom: 'Nedroma', wilaya_id: '13' },
  { id: '432', code_postal: '13041', nom: 'Sidi Djillali', wilaya_id: '13' },
  { id: '433', code_postal: '13042', nom: 'Beni Bahdel', wilaya_id: '13' },
  { id: '434', code_postal: '13043', nom: 'El Bouihi', wilaya_id: '13' },
  { id: '435', code_postal: '13044', nom: 'Honaine', wilaya_id: '13' },
  { id: '436', code_postal: '13045', nom: 'Tianet', wilaya_id: '13' },
  { id: '437', code_postal: '13046', nom: 'Ouled Riyah', wilaya_id: '13' },
  { id: '438', code_postal: '13047', nom: 'Bouhlou', wilaya_id: '13' },
  { id: '439', code_postal: '13048', nom: 'Souk El Khemis', wilaya_id: '13' },
  { id: '440', code_postal: '13049', nom: 'Ain Ghoraba', wilaya_id: '13' },
  { id: '441', code_postal: '13050', nom: 'Chetouane', wilaya_id: '13' },
  { id: '442', code_postal: '13051', nom: 'Mansourah', wilaya_id: '13' },
  { id: '443', code_postal: '13052', nom: 'Beni Semiel', wilaya_id: '13' },
  { id: '444', code_postal: '13053', nom: 'Ain Kebira', wilaya_id: '13' },
  { id: '445', code_postal: '14001', nom: 'Tiaret', wilaya_id: '14' },
  { id: '446', code_postal: '14002', nom: 'Medroussa', wilaya_id: '14' },
  { id: '447', code_postal: '14003', nom: 'Ain Bouchekif', wilaya_id: '14' },
  { id: '448', code_postal: '14004', nom: 'Sidi Ali Mellal', wilaya_id: '14' },
  { id: '449', code_postal: '14005', nom: 'Ain Zarit', wilaya_id: '14' },
  { id: '450', code_postal: '14006', nom: 'Ain Deheb', wilaya_id: '14' },
  { id: '451', code_postal: '14007', nom: 'Sidi Bakhti', wilaya_id: '14' },
  { id: '452', code_postal: '14008', nom: 'Medrissa', wilaya_id: '14' },
  { id: '453', code_postal: '14009', nom: 'Zmalet El Emir Aek', wilaya_id: '14' },
  { id: '454', code_postal: '14010', nom: 'Madna', wilaya_id: '14' },
  { id: '455', code_postal: '14011', nom: 'Sebt', wilaya_id: '14' },
  { id: '456', code_postal: '14012', nom: 'Mellakou', wilaya_id: '14' },
  { id: '457', code_postal: '14013', nom: 'Dahmouni', wilaya_id: '14' },
  { id: '458', code_postal: '14014', nom: 'Rahouia', wilaya_id: '14' },
  { id: '459', code_postal: '14015', nom: 'Mahdia', wilaya_id: '14' },
  { id: '460', code_postal: '14016', nom: 'Sougueur', wilaya_id: '14' },
  { id: '461', code_postal: '14017', nom: 'Sidi Abdelghani', wilaya_id: '14' },
  { id: '462', code_postal: '14018', nom: 'Ain El Hadid', wilaya_id: '14' },
  { id: '463', code_postal: '14019', nom: 'Ouled Djerad', wilaya_id: '14' },
  { id: '464', code_postal: '14020', nom: 'Naima', wilaya_id: '14' },
  { id: '465', code_postal: '14021', nom: 'Meghila', wilaya_id: '14' },
  { id: '466', code_postal: '14022', nom: 'Guertoufa', wilaya_id: '14' },
  { id: '467', code_postal: '14023', nom: 'Sidi Hosni', wilaya_id: '14' },
  { id: '468', code_postal: '14024', nom: 'Djillali Ben Amar', wilaya_id: '14' },
  { id: '469', code_postal: '14025', nom: 'Sebaine', wilaya_id: '14' },
  { id: '470', code_postal: '14026', nom: 'Tousnina', wilaya_id: '14' },
  { id: '471', code_postal: '14027', nom: 'Frenda', wilaya_id: '14' },
  { id: '472', code_postal: '14028', nom: 'Ain Kermes', wilaya_id: '14' },
  { id: '473', code_postal: '14029', nom: 'Ksar Chellala', wilaya_id: '14' },
  { id: '474', code_postal: '14030', nom: 'Rechaiga', wilaya_id: '14' },
  { id: '475', code_postal: '14031', nom: 'Nadorah', wilaya_id: '14' },
  { id: '476', code_postal: '14032', nom: 'Tagdemt', wilaya_id: '14' },
  { id: '477', code_postal: '14033', nom: 'Oued Lilli', wilaya_id: '14' },
  { id: '478', code_postal: '14034', nom: 'Mechraa Safa', wilaya_id: '14' },
  { id: '479', code_postal: '14035', nom: 'Hamadia', wilaya_id: '14' },
  { id: '480', code_postal: '14036', nom: 'Chehaima', wilaya_id: '14' },
  { id: '481', code_postal: '14037', nom: 'Takhemaret', wilaya_id: '14' },
  { id: '482', code_postal: '14038', nom: 'Sidi Abderrahmane', wilaya_id: '14' },
  { id: '483', code_postal: '14039', nom: 'Serghine', wilaya_id: '14' },
  { id: '484', code_postal: '14040', nom: 'Bougara', wilaya_id: '14' },
  { id: '485', code_postal: '14041', nom: 'Faidja', wilaya_id: '14' },
  { id: '486', code_postal: '14042', nom: 'Tidda', wilaya_id: '14' },
  { id: '487', code_postal: '15001', nom: 'Tizi Ouzou', wilaya_id: '15' },
  { id: '488', code_postal: '15002', nom: 'Ain El Hammam', wilaya_id: '15' },
  { id: '489', code_postal: '15003', nom: 'Akbil', wilaya_id: '15' },
  { id: '490', code_postal: '15004', nom: 'Freha', wilaya_id: '15' },
  { id: '491', code_postal: '15005', nom: 'Souamaa', wilaya_id: '15' },
  { id: '492', code_postal: '15006', nom: 'Mechtrass', wilaya_id: '15' },
  { id: '493', code_postal: '15007', nom: 'Irdjen', wilaya_id: '15' },
  { id: '494', code_postal: '15008', nom: 'Timizart', wilaya_id: '15' },
  { id: '495', code_postal: '15009', nom: 'Makouda', wilaya_id: '15' },
  { id: '496', code_postal: '15010', nom: 'Draa El Mizan', wilaya_id: '15' },
  { id: '497', code_postal: '15011', nom: 'Tizi Ghenif', wilaya_id: '15' },
  { id: '498', code_postal: '15012', nom: 'Bounouh', wilaya_id: '15' },
  { id: '499', code_postal: '15013', nom: 'Ait Chaffaa', wilaya_id: '15' },
  { id: '500', code_postal: '15014', nom: 'Frikat', wilaya_id: '15' },
  { id: '501', code_postal: '15015', nom: 'Beni Aissi', wilaya_id: '15' },
  { id: '502', code_postal: '15016', nom: 'Beni Zmenzer', wilaya_id: '15' },
  { id: '503', code_postal: '15017', nom: 'Iferhounene', wilaya_id: '15' },
  { id: '504', code_postal: '15018', nom: 'Azazga', wilaya_id: '15' },
  { id: '505', code_postal: '15019', nom: 'Iloula Oumalou', wilaya_id: '15' },
  { id: '506', code_postal: '15020', nom: 'Yakouren', wilaya_id: '15' },
  { id: '507', code_postal: '15021', nom: 'Larba Nait Irathen', wilaya_id: '15' },
  { id: '508', code_postal: '15022', nom: 'Tizi Rached', wilaya_id: '15' },
  { id: '509', code_postal: '15023', nom: 'Zekri', wilaya_id: '15' },
  { id: '510', code_postal: '15024', nom: 'Ouaguenoun', wilaya_id: '15' },
  { id: '511', code_postal: '15025', nom: 'Ain Zaouia', wilaya_id: '15' },
  { id: '512', code_postal: '15026', nom: 'Mkira', wilaya_id: '15' },
  { id: '513', code_postal: '15027', nom: 'Ait Yahia', wilaya_id: '15' },
  { id: '514', code_postal: '15028', nom: 'Ait Mahmoud', wilaya_id: '15' },
  { id: '515', code_postal: '15029', nom: 'Maatka', wilaya_id: '15' },
  { id: '516', code_postal: '15030', nom: 'Ait Boumehdi', wilaya_id: '15' },
  { id: '517', code_postal: '15031', nom: 'Abi Youcef', wilaya_id: '15' },
  { id: '518', code_postal: '15032', nom: 'Beni Douala', wilaya_id: '15' },
  { id: '519', code_postal: '15033', nom: 'Illilten', wilaya_id: '15' },
  { id: '520', code_postal: '15034', nom: 'Bouzguen', wilaya_id: '15' },
  { id: '521', code_postal: '15035', nom: 'Ait Aggouacha', wilaya_id: '15' },
  { id: '522', code_postal: '15036', nom: 'Ouadhia', wilaya_id: '15' },
  { id: '523', code_postal: '15037', nom: 'Azzefoun', wilaya_id: '15' },
  { id: '524', code_postal: '15038', nom: 'Tigzirt', wilaya_id: '15' },
  { id: '525', code_postal: '15039', nom: 'Ait Aissa Mimoun', wilaya_id: '15' },
  { id: '526', code_postal: '15040', nom: 'Boghni', wilaya_id: '15' },
  { id: '527', code_postal: '15041', nom: 'Ifigha', wilaya_id: '15' },
  { id: '528', code_postal: '15042', nom: 'Ait Oumalou', wilaya_id: '15' },
  { id: '529', code_postal: '15043', nom: 'Tirmitine', wilaya_id: '15' },
  { id: '530', code_postal: '15044', nom: 'Akerrou', wilaya_id: '15' },
  { id: '531', code_postal: '15045', nom: 'Yatafen', wilaya_id: '15' },
  { id: '532', code_postal: '15046', nom: 'Beni Ziki', wilaya_id: '15' },
  { id: '533', code_postal: '15047', nom: 'Draa Ben Khedda', wilaya_id: '15' },
  { id: '534', code_postal: '15048', nom: 'Ouacif', wilaya_id: '15' },
  { id: '535', code_postal: '15049', nom: 'Idjeur', wilaya_id: '15' },
  { id: '536', code_postal: '15050', nom: 'Mekla', wilaya_id: '15' },
  { id: '537', code_postal: '15051', nom: 'Tizi Nthlata', wilaya_id: '15' },
  { id: '538', code_postal: '15052', nom: 'Beni Yenni', wilaya_id: '15' },
  { id: '539', code_postal: '15053', nom: 'Aghrib', wilaya_id: '15' },
  { id: '540', code_postal: '15054', nom: 'Iflissen', wilaya_id: '15' },
  { id: '541', code_postal: '15055', nom: 'Boudjima', wilaya_id: '15' },
  { id: '542', code_postal: '15056', nom: 'Ait Yahia Moussa', wilaya_id: '15' },
  { id: '543', code_postal: '15057', nom: 'Souk El Thenine', wilaya_id: '15' },
  { id: '544', code_postal: '15058', nom: 'Ait Khelil', wilaya_id: '15' },
  { id: '545', code_postal: '15059', nom: 'Sidi Naamane', wilaya_id: '15' },
  { id: '546', code_postal: '15060', nom: 'Iboudraren', wilaya_id: '15' },
  { id: '547', code_postal: '15061', nom: 'Aghni Goughran', wilaya_id: '15' },
  { id: '548', code_postal: '15062', nom: 'Mizrana', wilaya_id: '15' },
  { id: '549', code_postal: '15063', nom: 'Imsouhal', wilaya_id: '15' },
  { id: '550', code_postal: '15064', nom: 'Tadmait', wilaya_id: '15' },
  { id: '551', code_postal: '15065', nom: 'Ait Bouadou', wilaya_id: '15' },
  { id: '552', code_postal: '15066', nom: 'Assi Youcef', wilaya_id: '15' },
  { id: '553', code_postal: '15067', nom: 'Ait Toudert', wilaya_id: '15' },
  { id: '554', code_postal: '16001', nom: 'Alger Centre', wilaya_id: '16' },
  { id: '555', code_postal: '16002', nom: 'Sidi Mhamed', wilaya_id: '16' },
  { id: '556', code_postal: '16003', nom: 'El Madania', wilaya_id: '16' },
  { id: '557', code_postal: '16004', nom: 'Hamma Anassers', wilaya_id: '16' },
  { id: '558', code_postal: '16005', nom: 'Bab El Oued', wilaya_id: '16' },
  { id: '559', code_postal: '16006', nom: 'Bologhine Ibn Ziri', wilaya_id: '16' },
  { id: '560', code_postal: '16007', nom: 'Casbah', wilaya_id: '16' },
  { id: '561', code_postal: '16008', nom: 'Oued Koriche', wilaya_id: '16' },
  { id: '562', code_postal: '16009', nom: 'Bir Mourad Rais', wilaya_id: '16' },
  { id: '563', code_postal: '16010', nom: 'El Biar', wilaya_id: '16' },
  { id: '564', code_postal: '16011', nom: 'Bouzareah', wilaya_id: '16' },
  { id: '565', code_postal: '16012', nom: 'Birkhadem', wilaya_id: '16' },
  { id: '566', code_postal: '16013', nom: 'El Harrach', wilaya_id: '16' },
  { id: '567', code_postal: '16014', nom: 'Baraki', wilaya_id: '16' },
  { id: '568', code_postal: '16015', nom: 'Oued Smar', wilaya_id: '16' },
  { id: '569', code_postal: '16016', nom: 'Bourouba', wilaya_id: '16' },
  { id: '570', code_postal: '16017', nom: 'Hussein Dey', wilaya_id: '16' },
  { id: '571', code_postal: '16018', nom: 'Kouba', wilaya_id: '16' },
  { id: '572', code_postal: '16019', nom: 'Bachedjerah', wilaya_id: '16' },
  { id: '573', code_postal: '16020', nom: 'Dar El Beida', wilaya_id: '16' },
  { id: '574', code_postal: '16021', nom: 'Bab Azzouar', wilaya_id: '16' },
  { id: '575', code_postal: '16022', nom: 'Ben Aknoun', wilaya_id: '16' },
  { id: '576', code_postal: '16023', nom: 'Dely Ibrahim', wilaya_id: '16' },
  { id: '577', code_postal: '16024', nom: 'Bains Romains', wilaya_id: '16' },
  { id: '578', code_postal: '16025', nom: 'Rais Hamidou', wilaya_id: '16' },
  { id: '579', code_postal: '16026', nom: 'Djasr Kasentina', wilaya_id: '16' },
  { id: '580', code_postal: '16027', nom: 'El Mouradia', wilaya_id: '16' },
  { id: '581', code_postal: '16028', nom: 'Hydra', wilaya_id: '16' },
  { id: '582', code_postal: '16029', nom: 'Mohammadia', wilaya_id: '16' },
  { id: '583', code_postal: '16030', nom: 'Bordj El Kiffan', wilaya_id: '16' },
  { id: '584', code_postal: '16031', nom: 'El Magharia', wilaya_id: '16' },
  { id: '585', code_postal: '16032', nom: 'Beni Messous', wilaya_id: '16' },
  { id: '586', code_postal: '16033', nom: 'Les Eucalyptus', wilaya_id: '16' },
  { id: '587', code_postal: '16034', nom: 'Birtouta', wilaya_id: '16' },
  { id: '588', code_postal: '16035', nom: 'Tassala El Merdja', wilaya_id: '16' },
  { id: '589', code_postal: '16036', nom: 'Ouled Chebel', wilaya_id: '16' },
  { id: '590', code_postal: '16037', nom: 'Sidi Moussa', wilaya_id: '16' },
  { id: '591', code_postal: '16038', nom: 'Ain Taya', wilaya_id: '16' },
  { id: '592', code_postal: '16039', nom: 'Bordj El Bahri', wilaya_id: '16' },
  { id: '593', code_postal: '16040', nom: 'Marsa', wilaya_id: '16' },
  { id: '594', code_postal: '16041', nom: 'Haraoua', wilaya_id: '16' },
  { id: '595', code_postal: '16042', nom: 'Rouiba', wilaya_id: '16' },
  { id: '596', code_postal: '16043', nom: 'Reghaia', wilaya_id: '16' },
  { id: '597', code_postal: '16044', nom: 'Ain Benian', wilaya_id: '16' },
  { id: '598', code_postal: '16045', nom: 'Staoueli', wilaya_id: '16' },
  { id: '599', code_postal: '16046', nom: 'Zeralda', wilaya_id: '16' },
  { id: '600', code_postal: '16047', nom: 'Mahelma', wilaya_id: '16' },
  { id: '601', code_postal: '16048', nom: 'Rahmania', wilaya_id: '16' },
  { id: '602', code_postal: '16049', nom: 'Souidania', wilaya_id: '16' },
  { id: '603', code_postal: '16050', nom: 'Cheraga', wilaya_id: '16' },
  { id: '604', code_postal: '16051', nom: 'Ouled Fayet', wilaya_id: '16' },
  { id: '605', code_postal: '16052', nom: 'El Achour', wilaya_id: '16' },
  { id: '606', code_postal: '16053', nom: 'Draria', wilaya_id: '16' },
  { id: '607', code_postal: '16054', nom: 'Douera', wilaya_id: '16' },
  { id: '608', code_postal: '16055', nom: 'Baba Hassen', wilaya_id: '16' },
  { id: '609', code_postal: '16056', nom: 'Khracia', wilaya_id: '16' },
  { id: '610', code_postal: '16057', nom: 'Saoula', wilaya_id: '16' },
  { id: '611', code_postal: '17001', nom: 'Djelfa', wilaya_id: '17' },
  { id: '612', code_postal: '17002', nom: 'Moudjebara', wilaya_id: '17' },
  { id: '613', code_postal: '17003', nom: 'El Guedid', wilaya_id: '17' },
  { id: '614', code_postal: '17004', nom: 'Hassi Bahbah', wilaya_id: '17' },
  { id: '615', code_postal: '17005', nom: 'Ain Maabed', wilaya_id: '17' },
  { id: '616', code_postal: '17006', nom: 'Sed Rahal', wilaya_id: '17' },
  { id: '617', code_postal: '17007', nom: 'Feidh El Botma', wilaya_id: '17' },
  { id: '618', code_postal: '17008', nom: 'Birine', wilaya_id: '17' },
  { id: '619', code_postal: '17009', nom: 'Bouira Lahdeb', wilaya_id: '17' },
  { id: '620', code_postal: '17010', nom: 'Zaccar', wilaya_id: '17' },
  { id: '621', code_postal: '17011', nom: 'El Khemis', wilaya_id: '17' },
  { id: '622', code_postal: '17012', nom: 'Sidi Baizid', wilaya_id: '17' },
  { id: '623', code_postal: '17013', nom: 'Mliliha', wilaya_id: '17' },
  { id: '624', code_postal: '17014', nom: 'El Idrissia', wilaya_id: '17' },
  { id: '625', code_postal: '17015', nom: 'Douis', wilaya_id: '17' },
  { id: '626', code_postal: '17016', nom: 'Hassi El Euch', wilaya_id: '17' },
  { id: '627', code_postal: '17017', nom: 'Messaad', wilaya_id: '17' },
  { id: '628', code_postal: '17018', nom: 'Guettara', wilaya_id: '17' },
  { id: '629', code_postal: '17019', nom: 'Sidi Ladjel', wilaya_id: '17' },
  { id: '630', code_postal: '17020', nom: 'Had Sahary', wilaya_id: '17' },
  { id: '631', code_postal: '17021', nom: 'Guernini', wilaya_id: '17' },
  { id: '632', code_postal: '17022', nom: 'Selmana', wilaya_id: '17' },
  { id: '633', code_postal: '17023', nom: 'Ain Chouhada', wilaya_id: '17' },
  { id: '634', code_postal: '17024', nom: 'Oum Laadham', wilaya_id: '17' },
  { id: '635', code_postal: '17025', nom: 'Dar Chouikh', wilaya_id: '17' },
  { id: '636', code_postal: '17026', nom: 'Charef', wilaya_id: '17' },
  { id: '637', code_postal: '17027', nom: 'Beni Yacoub', wilaya_id: '17' },
  { id: '638', code_postal: '17028', nom: 'Zaafrane', wilaya_id: '17' },
  { id: '639', code_postal: '17029', nom: 'Deldoul', wilaya_id: '17' },
  { id: '640', code_postal: '17030', nom: 'Ain El Ibel', wilaya_id: '17' },
  { id: '641', code_postal: '17031', nom: 'Ain Oussera', wilaya_id: '17' },
  { id: '642', code_postal: '17032', nom: 'Benhar', wilaya_id: '17' },
  { id: '643', code_postal: '17033', nom: 'Hassi Fedoul', wilaya_id: '17' },
  { id: '644', code_postal: '17034', nom: 'Amourah', wilaya_id: '17' },
  { id: '645', code_postal: '17035', nom: 'Ain Fekka', wilaya_id: '17' },
  { id: '646', code_postal: '17036', nom: 'Tadmit', wilaya_id: '17' },
  { id: '647', code_postal: '18001', nom: 'Jijel', wilaya_id: '18' },
  { id: '648', code_postal: '18002', nom: 'Erraguene', wilaya_id: '18' },
  { id: '649', code_postal: '18003', nom: 'El Aouana', wilaya_id: '18' },
  { id: '650', code_postal: '18004', nom: 'Ziamma Mansouriah', wilaya_id: '18' },
  { id: '651', code_postal: '18005', nom: 'Taher', wilaya_id: '18' },
  { id: '652', code_postal: '18006', nom: 'Emir Abdelkader', wilaya_id: '18' },
  { id: '653', code_postal: '18007', nom: 'Chekfa', wilaya_id: '18' },
  { id: '654', code_postal: '18008', nom: 'Chahna', wilaya_id: '18' },
  { id: '655', code_postal: '18009', nom: 'El Milia', wilaya_id: '18' },
  { id: '656', code_postal: '18010', nom: 'Sidi Maarouf', wilaya_id: '18' },
  { id: '657', code_postal: '18011', nom: 'Settara', wilaya_id: '18' },
  { id: '658', code_postal: '18012', nom: 'El Ancer', wilaya_id: '18' },
  { id: '659', code_postal: '18013', nom: 'Sidi Abdelaziz', wilaya_id: '18' },
  { id: '660', code_postal: '18014', nom: 'Kaous', wilaya_id: '18' },
  { id: '661', code_postal: '18015', nom: 'Ghebala', wilaya_id: '18' },
  { id: '662', code_postal: '18016', nom: 'Bouraoui Belhadef', wilaya_id: '18' },
  { id: '663', code_postal: '18017', nom: 'Djmila', wilaya_id: '18' },
  { id: '664', code_postal: '18018', nom: 'Selma Benziada', wilaya_id: '18' },
  { id: '665', code_postal: '18019', nom: 'Boussif Ouled Askeur', wilaya_id: '18' },
  { id: '666', code_postal: '18020', nom: 'El Kennar Nouchfi', wilaya_id: '18' },
  { id: '667', code_postal: '18021', nom: 'Ouled Yahia Khadrouch', wilaya_id: '18' },
  { id: '668', code_postal: '18022', nom: 'Boudria Beni Yadjis', wilaya_id: '18' },
  { id: '669', code_postal: '18023', nom: 'Kemir Oued Adjoul', wilaya_id: '18' },
  { id: '670', code_postal: '18024', nom: 'Texena', wilaya_id: '18' },
  { id: '671', code_postal: '18025', nom: 'Djemaa Beni Habibi', wilaya_id: '18' },
  { id: '672', code_postal: '18026', nom: 'Bordj Taher', wilaya_id: '18' },
  { id: '673', code_postal: '18027', nom: 'Ouled Rabah', wilaya_id: '18' },
  { id: '674', code_postal: '18028', nom: 'Ouadjana', wilaya_id: '18' },
  { id: '675', code_postal: '19001', nom: 'Setif', wilaya_id: '19' },
  { id: '676', code_postal: '19002', nom: 'Ain El Kebira', wilaya_id: '19' },
  { id: '677', code_postal: '19003', nom: 'Beni Aziz', wilaya_id: '19' },
  { id: '678', code_postal: '19004', nom: 'Ouled Sidi Ahmed', wilaya_id: '19' },
  { id: '679', code_postal: '19005', nom: 'Boutaleb', wilaya_id: '19' },
  { id: '680', code_postal: '19006', nom: 'Ain Roua', wilaya_id: '19' },
  { id: '681', code_postal: '19007', nom: 'Draa Kebila', wilaya_id: '19' },
  { id: '682', code_postal: '19008', nom: 'Bir El Arch', wilaya_id: '19' },
  { id: '683', code_postal: '19009', nom: 'Beni Chebana', wilaya_id: '19' },
  { id: '684', code_postal: '19010', nom: 'Ouled Tebben', wilaya_id: '19' },
  { id: '685', code_postal: '19011', nom: 'Hamma', wilaya_id: '19' },
  { id: '686', code_postal: '19012', nom: 'Maaouia', wilaya_id: '19' },
  { id: '687', code_postal: '19013', nom: 'Ain Legraj', wilaya_id: '19' },
  { id: '688', code_postal: '19014', nom: 'Ain Abessa', wilaya_id: '19' },
  { id: '689', code_postal: '19015', nom: 'Dehamcha', wilaya_id: '19' },
  { id: '690', code_postal: '19016', nom: 'Babor', wilaya_id: '19' },
  { id: '691', code_postal: '19017', nom: 'Guidjel', wilaya_id: '19' },
  { id: '692', code_postal: '19018', nom: 'Ain Lahdjar', wilaya_id: '19' },
  { id: '693', code_postal: '19019', nom: 'Bousselam', wilaya_id: '19' },
  { id: '694', code_postal: '19020', nom: 'El Eulma', wilaya_id: '19' },
  { id: '695', code_postal: '19021', nom: 'Djemila', wilaya_id: '19' },
  { id: '696', code_postal: '19022', nom: 'Beni Ouartilane', wilaya_id: '19' },
  { id: '697', code_postal: '19023', nom: 'Rosfa', wilaya_id: '19' },
  { id: '698', code_postal: '19024', nom: 'Ouled Addouane', wilaya_id: '19' },
  { id: '699', code_postal: '19025', nom: 'Belaa', wilaya_id: '19' },
  { id: '700', code_postal: '19026', nom: 'Ain Arnat', wilaya_id: '19' },
  { id: '701', code_postal: '19027', nom: 'Amoucha', wilaya_id: '19' },
  { id: '702', code_postal: '19028', nom: 'Ain Oulmane', wilaya_id: '19' },
  { id: '703', code_postal: '19029', nom: 'Beidha Bordj', wilaya_id: '19' },
  { id: '704', code_postal: '19030', nom: 'Bouandas', wilaya_id: '19' },
  { id: '705', code_postal: '19031', nom: 'Bazer Sakhra', wilaya_id: '19' },
  { id: '706', code_postal: '19032', nom: 'Hammam Essokhna', wilaya_id: '19' },
  { id: '707', code_postal: '19033', nom: 'Mezloug', wilaya_id: '19' },
  { id: '708', code_postal: '19034', nom: 'Bir Haddada', wilaya_id: '19' },
  { id: '709', code_postal: '19035', nom: 'Serdj El Ghoul', wilaya_id: '19' },
  { id: '710', code_postal: '19036', nom: 'Harbil', wilaya_id: '19' },
  { id: '711', code_postal: '19037', nom: 'El Ouricia', wilaya_id: '19' },
  { id: '712', code_postal: '19038', nom: 'Tizi Nbechar', wilaya_id: '19' },
  { id: '713', code_postal: '19039', nom: 'Salah Bey', wilaya_id: '19' },
  { id: '714', code_postal: '19040', nom: 'Ain Azal', wilaya_id: '19' },
  { id: '715', code_postal: '19041', nom: 'Guenzet', wilaya_id: '19' },
  { id: '716', code_postal: '19042', nom: 'Talaifacene', wilaya_id: '19' },
  { id: '717', code_postal: '19043', nom: 'Bougaa', wilaya_id: '19' },
  { id: '718', code_postal: '19044', nom: 'Beni Fouda', wilaya_id: '19' },
  { id: '719', code_postal: '19045', nom: 'Tachouda', wilaya_id: '19' },
  { id: '720', code_postal: '19046', nom: 'Beni Mouhli', wilaya_id: '19' },
  { id: '721', code_postal: '19047', nom: 'Ouled Sabor', wilaya_id: '19' },
  { id: '722', code_postal: '19048', nom: 'Guellal', wilaya_id: '19' },
  { id: '723', code_postal: '19049', nom: 'Ain Sebt', wilaya_id: '19' },
  { id: '724', code_postal: '19050', nom: 'Hammam Guergour', wilaya_id: '19' },
  { id: '725', code_postal: '19051', nom: 'Ait Naoual Mezada', wilaya_id: '19' },
  { id: '726', code_postal: '19052', nom: 'Ksar El Abtal', wilaya_id: '19' },
  { id: '727', code_postal: '19053', nom: 'Beni Hocine', wilaya_id: '19' },
  { id: '728', code_postal: '19054', nom: 'Ait Tizi', wilaya_id: '19' },
  { id: '729', code_postal: '19055', nom: 'Maouklane', wilaya_id: '19' },
  { id: '730', code_postal: '19056', nom: 'Guelta Zerka', wilaya_id: '19' },
  { id: '731', code_postal: '19057', nom: 'Oued El Barad', wilaya_id: '19' },
  { id: '732', code_postal: '19058', nom: 'Taya', wilaya_id: '19' },
  { id: '733', code_postal: '19059', nom: 'El Ouldja', wilaya_id: '19' },
  { id: '734', code_postal: '19060', nom: 'Tella', wilaya_id: '19' },
  { id: '735', code_postal: '20001', nom: 'Saida', wilaya_id: '20' },
  { id: '736', code_postal: '20002', nom: 'Doui Thabet', wilaya_id: '20' },
  { id: '737', code_postal: '20003', nom: 'Ain El Hadjar', wilaya_id: '20' },
  { id: '738', code_postal: '20004', nom: 'Ouled Khaled', wilaya_id: '20' },
  { id: '739', code_postal: '20005', nom: 'Moulay Larbi', wilaya_id: '20' },
  { id: '740', code_postal: '20006', nom: 'Youb', wilaya_id: '20' },
  { id: '741', code_postal: '20007', nom: 'Hounet', wilaya_id: '20' },
  { id: '742', code_postal: '20008', nom: 'Sidi Amar', wilaya_id: '20' },
  { id: '743', code_postal: '20009', nom: 'Sidi Boubekeur', wilaya_id: '20' },
  { id: '744', code_postal: '20010', nom: 'El Hassasna', wilaya_id: '20' },
  { id: '745', code_postal: '20011', nom: 'Maamora', wilaya_id: '20' },
  { id: '746', code_postal: '20012', nom: 'Sidi Ahmed', wilaya_id: '20' },
  { id: '747', code_postal: '20013', nom: 'Ain Sekhouna', wilaya_id: '20' },
  { id: '748', code_postal: '20014', nom: 'Ouled Brahim', wilaya_id: '20' },
  { id: '749', code_postal: '20015', nom: 'Tircine', wilaya_id: '20' },
  { id: '750', code_postal: '20016', nom: 'Ain Soltane', wilaya_id: '20' },
  { id: '751', code_postal: '21001', nom: 'Skikda', wilaya_id: '21' },
  { id: '752', code_postal: '21002', nom: 'Ain Zouit', wilaya_id: '21' },
  { id: '753', code_postal: '21003', nom: 'El Hadaik', wilaya_id: '21' },
  { id: '754', code_postal: '21004', nom: 'Azzaba', wilaya_id: '21' },
  { id: '755', code_postal: '21005', nom: 'Djendel Saadi Mohamed', wilaya_id: '21' },
  { id: '756', code_postal: '21006', nom: 'Ain Cherchar', wilaya_id: '21' },
  { id: '757', code_postal: '21007', nom: 'Bekkouche Lakhdar', wilaya_id: '21' },
  { id: '758', code_postal: '21008', nom: 'Benazouz', wilaya_id: '21' },
  { id: '759', code_postal: '21009', nom: 'Es Sebt', wilaya_id: '21' },
  { id: '760', code_postal: '21010', nom: 'Collo', wilaya_id: '21' },
  { id: '761', code_postal: '21011', nom: 'Beni Zid', wilaya_id: '21' },
  { id: '762', code_postal: '21012', nom: 'Kerkera', wilaya_id: '21' },
  { id: '763', code_postal: '21013', nom: 'Ouled Attia', wilaya_id: '21' },
  { id: '764', code_postal: '21014', nom: 'Oued Zehour', wilaya_id: '21' },
  { id: '765', code_postal: '21015', nom: 'Zitouna', wilaya_id: '21' },
  { id: '766', code_postal: '21016', nom: 'El Harrouch', wilaya_id: '21' },
  { id: '767', code_postal: '21017', nom: 'Zerdazas', wilaya_id: '21' },
  { id: '768', code_postal: '21018', nom: 'Ouled Hebaba', wilaya_id: '21' },
  { id: '769', code_postal: '21019', nom: 'Sidi Mezghiche', wilaya_id: '21' },
  { id: '770', code_postal: '21020', nom: 'Emdjez Edchich', wilaya_id: '21' },
  { id: '771', code_postal: '21021', nom: 'Beni Oulbane', wilaya_id: '21' },
  { id: '772', code_postal: '21022', nom: 'Ain Bouziane', wilaya_id: '21' },
  { id: '773', code_postal: '21023', nom: 'Ramdane Djamel', wilaya_id: '21' },
  { id: '774', code_postal: '21024', nom: 'Beni Bachir', wilaya_id: '21' },
  { id: '775', code_postal: '21025', nom: 'Salah Bouchaour', wilaya_id: '21' },
  { id: '776', code_postal: '21026', nom: 'Tamalous', wilaya_id: '21' },
  { id: '777', code_postal: '21027', nom: 'Ain Kechra', wilaya_id: '21' },
  { id: '778', code_postal: '21028', nom: 'Oum Toub', wilaya_id: '21' },
  { id: '779', code_postal: '21029', nom: 'Bein El Ouiden', wilaya_id: '21' },
  { id: '780', code_postal: '21030', nom: 'Fil Fila', wilaya_id: '21' },
  { id: '781', code_postal: '21031', nom: 'Cheraia', wilaya_id: '21' },
  { id: '782', code_postal: '21032', nom: 'Kanoua', wilaya_id: '21' },
  { id: '783', code_postal: '21033', nom: 'El Ghedir', wilaya_id: '21' },
  { id: '784', code_postal: '21034', nom: 'Bouchtata', wilaya_id: '21' },
  { id: '785', code_postal: '21035', nom: 'Ouldja Boulbalout', wilaya_id: '21' },
  { id: '786', code_postal: '21036', nom: 'Kheneg Mayoum', wilaya_id: '21' },
  { id: '787', code_postal: '21037', nom: 'Hamadi Krouma', wilaya_id: '21' },
  { id: '788', code_postal: '21038', nom: 'El Marsa', wilaya_id: '21' },
  { id: '789', code_postal: '22001', nom: 'Sidi Bel Abbes', wilaya_id: '22' },
  { id: '790', code_postal: '22002', nom: 'Tessala', wilaya_id: '22' },
  { id: '791', code_postal: '22003', nom: 'Sidi Brahim', wilaya_id: '22' },
  { id: '792', code_postal: '22004', nom: 'Mostefa Ben Brahim', wilaya_id: '22' },
  { id: '793', code_postal: '22005', nom: 'Telagh', wilaya_id: '22' },
  { id: '794', code_postal: '22006', nom: 'Mezaourou', wilaya_id: '22' },
  { id: '795', code_postal: '22007', nom: 'Boukhanafis', wilaya_id: '22' },
  { id: '796', code_postal: '22008', nom: 'Sidi Ali Boussidi', wilaya_id: '22' },
  { id: '797', code_postal: '22009', nom: 'Badredine El Mokrani', wilaya_id: '22' },
  { id: '798', code_postal: '22010', nom: 'Marhoum', wilaya_id: '22' },
  { id: '799', code_postal: '22011', nom: 'Tafissour', wilaya_id: '22' },
  { id: '800', code_postal: '22012', nom: 'Amarnas', wilaya_id: '22' },
  { id: '801', code_postal: '22013', nom: 'Tilmouni', wilaya_id: '22' },
  { id: '802', code_postal: '22014', nom: 'Sidi Lahcene', wilaya_id: '22' },
  { id: '803', code_postal: '22015', nom: 'Ain Thrid', wilaya_id: '22' },
  { id: '804', code_postal: '22016', nom: 'Makedra', wilaya_id: '22' },
  { id: '805', code_postal: '22017', nom: 'Tenira', wilaya_id: '22' },
  { id: '806', code_postal: '22018', nom: 'Moulay Slissen', wilaya_id: '22' },
  { id: '807', code_postal: '22019', nom: 'El Hacaiba', wilaya_id: '22' },
  { id: '808', code_postal: '22020', nom: 'Hassi Zehana', wilaya_id: '22' },
  { id: '809', code_postal: '22021', nom: 'Tabia', wilaya_id: '22' },
  { id: '810', code_postal: '22022', nom: 'Merine', wilaya_id: '22' },
  { id: '811', code_postal: '22023', nom: 'Ras El Ma', wilaya_id: '22' },
  { id: '812', code_postal: '22024', nom: 'Ain Tindamine', wilaya_id: '22' },
  { id: '813', code_postal: '22025', nom: 'Ain Kada', wilaya_id: '22' },
  { id: '814', code_postal: '22026', nom: 'Mcid', wilaya_id: '22' },
  { id: '815', code_postal: '22027', nom: 'Sidi Khaled', wilaya_id: '22' },
  { id: '816', code_postal: '22028', nom: 'Ain El Berd', wilaya_id: '22' },
  { id: '817', code_postal: '22029', nom: 'Sfissef', wilaya_id: '22' },
  { id: '818', code_postal: '22030', nom: 'Ain Adden', wilaya_id: '22' },
  { id: '819', code_postal: '22031', nom: 'Oued Taourira', wilaya_id: '22' },
  { id: '820', code_postal: '22032', nom: 'Dhaya', wilaya_id: '22' },
  { id: '821', code_postal: '22033', nom: 'Zerouala', wilaya_id: '22' },
  { id: '822', code_postal: '22034', nom: 'Lamtar', wilaya_id: '22' },
  { id: '823', code_postal: '22035', nom: 'Sidi Chaib', wilaya_id: '22' },
  { id: '824', code_postal: '22036', nom: 'Sidi Dahou Dezairs', wilaya_id: '22' },
  { id: '825', code_postal: '22037', nom: 'Oued Sbaa', wilaya_id: '22' },
  { id: '826', code_postal: '22038', nom: 'Boudjebaa El Bordj', wilaya_id: '22' },
  { id: '827', code_postal: '22039', nom: 'Sehala Thaoura', wilaya_id: '22' },
  { id: '828', code_postal: '22040', nom: 'Sidi Yacoub', wilaya_id: '22' },
  { id: '829', code_postal: '22041', nom: 'Sidi Hamadouche', wilaya_id: '22' },
  { id: '830', code_postal: '22042', nom: 'Belarbi', wilaya_id: '22' },
  { id: '831', code_postal: '22043', nom: 'Oued Sefioun', wilaya_id: '22' },
  { id: '832', code_postal: '22044', nom: 'Teghalimet', wilaya_id: '22' },
  { id: '833', code_postal: '22045', nom: 'Ben Badis', wilaya_id: '22' },
  { id: '834', code_postal: '22046', nom: 'Sidi Ali Benyoub', wilaya_id: '22' },
  { id: '835', code_postal: '22047', nom: 'Chetouane Belaila', wilaya_id: '22' },
  { id: '836', code_postal: '22048', nom: 'Bir El Hammam', wilaya_id: '22' },
  { id: '837', code_postal: '22049', nom: 'Taoudmout', wilaya_id: '22' },
  { id: '838', code_postal: '22050', nom: 'Redjem Demouche', wilaya_id: '22' },
  { id: '839', code_postal: '22051', nom: 'Benachiba Chelia', wilaya_id: '22' },
  { id: '840', code_postal: '22052', nom: 'Hassi Dahou', wilaya_id: '22' },
  { id: '841', code_postal: '23001', nom: 'Annaba', wilaya_id: '23' },
  { id: '842', code_postal: '23002', nom: 'Berrahel', wilaya_id: '23' },
  { id: '843', code_postal: '23003', nom: 'El Hadjar', wilaya_id: '23' },
  { id: '844', code_postal: '23004', nom: 'Eulma', wilaya_id: '23' },
  { id: '845', code_postal: '23005', nom: 'El Bouni', wilaya_id: '23' },
  { id: '846', code_postal: '23006', nom: 'Oued El Aneb', wilaya_id: '23' },
  { id: '847', code_postal: '23007', nom: 'Cheurfa', wilaya_id: '23' },
  { id: '848', code_postal: '23008', nom: 'Seraidi', wilaya_id: '23' },
  { id: '849', code_postal: '23009', nom: 'Ain Berda', wilaya_id: '23' },
  { id: '850', code_postal: '23010', nom: 'Chetaibi', wilaya_id: '23' },
  { id: '851', code_postal: '23011', nom: 'Sidi Amer', wilaya_id: '23' },
  { id: '852', code_postal: '23012', nom: 'Treat', wilaya_id: '23' },
  { id: '853', code_postal: '24001', nom: 'Guelma', wilaya_id: '24' },
  { id: '854', code_postal: '24002', nom: 'Nechmaya', wilaya_id: '24' },
  { id: '855', code_postal: '24003', nom: 'Bouati Mahmoud', wilaya_id: '24' },
  { id: '856', code_postal: '24004', nom: 'Oued Zenati', wilaya_id: '24' },
  { id: '857', code_postal: '24005', nom: 'Tamlouka', wilaya_id: '24' },
  { id: '858', code_postal: '24006', nom: 'Oued Fragha', wilaya_id: '24' },
  { id: '859', code_postal: '24007', nom: 'Ain Sandel', wilaya_id: '24' },
  { id: '860', code_postal: '24008', nom: 'Ras El Agba', wilaya_id: '24' },
  { id: '861', code_postal: '24009', nom: 'Dahouara', wilaya_id: '24' },
  { id: '862', code_postal: '24010', nom: 'Belkhir', wilaya_id: '24' },
  { id: '863', code_postal: '24011', nom: 'Ben Djarah', wilaya_id: '24' },
  { id: '864', code_postal: '24012', nom: 'Bou Hamdane', wilaya_id: '24' },
  { id: '865', code_postal: '24013', nom: 'Ain Makhlouf', wilaya_id: '24' },
  { id: '866', code_postal: '24014', nom: 'Ain Ben Beida', wilaya_id: '24' },
  { id: '867', code_postal: '24015', nom: 'Khezara', wilaya_id: '24' },
  { id: '868', code_postal: '24016', nom: 'Beni Mezline', wilaya_id: '24' },
  { id: '869', code_postal: '24017', nom: 'Bou Hachana', wilaya_id: '24' },
  { id: '870', code_postal: '24018', nom: 'Guelaat Bou Sbaa', wilaya_id: '24' },
  { id: '871', code_postal: '24019', nom: 'Hammam Maskhoutine', wilaya_id: '24' },
  { id: '872', code_postal: '24020', nom: 'El Fedjoudj', wilaya_id: '24' },
  { id: '873', code_postal: '24021', nom: 'Bordj Sabat', wilaya_id: '24' },
  { id: '874', code_postal: '24022', nom: 'Hamman Nbail', wilaya_id: '24' },
  { id: '875', code_postal: '24023', nom: 'Ain Larbi', wilaya_id: '24' },
  { id: '876', code_postal: '24024', nom: 'Medjez Amar', wilaya_id: '24' },
  { id: '877', code_postal: '24025', nom: 'Bouchegouf', wilaya_id: '24' },
  { id: '878', code_postal: '24026', nom: 'Heliopolis', wilaya_id: '24' },
  { id: '879', code_postal: '24027', nom: 'Ain Hessania', wilaya_id: '24' },
  { id: '880', code_postal: '24028', nom: 'Roknia', wilaya_id: '24' },
  { id: '881', code_postal: '24029', nom: 'Salaoua Announa', wilaya_id: '24' },
  { id: '882', code_postal: '24030', nom: 'Medjez Sfa', wilaya_id: '24' },
  { id: '883', code_postal: '24031', nom: 'Boumahra Ahmed', wilaya_id: '24' },
  { id: '884', code_postal: '24032', nom: 'Ain Reggada', wilaya_id: '24' },
  { id: '885', code_postal: '24033', nom: 'Oued Cheham', wilaya_id: '24' },
  { id: '886', code_postal: '24034', nom: 'Djeballah Khemissi', wilaya_id: '24' },
  { id: '887', code_postal: '25001', nom: 'Constantine', wilaya_id: '25' },
  { id: '888', code_postal: '25002', nom: 'Hamma Bouziane', wilaya_id: '25' },
  { id: '889', code_postal: '25003', nom: 'El Haria', wilaya_id: '25' },
  { id: '890', code_postal: '25004', nom: 'Zighoud Youcef', wilaya_id: '25' },
  { id: '891', code_postal: '25005', nom: 'Didouche Mourad', wilaya_id: '25' },
  { id: '892', code_postal: '25006', nom: 'El Khroub', wilaya_id: '25' },
  { id: '893', code_postal: '25007', nom: 'Ain Abid', wilaya_id: '25' },
  { id: '894', code_postal: '25008', nom: 'Beni Hamiden', wilaya_id: '25' },
  { id: '895', code_postal: '25009', nom: 'Ouled Rahmoune', wilaya_id: '25' },
  { id: '896', code_postal: '25010', nom: 'Ain Smara', wilaya_id: '25' },
  { id: '897', code_postal: '25011', nom: 'Mesaoud Boudjeriou', wilaya_id: '25' },
  { id: '898', code_postal: '25012', nom: 'Ibn Ziad', wilaya_id: '25' },
  { id: '899', code_postal: '26001', nom: 'Medea', wilaya_id: '26' },
  { id: '900', code_postal: '26002', nom: 'Ouzera', wilaya_id: '26' },
  { id: '901', code_postal: '26003', nom: 'Ouled Maaref', wilaya_id: '26' },
  { id: '902', code_postal: '26004', nom: 'Ain Boucif', wilaya_id: '26' },
  { id: '903', code_postal: '26005', nom: 'Aissaouia', wilaya_id: '26' },
  { id: '904', code_postal: '26006', nom: 'Ouled Deide', wilaya_id: '26' },
  { id: '905', code_postal: '26007', nom: 'El Omaria', wilaya_id: '26' },
  { id: '906', code_postal: '26008', nom: 'Derrag', wilaya_id: '26' },
  { id: '907', code_postal: '26009', nom: 'El Guelbelkebir', wilaya_id: '26' },
  { id: '908', code_postal: '26010', nom: 'Bouaiche', wilaya_id: '26' },
  { id: '909', code_postal: '26011', nom: 'Mezerena', wilaya_id: '26' },
  { id: '910', code_postal: '26012', nom: 'Ouled Brahim', wilaya_id: '26' },
  { id: '911', code_postal: '26013', nom: 'Damiat', wilaya_id: '26' },
  { id: '912', code_postal: '26014', nom: 'Sidi Ziane', wilaya_id: '26' },
  { id: '913', code_postal: '26015', nom: 'Tamesguida', wilaya_id: '26' },
  { id: '914', code_postal: '26016', nom: 'El Hamdania', wilaya_id: '26' },
  { id: '915', code_postal: '26017', nom: 'Kef Lakhdar', wilaya_id: '26' },
  { id: '916', code_postal: '26018', nom: 'Chelalet El Adhaoura', wilaya_id: '26' },
  { id: '917', code_postal: '26019', nom: 'Bouskene', wilaya_id: '26' },
  { id: '918', code_postal: '26020', nom: 'Rebaia', wilaya_id: '26' },
  { id: '919', code_postal: '26021', nom: 'Bouchrahil', wilaya_id: '26' },
  { id: '920', code_postal: '26022', nom: 'Ouled Hellal', wilaya_id: '26' },
  { id: '921', code_postal: '26023', nom: 'Tafraout', wilaya_id: '26' },
  { id: '922', code_postal: '26024', nom: 'Baata', wilaya_id: '26' },
  { id: '923', code_postal: '26025', nom: 'Boghar', wilaya_id: '26' },
  { id: '924', code_postal: '26026', nom: 'Sidi Naamane', wilaya_id: '26' },
  { id: '925', code_postal: '26027', nom: 'Ouled Bouachra', wilaya_id: '26' },
  { id: '926', code_postal: '26028', nom: 'Sidi Zahar', wilaya_id: '26' },
  { id: '927', code_postal: '26029', nom: 'Oued Harbil', wilaya_id: '26' },
  { id: '928', code_postal: '26030', nom: 'Benchicao', wilaya_id: '26' },
  { id: '929', code_postal: '26031', nom: 'Sidi Damed', wilaya_id: '26' },
  { id: '930', code_postal: '26032', nom: 'Aziz', wilaya_id: '26' },
  { id: '931', code_postal: '26033', nom: 'Souagui', wilaya_id: '26' },
  { id: '932', code_postal: '26034', nom: 'Zoubiria', wilaya_id: '26' },
  { id: '933', code_postal: '26035', nom: 'Ksar El Boukhari', wilaya_id: '26' },
  { id: '934', code_postal: '26036', nom: 'El Azizia', wilaya_id: '26' },
  { id: '935', code_postal: '26037', nom: 'Djouab', wilaya_id: '26' },
  { id: '936', code_postal: '26038', nom: 'Chahbounia', wilaya_id: '26' },
  { id: '937', code_postal: '26039', nom: 'Meghraoua', wilaya_id: '26' },
  { id: '938', code_postal: '26040', nom: 'Cheniguel', wilaya_id: '26' },
  { id: '939', code_postal: '26041', nom: 'Ain Ouksir', wilaya_id: '26' },
  { id: '940', code_postal: '26042', nom: 'Oum El Djalil', wilaya_id: '26' },
  { id: '941', code_postal: '26043', nom: 'Ouamri', wilaya_id: '26' },
  { id: '942', code_postal: '26044', nom: 'Si Mahdjoub', wilaya_id: '26' },
  { id: '943', code_postal: '26045', nom: 'Tlatet Eddoair', wilaya_id: '26' },
  { id: '944', code_postal: '26046', nom: 'Beni Slimane', wilaya_id: '26' },
  { id: '945', code_postal: '26047', nom: 'Berrouaghia', wilaya_id: '26' },
  { id: '946', code_postal: '26048', nom: 'Seghouane', wilaya_id: '26' },
  { id: '947', code_postal: '26049', nom: 'Meftaha', wilaya_id: '26' },
  { id: '948', code_postal: '26050', nom: 'Mihoub', wilaya_id: '26' },
  { id: '949', code_postal: '26051', nom: 'Boughezoul', wilaya_id: '26' },
  { id: '950', code_postal: '26052', nom: 'Tablat', wilaya_id: '26' },
  { id: '951', code_postal: '26053', nom: 'Deux Bassins', wilaya_id: '26' },
  { id: '952', code_postal: '26054', nom: 'Draa Essamar', wilaya_id: '26' },
  { id: '953', code_postal: '26055', nom: 'Sidi Errabia', wilaya_id: '26' },
  { id: '954', code_postal: '26056', nom: 'Bir Ben Laabed', wilaya_id: '26' },
  { id: '955', code_postal: '26057', nom: 'El Ouinet', wilaya_id: '26' },
  { id: '956', code_postal: '26058', nom: 'Ouled Antar', wilaya_id: '26' },
  { id: '957', code_postal: '26059', nom: 'Bouaichoune', wilaya_id: '26' },
  { id: '958', code_postal: '26060', nom: 'Hannacha', wilaya_id: '26' },
  { id: '959', code_postal: '26061', nom: 'Sedraia', wilaya_id: '26' },
  { id: '960', code_postal: '26062', nom: 'Medjebar', wilaya_id: '26' },
  { id: '961', code_postal: '26063', nom: 'Khams Djouamaa', wilaya_id: '26' },
  { id: '962', code_postal: '26064', nom: 'Saneg', wilaya_id: '26' },
  { id: '963', code_postal: '27001', nom: 'Mostaganem', wilaya_id: '27' },
  { id: '964', code_postal: '27002', nom: 'Sayada', wilaya_id: '27' },
  { id: '965', code_postal: '27003', nom: 'Fornaka', wilaya_id: '27' },
  { id: '966', code_postal: '27004', nom: 'Stidia', wilaya_id: '27' },
  { id: '967', code_postal: '27005', nom: 'Ain Nouissy', wilaya_id: '27' },
  { id: '968', code_postal: '27006', nom: 'Hassi Maameche', wilaya_id: '27' },
  { id: '969', code_postal: '27007', nom: 'Ain Tadles', wilaya_id: '27' },
  { id: '970', code_postal: '27008', nom: 'Sour', wilaya_id: '27' },
  { id: '971', code_postal: '27009', nom: 'Oued El Kheir', wilaya_id: '27' },
  { id: '972', code_postal: '27010', nom: 'Sidi Bellater', wilaya_id: '27' },
  { id: '973', code_postal: '27011', nom: 'Kheiredine\u00a0', wilaya_id: '27' },
  { id: '974', code_postal: '27012', nom: 'Sidi Ali', wilaya_id: '27' },
  { id: '975', code_postal: '27013', nom: 'Abdelmalek Ramdane', wilaya_id: '27' },
  { id: '976', code_postal: '27014', nom: 'Hadjadj', wilaya_id: '27' },
  { id: '977', code_postal: '27015', nom: 'Nekmaria', wilaya_id: '27' },
  { id: '978', code_postal: '27016', nom: 'Sidi Lakhdar', wilaya_id: '27' },
  { id: '979', code_postal: '27017', nom: 'Achaacha', wilaya_id: '27' },
  { id: '980', code_postal: '27018', nom: 'Khadra', wilaya_id: '27' },
  { id: '981', code_postal: '27019', nom: 'Bouguirat', wilaya_id: '27' },
  { id: '982', code_postal: '27020', nom: 'Sirat', wilaya_id: '27' },
  { id: '983', code_postal: '27021', nom: 'Ain Sidi Cherif', wilaya_id: '27' },
  { id: '984', code_postal: '27022', nom: 'Mesra', wilaya_id: '27' },
  { id: '985', code_postal: '27023', nom: 'Mansourah', wilaya_id: '27' },
  { id: '986', code_postal: '27024', nom: 'Souaflia', wilaya_id: '27' },
  { id: '987', code_postal: '27025', nom: 'Ouled Boughalem', wilaya_id: '27' },
  { id: '988', code_postal: '27026', nom: 'Ouled Maallah', wilaya_id: '27' },
  { id: '989', code_postal: '27027', nom: 'Mezghrane', wilaya_id: '27' },
  { id: '990', code_postal: '27028', nom: 'Ain Boudinar', wilaya_id: '27' },
  { id: '991', code_postal: '27029', nom: 'Tazgait', wilaya_id: '27' },
  { id: '992', code_postal: '27030', nom: 'Safsaf', wilaya_id: '27' },
  { id: '993', code_postal: '27031', nom: 'Touahria', wilaya_id: '27' },
  { id: '994', code_postal: '27032', nom: 'El Hassiane', wilaya_id: '27' },
  { id: '995', code_postal: '28001', nom: 'Msila', wilaya_id: '28' },
  { id: '996', code_postal: '28002', nom: 'Maadid', wilaya_id: '28' },
  { id: '997', code_postal: '28003', nom: 'Hammam Dhalaa', wilaya_id: '28' },
  { id: '998', code_postal: '28004', nom: 'Ouled Derradj', wilaya_id: '28' },
  { id: '999', code_postal: '28005', nom: 'Tarmount', wilaya_id: '28' },
  { id: '1000', code_postal: '28006', nom: 'Mtarfa', wilaya_id: '28' },
  { id: '1001', code_postal: '28007', nom: 'Khoubana', wilaya_id: '28' },
  { id: '1002', code_postal: '28008', nom: 'Mcif', wilaya_id: '28' },
  { id: '1003', code_postal: '28009', nom: 'Chellal', wilaya_id: '28' },
  { id: '1004', code_postal: '28010', nom: 'Ouled Madhi', wilaya_id: '28' },
  { id: '1005', code_postal: '28011', nom: 'Magra', wilaya_id: '28' },
  { id: '1006', code_postal: '28012', nom: 'Berhoum', wilaya_id: '28' },
  { id: '1007', code_postal: '28013', nom: 'Ain Khadra', wilaya_id: '28' },
  { id: '1008', code_postal: '28014', nom: 'Ouled Addi Guebala', wilaya_id: '28' },
  { id: '1009', code_postal: '28015', nom: 'Belaiba', wilaya_id: '28' },
  { id: '1010', code_postal: '28016', nom: 'Sidi Aissa', wilaya_id: '28' },
  { id: '1011', code_postal: '28017', nom: 'Ain El Hadjel', wilaya_id: '28' },
  { id: '1012', code_postal: '28018', nom: 'Sidi Hadjeres', wilaya_id: '28' },
  { id: '1013', code_postal: '28019', nom: 'Ouanougha', wilaya_id: '28' },
  { id: '1014', code_postal: '28020', nom: 'Bou Saada', wilaya_id: '28' },
  { id: '1015', code_postal: '28021', nom: 'Ouled Sidi Brahim', wilaya_id: '28' },
  { id: '1016', code_postal: '28022', nom: 'Sidi Ameur', wilaya_id: '28' },
  { id: '1017', code_postal: '28023', nom: 'Tamsa', wilaya_id: '28' },
  { id: '1018', code_postal: '28024', nom: 'Ben Srour', wilaya_id: '28' },
  { id: '1019', code_postal: '28025', nom: 'Ouled Slimane', wilaya_id: '28' },
  { id: '1020', code_postal: '28026', nom: 'El Houamed', wilaya_id: '28' },
  { id: '1021', code_postal: '28027', nom: 'El Hamel', wilaya_id: '28' },
  { id: '1022', code_postal: '28028', nom: 'Ouled Mansour', wilaya_id: '28' },
  { id: '1023', code_postal: '28029', nom: 'Maarif', wilaya_id: '28' },
  { id: '1024', code_postal: '28030', nom: 'Dehahna', wilaya_id: '28' },
  { id: '1025', code_postal: '28031', nom: 'Bouti Sayah', wilaya_id: '28' },
  { id: '1026', code_postal: '28032', nom: 'Khettouti Sed Djir', wilaya_id: '28' },
  { id: '1027', code_postal: '28033', nom: 'Zarzour', wilaya_id: '28' },
  { id: '1028', code_postal: '28034', nom: 'Oued Chair', wilaya_id: '28' },
  { id: '1029', code_postal: '28035', nom: 'Benzouh', wilaya_id: '28' },
  { id: '1030', code_postal: '28036', nom: 'Bir Foda', wilaya_id: '28' },
  { id: '1031', code_postal: '28037', nom: 'Ain Fares', wilaya_id: '28' },
  { id: '1032', code_postal: '28038', nom: 'Sidi Mhamed', wilaya_id: '28' },
  { id: '1033', code_postal: '28039', nom: 'Ouled Atia', wilaya_id: '28' },
  { id: '1034', code_postal: '28040', nom: 'Souamaa', wilaya_id: '28' },
  { id: '1035', code_postal: '28041', nom: 'Ain El Melh', wilaya_id: '28' },
  { id: '1036', code_postal: '28042', nom: 'Medjedel', wilaya_id: '28' },
  { id: '1037', code_postal: '28043', nom: 'Slim', wilaya_id: '28' },
  { id: '1038', code_postal: '28044', nom: 'Ain Errich', wilaya_id: '28' },
  { id: '1039', code_postal: '28045', nom: 'Beni Ilmane', wilaya_id: '28' },
  { id: '1040', code_postal: '28046', nom: 'Oultene', wilaya_id: '28' },
  { id: '1041', code_postal: '28047', nom: 'Djebel Messaad', wilaya_id: '28' },
  { id: '1042', code_postal: '29001', nom: 'Mascara', wilaya_id: '29' },
  { id: '1043', code_postal: '29002', nom: 'Bou Hanifia', wilaya_id: '29' },
  { id: '1044', code_postal: '29003', nom: 'Tizi', wilaya_id: '29' },
  { id: '1045', code_postal: '29004', nom: 'Hacine', wilaya_id: '29' },
  { id: '1046', code_postal: '29005', nom: 'Maoussa', wilaya_id: '29' },
  { id: '1047', code_postal: '29006', nom: 'Teghennif', wilaya_id: '29' },
  { id: '1048', code_postal: '29007', nom: 'El Hachem', wilaya_id: '29' },
  { id: '1049', code_postal: '29008', nom: 'Sidi Kada', wilaya_id: '29' },
  { id: '1050', code_postal: '29009', nom: 'Zelmata', wilaya_id: '29' },
  { id: '1051', code_postal: '29010', nom: 'Oued El Abtal', wilaya_id: '29' },
  { id: '1052', code_postal: '29011', nom: 'Ain Ferah', wilaya_id: '29' },
  { id: '1053', code_postal: '29012', nom: 'Ghriss', wilaya_id: '29' },
  { id: '1054', code_postal: '29013', nom: 'Froha', wilaya_id: '29' },
  { id: '1055', code_postal: '29014', nom: 'Matemore', wilaya_id: '29' },
  { id: '1056', code_postal: '29015', nom: 'Makdha', wilaya_id: '29' },
  { id: '1057', code_postal: '29016', nom: 'Sidi Boussaid', wilaya_id: '29' },
  { id: '1058', code_postal: '29017', nom: 'El Bordj', wilaya_id: '29' },
  { id: '1059', code_postal: '29018', nom: 'Ain Fekan', wilaya_id: '29' },
  { id: '1060', code_postal: '29019', nom: 'Benian', wilaya_id: '29' },
  { id: '1061', code_postal: '29020', nom: 'Khalouia', wilaya_id: '29' },
  { id: '1062', code_postal: '29021', nom: 'El Menaouer', wilaya_id: '29' },
  { id: '1063', code_postal: '29022', nom: 'Oued Taria', wilaya_id: '29' },
  { id: '1064', code_postal: '29023', nom: 'Aouf', wilaya_id: '29' },
  { id: '1065', code_postal: '29024', nom: 'Ain Fares', wilaya_id: '29' },
  { id: '1066', code_postal: '29025', nom: 'Ain Frass', wilaya_id: '29' },
  { id: '1067', code_postal: '29026', nom: 'Sig', wilaya_id: '29' },
  { id: '1068', code_postal: '29027', nom: 'Oggaz', wilaya_id: '29' },
  { id: '1069', code_postal: '29028', nom: 'Alaimia', wilaya_id: '29' },
  { id: '1070', code_postal: '29029', nom: 'El Gaada', wilaya_id: '29' },
  { id: '1071', code_postal: '29030', nom: 'Zahana', wilaya_id: '29' },
  { id: '1072', code_postal: '29031', nom: 'Mohammadia', wilaya_id: '29' },
  { id: '1073', code_postal: '29032', nom: 'Sidi Abdelmoumene', wilaya_id: '29' },
  { id: '1074', code_postal: '29033', nom: 'Ferraguig', wilaya_id: '29' },
  { id: '1075', code_postal: '29034', nom: 'El Ghomri', wilaya_id: '29' },
  { id: '1076', code_postal: '29035', nom: 'Sedjerara', wilaya_id: '29' },
  { id: '1077', code_postal: '29036', nom: 'Moctadouz', wilaya_id: '29' },
  { id: '1078', code_postal: '29037', nom: 'Bou Henni', wilaya_id: '29' },
  { id: '1079', code_postal: '29038', nom: 'Guettena', wilaya_id: '29' },
  { id: '1080', code_postal: '29039', nom: 'El Mamounia', wilaya_id: '29' },
  { id: '1081', code_postal: '29040', nom: 'El Keurt', wilaya_id: '29' },
  { id: '1082', code_postal: '29041', nom: 'Gharrous', wilaya_id: '29' },
  { id: '1083', code_postal: '29042', nom: 'Gherdjoum', wilaya_id: '29' },
  { id: '1084', code_postal: '29043', nom: 'Chorfa', wilaya_id: '29' },
  { id: '1085', code_postal: '29044', nom: 'Ras Ain Amirouche', wilaya_id: '29' },
  { id: '1086', code_postal: '29045', nom: 'Nesmot', wilaya_id: '29' },
  { id: '1087', code_postal: '29046', nom: 'Sidi Abdeldjebar', wilaya_id: '29' },
  { id: '1088', code_postal: '29047', nom: 'Sehailia', wilaya_id: '29' },
  { id: '1089', code_postal: '30001', nom: 'Ouargla', wilaya_id: '30' },
  { id: '1090', code_postal: '30002', nom: 'Ain Beida', wilaya_id: '30' },
  { id: '1091', code_postal: '30003', nom: 'Ngoussa', wilaya_id: '30' },
  { id: '1092', code_postal: '30004', nom: 'Hassi Messaoud', wilaya_id: '30' },
  { id: '1093', code_postal: '30005', nom: 'Rouissat', wilaya_id: '30' },
  { id: '1094', code_postal: '30006', nom: 'Balidat Ameur', wilaya_id: '30' },
  { id: '1095', code_postal: '30007', nom: 'Tebesbest', wilaya_id: '30' },
  { id: '1096', code_postal: '30008', nom: 'Nezla', wilaya_id: '30' },
  { id: '1097', code_postal: '30009', nom: 'Zaouia El Abidia', wilaya_id: '30' },
  { id: '1098', code_postal: '30010', nom: 'Sidi Slimane', wilaya_id: '30' },
  { id: '1099', code_postal: '30011', nom: 'Sidi Khouiled', wilaya_id: '30' },
  { id: '1100', code_postal: '30012', nom: 'Hassi Ben Abdellah', wilaya_id: '30' },
  { id: '1101', code_postal: '30013', nom: 'Touggourt', wilaya_id: '30' },
  { id: '1102', code_postal: '30014', nom: 'El Hadjira', wilaya_id: '30' },
  { id: '1103', code_postal: '30015', nom: 'Taibet', wilaya_id: '30' },
  { id: '1104', code_postal: '30016', nom: 'Tamacine', wilaya_id: '30' },
  { id: '1105', code_postal: '30017', nom: 'Benaceur', wilaya_id: '30' },
  { id: '1106', code_postal: '30018', nom: 'Mnaguer', wilaya_id: '30' },
  { id: '1107', code_postal: '30019', nom: 'Megarine', wilaya_id: '30' },
  { id: '1108', code_postal: '30020', nom: 'El Allia', wilaya_id: '30' },
  { id: '1109', code_postal: '30021', nom: 'El Borma', wilaya_id: '30' },
  { id: '1110', code_postal: '31001', nom: 'Oran', wilaya_id: '31' },
  { id: '1111', code_postal: '31002', nom: 'Gdyel', wilaya_id: '31' },
  { id: '1112', code_postal: '31003', nom: 'Bir El Djir', wilaya_id: '31' },
  { id: '1113', code_postal: '31004', nom: 'Hassi Bounif', wilaya_id: '31' },
  { id: '1114', code_postal: '31005', nom: 'Es Senia', wilaya_id: '31' },
  { id: '1115', code_postal: '31006', nom: 'Arzew', wilaya_id: '31' },
  { id: '1116', code_postal: '31007', nom: 'Bethioua', wilaya_id: '31' },
  { id: '1117', code_postal: '31008', nom: 'Marsat El Hadjadj', wilaya_id: '31' },
  { id: '1118', code_postal: '31009', nom: 'Ain Turk', wilaya_id: '31' },
  { id: '1119', code_postal: '31010', nom: 'El Ancar', wilaya_id: '31' },
  { id: '1120', code_postal: '31011', nom: 'Oued Tlelat', wilaya_id: '31' },
  { id: '1121', code_postal: '31012', nom: 'Tafraoui', wilaya_id: '31' },
  { id: '1122', code_postal: '31013', nom: 'Sidi Chami', wilaya_id: '31' },
  { id: '1123', code_postal: '31014', nom: 'Boufatis', wilaya_id: '31' },
  { id: '1124', code_postal: '31015', nom: 'Mers El Kebir', wilaya_id: '31' },
  { id: '1125', code_postal: '31016', nom: 'Bousfer', wilaya_id: '31' },
  { id: '1126', code_postal: '31017', nom: 'El Karma', wilaya_id: '31' },
  { id: '1127', code_postal: '31018', nom: 'El Braya', wilaya_id: '31' },
  { id: '1128', code_postal: '31019', nom: 'Hassi Ben Okba', wilaya_id: '31' },
  { id: '1129', code_postal: '31020', nom: 'Ben Freha', wilaya_id: '31' },
  { id: '1130', code_postal: '31021', nom: 'Hassi Mefsoukh', wilaya_id: '31' },
  { id: '1131', code_postal: '31022', nom: 'Sidi Ben Yabka', wilaya_id: '31' },
  { id: '1132', code_postal: '31023', nom: 'Messerghin', wilaya_id: '31' },
  { id: '1133', code_postal: '31024', nom: 'Boutlelis', wilaya_id: '31' },
  { id: '1134', code_postal: '31025', nom: 'Ain Kerma', wilaya_id: '31' },
  { id: '1135', code_postal: '31026', nom: 'Ain Biya', wilaya_id: '31' },
  { id: '1136', code_postal: '32001', nom: 'El Bayadh', wilaya_id: '32' },
  { id: '1137', code_postal: '32002', nom: 'Rogassa', wilaya_id: '32' },
  { id: '1138', code_postal: '32003', nom: 'Stitten', wilaya_id: '32' },
  { id: '1139', code_postal: '32004', nom: 'Brezina', wilaya_id: '32' },
  { id: '1140', code_postal: '32005', nom: 'Ghassoul', wilaya_id: '32' },
  { id: '1141', code_postal: '32006', nom: 'Boualem', wilaya_id: '32' },
  { id: '1142', code_postal: '32007', nom: 'El Abiodh Sidi Cheikh', wilaya_id: '32' },
  { id: '1143', code_postal: '32008', nom: 'Ain El Orak', wilaya_id: '32' },
  { id: '1144', code_postal: '32009', nom: 'Arbaouat', wilaya_id: '32' },
  { id: '1145', code_postal: '32010', nom: 'Bougtoub', wilaya_id: '32' },
  { id: '1146', code_postal: '32011', nom: 'El Kheither', wilaya_id: '32' },
  { id: '1147', code_postal: '32012', nom: 'Kef El Ahmar', wilaya_id: '32' },
  { id: '1148', code_postal: '32013', nom: 'Boussemghoun', wilaya_id: '32' },
  { id: '1149', code_postal: '32014', nom: 'Chellala', wilaya_id: '32' },
  { id: '1150', code_postal: '32015', nom: 'Krakda', wilaya_id: '32' },
  { id: '1151', code_postal: '32016', nom: 'El Bnoud', wilaya_id: '32' },
  { id: '1152', code_postal: '32017', nom: 'Cheguig', wilaya_id: '32' },
  { id: '1153', code_postal: '32018', nom: 'Sidi Ameur', wilaya_id: '32' },
  { id: '1154', code_postal: '32019', nom: 'El Mehara', wilaya_id: '32' },
  { id: '1155', code_postal: '32020', nom: 'Tousmouline', wilaya_id: '32' },
  { id: '1156', code_postal: '32021', nom: 'Sidi Slimane', wilaya_id: '32' },
  { id: '1157', code_postal: '32022', nom: 'Sidi Tifour', wilaya_id: '32' },
  { id: '1158', code_postal: '33001', nom: 'Illizi', wilaya_id: '33' },
  { id: '1159', code_postal: '33002', nom: 'Djanet', wilaya_id: '33' },
  { id: '1160', code_postal: '33003', nom: 'Debdeb', wilaya_id: '33' },
  { id: '1161', code_postal: '33004', nom: 'Bordj Omar Driss', wilaya_id: '33' },
  { id: '1162', code_postal: '33005', nom: 'Bordj El Haouasse', wilaya_id: '33' },
  { id: '1163', code_postal: '33006', nom: 'In Amenas', wilaya_id: '33' },
  { id: '1164', code_postal: '34001', nom: 'Bordj Bou Arreridj', wilaya_id: '34' },
  { id: '1165', code_postal: '34002', nom: 'Ras El Oued', wilaya_id: '34' },
  { id: '1166', code_postal: '34003', nom: 'Bordj Zemoura', wilaya_id: '34' },
  { id: '1167', code_postal: '34004', nom: 'Mansoura', wilaya_id: '34' },
  { id: '1168', code_postal: '34005', nom: 'El Mhir', wilaya_id: '34' },
  { id: '1169', code_postal: '34006', nom: 'Ben Daoud', wilaya_id: '34' },
  { id: '1170', code_postal: '34007', nom: 'El Achir', wilaya_id: '34' },
  { id: '1171', code_postal: '34008', nom: 'Ain Taghrout', wilaya_id: '34' },
  { id: '1172', code_postal: '34009', nom: 'Bordj Ghdir', wilaya_id: '34' },
  { id: '1173', code_postal: '34010', nom: 'Sidi Embarek', wilaya_id: '34' },
  { id: '1174', code_postal: '34011', nom: 'El Hamadia', wilaya_id: '34' },
  { id: '1175', code_postal: '34012', nom: 'Belimour', wilaya_id: '34' },
  { id: '1176', code_postal: '34013', nom: 'Medjana', wilaya_id: '34' },
  { id: '1177', code_postal: '34014', nom: 'Teniet En Nasr', wilaya_id: '34' },
  { id: '1178', code_postal: '34015', nom: 'Djaafra', wilaya_id: '34' },
  { id: '1179', code_postal: '34016', nom: 'El Main', wilaya_id: '34' },
  { id: '1180', code_postal: '34017', nom: 'Ouled Brahem', wilaya_id: '34' },
  { id: '1181', code_postal: '34018', nom: 'Ouled Dahmane', wilaya_id: '34' },
  { id: '1182', code_postal: '34019', nom: 'Hasnaoua', wilaya_id: '34' },
  { id: '1183', code_postal: '34020', nom: 'Khelil', wilaya_id: '34' },
  { id: '1184', code_postal: '34021', nom: 'Taglait', wilaya_id: '34' },
  { id: '1185', code_postal: '34022', nom: 'Ksour', wilaya_id: '34' },
  { id: '1186', code_postal: '34023', nom: 'Ouled Sidi Brahim', wilaya_id: '34' },
  { id: '1187', code_postal: '34024', nom: 'Tafreg', wilaya_id: '34' },
  { id: '1188', code_postal: '34025', nom: 'Colla', wilaya_id: '34' },
  { id: '1189', code_postal: '34026', nom: 'Tixter', wilaya_id: '34' },
  { id: '1190', code_postal: '34027', nom: 'El Ach', wilaya_id: '34' },
  { id: '1191', code_postal: '34028', nom: 'El Anseur', wilaya_id: '34' },
  { id: '1192', code_postal: '34029', nom: 'Tesmart', wilaya_id: '34' },
  { id: '1193', code_postal: '34030', nom: 'Ain Tesra', wilaya_id: '34' },
  { id: '1194', code_postal: '34031', nom: 'Bir Kasdali', wilaya_id: '34' },
  { id: '1195', code_postal: '34032', nom: 'Ghilassa', wilaya_id: '34' },
  { id: '1196', code_postal: '34033', nom: 'Rabta', wilaya_id: '34' },
  { id: '1197', code_postal: '34034', nom: 'Haraza', wilaya_id: '34' },
  { id: '1198', code_postal: '35001', nom: 'Boumerdes', wilaya_id: '35' },
  { id: '1199', code_postal: '35002', nom: 'Boudouaou', wilaya_id: '35' },
  { id: '1200', code_postal: '35004', nom: 'Afir', wilaya_id: '35' },
  { id: '1201', code_postal: '35005', nom: 'Bordj Menaiel', wilaya_id: '35' },
  { id: '1202', code_postal: '35006', nom: 'Baghlia', wilaya_id: '35' },
  { id: '1203', code_postal: '35007', nom: 'Sidi Daoud', wilaya_id: '35' },
  { id: '1204', code_postal: '35008', nom: 'Naciria', wilaya_id: '35' },
  { id: '1205', code_postal: '35009', nom: 'Djinet', wilaya_id: '35' },
  { id: '1206', code_postal: '35010', nom: 'Isser', wilaya_id: '35' },
  { id: '1207', code_postal: '35011', nom: 'Zemmouri', wilaya_id: '35' },
  { id: '1208', code_postal: '35012', nom: 'Si Mustapha', wilaya_id: '35' },
  { id: '1209', code_postal: '35013', nom: 'Tidjelabine', wilaya_id: '35' },
  { id: '1210', code_postal: '35014', nom: 'Chabet El Ameur', wilaya_id: '35' },
  { id: '1211', code_postal: '35015', nom: 'Thenia', wilaya_id: '35' },
  { id: '1212', code_postal: '35018', nom: 'Timezrit', wilaya_id: '35' },
  { id: '1213', code_postal: '35019', nom: 'Corso', wilaya_id: '35' },
  { id: '1214', code_postal: '35020', nom: 'Ouled Moussa', wilaya_id: '35' },
  { id: '1215', code_postal: '35021', nom: 'Larbatache', wilaya_id: '35' },
  { id: '1216', code_postal: '35022', nom: 'Bouzegza Keddara', wilaya_id: '35' },
  { id: '1217', code_postal: '35025', nom: 'Taourga', wilaya_id: '35' },
  { id: '1218', code_postal: '35026', nom: 'Ouled Aissa', wilaya_id: '35' },
  { id: '1219', code_postal: '35027', nom: 'Ben Choud', wilaya_id: '35' },
  { id: '1220', code_postal: '35028', nom: 'Dellys', wilaya_id: '35' },
  { id: '1221', code_postal: '35029', nom: 'Ammal', wilaya_id: '35' },
  { id: '1222', code_postal: '35030', nom: 'Beni Amrane', wilaya_id: '35' },
  { id: '1223', code_postal: '35031', nom: 'Souk El Had', wilaya_id: '35' },
  { id: '1224', code_postal: '35032', nom: 'Boudouaou El Bahri', wilaya_id: '35' },
  { id: '1225', code_postal: '35033', nom: 'Ouled Hedadj', wilaya_id: '35' },
  { id: '1226', code_postal: '35035', nom: 'Laghata', wilaya_id: '35' },
  { id: '1227', code_postal: '35036', nom: 'Hammedi', wilaya_id: '35' },
  { id: '1228', code_postal: '35037', nom: 'Khemis El Khechna', wilaya_id: '35' },
  { id: '1229', code_postal: '35038', nom: 'El Kharrouba', wilaya_id: '35' },
  { id: '1230', code_postal: '36001', nom: 'El Tarf', wilaya_id: '36' },
  { id: '1231', code_postal: '36002', nom: 'Bouhadjar', wilaya_id: '36' },
  { id: '1232', code_postal: '36003', nom: 'Ben Mhidi', wilaya_id: '36' },
  { id: '1233', code_postal: '36004', nom: 'Bougous', wilaya_id: '36' },
  { id: '1234', code_postal: '36005', nom: 'El Kala', wilaya_id: '36' },
  { id: '1235', code_postal: '36006', nom: 'Ain El Assel', wilaya_id: '36' },
  { id: '1236', code_postal: '36007', nom: 'El Aioun', wilaya_id: '36' },
  { id: '1237', code_postal: '36008', nom: 'Bouteldja', wilaya_id: '36' },
  { id: '1238', code_postal: '36009', nom: 'Souarekh', wilaya_id: '36' },
  { id: '1239', code_postal: '36010', nom: 'Berrihane', wilaya_id: '36' },
  { id: '1240', code_postal: '36011', nom: 'Lac Des Oiseaux', wilaya_id: '36' },
  { id: '1241', code_postal: '36012', nom: 'Chefia', wilaya_id: '36' },
  { id: '1242', code_postal: '36013', nom: 'Drean', wilaya_id: '36' },
  { id: '1243', code_postal: '36014', nom: 'Chihani', wilaya_id: '36' },
  { id: '1244', code_postal: '36015', nom: 'Chebaita Mokhtar', wilaya_id: '36' },
  { id: '1245', code_postal: '36016', nom: 'Besbes', wilaya_id: '36' },
  { id: '1246', code_postal: '36017', nom: 'Asfour', wilaya_id: '36' },
  { id: '1247', code_postal: '36018', nom: 'Echatt', wilaya_id: '36' },
  { id: '1248', code_postal: '36019', nom: 'Zerizer', wilaya_id: '36' },
  { id: '1249', code_postal: '36020', nom: 'Zitouna', wilaya_id: '36' },
  { id: '1250', code_postal: '36021', nom: 'Ain Kerma', wilaya_id: '36' },
  { id: '1251', code_postal: '36022', nom: 'Oued Zitoun', wilaya_id: '36' },
  { id: '1252', code_postal: '36023', nom: 'Hammam Beni Salah', wilaya_id: '36' },
  { id: '1253', code_postal: '36024', nom: 'Raml Souk', wilaya_id: '36' },
  { id: '1254', code_postal: '37001', nom: 'Tindouf', wilaya_id: '37' },
  { id: '1255', code_postal: '37002', nom: 'Oum El Assel', wilaya_id: '37' },
  { id: '1256', code_postal: '38001', nom: 'Tissemsilt', wilaya_id: '38' },
  { id: '1257', code_postal: '38002', nom: 'Bordj Bou Naama', wilaya_id: '38' },
  { id: '1258', code_postal: '38003', nom: 'Theniet El Had', wilaya_id: '38' },
  { id: '1259', code_postal: '38004', nom: 'Lazharia', wilaya_id: '38' },
  { id: '1260', code_postal: '38005', nom: 'Beni Chaib', wilaya_id: '38' },
  { id: '1261', code_postal: '38006', nom: 'Lardjem', wilaya_id: '38' },
  { id: '1262', code_postal: '38007', nom: 'Melaab', wilaya_id: '38' },
  { id: '1263', code_postal: '38008', nom: 'Sidi Lantri', wilaya_id: '38' },
  { id: '1264', code_postal: '38009', nom: 'Bordj El Emir Abdelkader', wilaya_id: '38' },
  { id: '1265', code_postal: '38010', nom: 'Layoune', wilaya_id: '38' },
  { id: '1266', code_postal: '38011', nom: 'Khemisti', wilaya_id: '38' },
  { id: '1267', code_postal: '38012', nom: 'Ouled Bessem', wilaya_id: '38' },
  { id: '1268', code_postal: '38013', nom: 'Ammari', wilaya_id: '38' },
  { id: '1269', code_postal: '38014', nom: 'Youssoufia', wilaya_id: '38' },
  { id: '1270', code_postal: '38015', nom: 'Sidi Boutouchent', wilaya_id: '38' },
  { id: '1271', code_postal: '38016', nom: 'Larbaa', wilaya_id: '38' },
  { id: '1272', code_postal: '38017', nom: 'Maasem', wilaya_id: '38' },
  { id: '1273', code_postal: '38018', nom: 'Sidi Abed', wilaya_id: '38' },
  { id: '1274', code_postal: '38019', nom: 'Tamalaht', wilaya_id: '38' },
  { id: '1275', code_postal: '38020', nom: 'Sidi Slimane', wilaya_id: '38' },
  { id: '1276', code_postal: '38021', nom: 'Boucaid', wilaya_id: '38' },
  { id: '1277', code_postal: '38022', nom: 'Beni Lahcene', wilaya_id: '38' },
  { id: '1278', code_postal: '39001', nom: 'El Oued', wilaya_id: '39' },
  { id: '1279', code_postal: '39002', nom: 'Robbah', wilaya_id: '39' },
  { id: '1280', code_postal: '39003', nom: 'Oued El Alenda', wilaya_id: '39' },
  { id: '1281', code_postal: '39004', nom: 'Bayadha', wilaya_id: '39' },
  { id: '1282', code_postal: '39005', nom: 'Nakhla', wilaya_id: '39' },
  { id: '1283', code_postal: '39006', nom: 'Guemar', wilaya_id: '39' },
  { id: '1284', code_postal: '39007', nom: 'Kouinine', wilaya_id: '39' },
  { id: '1285', code_postal: '39008', nom: 'Reguiba', wilaya_id: '39' },
  { id: '1286', code_postal: '39009', nom: 'Hamraia', wilaya_id: '39' },
  { id: '1287', code_postal: '39010', nom: 'Taghzout', wilaya_id: '39' },
  { id: '1288', code_postal: '39011', nom: 'Debila', wilaya_id: '39' },
  { id: '1289', code_postal: '39012', nom: 'Hassani Abdelkrim', wilaya_id: '39' },
  { id: '1290', code_postal: '39013', nom: 'Hassi Khelifa', wilaya_id: '39' },
  { id: '1291', code_postal: '39014', nom: 'Taleb Larbi', wilaya_id: '39' },
  { id: '1292', code_postal: '39015', nom: 'Douar El Ma', wilaya_id: '39' },
  { id: '1293', code_postal: '39016', nom: 'Sidi Aoun', wilaya_id: '39' },
  { id: '1294', code_postal: '39017', nom: 'Trifaoui', wilaya_id: '39' },
  { id: '1295', code_postal: '39018', nom: 'Magrane', wilaya_id: '39' },
  { id: '1296', code_postal: '39019', nom: 'Beni Guecha', wilaya_id: '39' },
  { id: '1297', code_postal: '39020', nom: 'Ourmas', wilaya_id: '39' },
  { id: '1298', code_postal: '39021', nom: 'Still', wilaya_id: '39' },
  { id: '1299', code_postal: '39022', nom: 'Mrara', wilaya_id: '39' },
  { id: '1300', code_postal: '39023', nom: 'Sidi Khellil', wilaya_id: '39' },
  { id: '1301', code_postal: '39024', nom: 'Tendla', wilaya_id: '39' },
  { id: '1302', code_postal: '39025', nom: 'El Ogla', wilaya_id: '39' },
  { id: '1303', code_postal: '39026', nom: 'Mih Ouansa', wilaya_id: '39' },
  { id: '1304', code_postal: '39027', nom: 'El Mghair', wilaya_id: '39' },
  { id: '1305', code_postal: '39028', nom: 'Djamaa', wilaya_id: '39' },
  { id: '1306', code_postal: '39029', nom: 'Oum Touyour', wilaya_id: '39' },
  { id: '1307', code_postal: '39030', nom: 'Sidi Amrane', wilaya_id: '39' },
  { id: '1308', code_postal: '40001', nom: 'Khenchela', wilaya_id: '40' },
  { id: '1309', code_postal: '40002', nom: 'Mtoussa', wilaya_id: '40' },
  { id: '1310', code_postal: '40003', nom: 'Kais', wilaya_id: '40' },
  { id: '1311', code_postal: '40004', nom: 'Baghai', wilaya_id: '40' },
  { id: '1312', code_postal: '40005', nom: 'El Hamma', wilaya_id: '40' },
  { id: '1313', code_postal: '40006', nom: 'Ain Touila', wilaya_id: '40' },
  { id: '1314', code_postal: '40007', nom: 'Taouzianat', wilaya_id: '40' },
  { id: '1315', code_postal: '40008', nom: 'Bouhmama', wilaya_id: '40' },
  { id: '1316', code_postal: '40009', nom: 'El Oueldja', wilaya_id: '40' },
  { id: '1317', code_postal: '40010', nom: 'Remila', wilaya_id: '40' },
  { id: '1318', code_postal: '40011', nom: 'Cherchar', wilaya_id: '40' },
  { id: '1319', code_postal: '40012', nom: 'Djellal', wilaya_id: '40' },
  { id: '1320', code_postal: '40013', nom: 'Babar', wilaya_id: '40' },
  { id: '1321', code_postal: '40014', nom: 'Tamza', wilaya_id: '40' },
  { id: '1322', code_postal: '40015', nom: 'Ensigha', wilaya_id: '40' },
  { id: '1323', code_postal: '40016', nom: 'Ouled Rechache', wilaya_id: '40' },
  { id: '1324', code_postal: '40017', nom: 'El Mahmal', wilaya_id: '40' },
  { id: '1325', code_postal: '40018', nom: 'Msara', wilaya_id: '40' },
  { id: '1326', code_postal: '40019', nom: 'Yabous', wilaya_id: '40' },
  { id: '1327', code_postal: '40020', nom: 'Khirane', wilaya_id: '40' },
  { id: '1328', code_postal: '40021', nom: 'Chelia', wilaya_id: '40' },
  { id: '1329', code_postal: '41001', nom: 'Souk Ahras', wilaya_id: '41' },
  { id: '1330', code_postal: '41002', nom: 'Sedrata', wilaya_id: '41' },
  { id: '1331', code_postal: '41003', nom: 'Hanancha', wilaya_id: '41' },
  { id: '1332', code_postal: '41004', nom: 'Mechroha', wilaya_id: '41' },
  { id: '1333', code_postal: '41005', nom: 'Ouled Driss', wilaya_id: '41' },
  { id: '1334', code_postal: '41006', nom: 'Tiffech', wilaya_id: '41' },
  { id: '1335', code_postal: '41007', nom: 'Zaarouria', wilaya_id: '41' },
  { id: '1336', code_postal: '41008', nom: 'Taoura', wilaya_id: '41' },
  { id: '1337', code_postal: '41009', nom: 'Drea', wilaya_id: '41' },
  { id: '1338', code_postal: '41010', nom: 'Haddada', wilaya_id: '41' },
  { id: '1339', code_postal: '41011', nom: 'Khedara', wilaya_id: '41' },
  { id: '1340', code_postal: '41012', nom: 'Merahna', wilaya_id: '41' },
  { id: '1341', code_postal: '41013', nom: 'Ouled Moumen', wilaya_id: '41' },
  { id: '1342', code_postal: '41014', nom: 'Bir Bouhouche', wilaya_id: '41' },
  { id: '1343', code_postal: '41015', nom: 'Mdaourouche', wilaya_id: '41' },
  { id: '1344', code_postal: '41016', nom: 'Oum El Adhaim', wilaya_id: '41' },
  { id: '1345', code_postal: '41017', nom: 'Ain Zana', wilaya_id: '41' },
  { id: '1346', code_postal: '41018', nom: 'Ain Soltane', wilaya_id: '41' },
  { id: '1347', code_postal: '41019', nom: 'Quillen', wilaya_id: '41' },
  { id: '1348', code_postal: '41020', nom: 'Sidi Fredj', wilaya_id: '41' },
  { id: '1349', code_postal: '41021', nom: 'Safel El Ouiden', wilaya_id: '41' },
  { id: '1350', code_postal: '41022', nom: 'Ragouba', wilaya_id: '41' },
  { id: '1351', code_postal: '41023', nom: 'Khemissa', wilaya_id: '41' },
  { id: '1352', code_postal: '41024', nom: 'Oued Keberit', wilaya_id: '41' },
  { id: '1353', code_postal: '41025', nom: 'Terraguelt', wilaya_id: '41' },
  { id: '1354', code_postal: '41026', nom: 'Zouabi', wilaya_id: '41' },
  { id: '1355', code_postal: '42001', nom: 'Tipaza', wilaya_id: '42' },
  { id: '1356', code_postal: '42002', nom: 'Menaceur', wilaya_id: '42' },
  { id: '1357', code_postal: '42003', nom: 'Larhat', wilaya_id: '42' },
  { id: '1358', code_postal: '42004', nom: 'Douaouda', wilaya_id: '42' },
  { id: '1359', code_postal: '42005', nom: 'Bourkika', wilaya_id: '42' },
  { id: '1360', code_postal: '42006', nom: 'Khemisti', wilaya_id: '42' },
  { id: '1361', code_postal: '42010', nom: 'Aghabal', wilaya_id: '42' },
  { id: '1362', code_postal: '42012', nom: 'Hadjout', wilaya_id: '42' },
  { id: '1363', code_postal: '42013', nom: 'Sidi Amar', wilaya_id: '42' },
  { id: '1364', code_postal: '42014', nom: 'Gouraya', wilaya_id: '42' },
  { id: '1365', code_postal: '42015', nom: 'Nodor', wilaya_id: '42' },
  { id: '1366', code_postal: '42016', nom: 'Chaiba', wilaya_id: '42' },
  { id: '1367', code_postal: '42017', nom: 'Ain Tagourait', wilaya_id: '42' },
  { id: '1368', code_postal: '42022', nom: 'Cherchel', wilaya_id: '42' },
  { id: '1369', code_postal: '42023', nom: 'Damous', wilaya_id: '42' },
  { id: '1370', code_postal: '42024', nom: 'Meurad', wilaya_id: '42' },
  { id: '1371', code_postal: '42025', nom: 'Fouka', wilaya_id: '42' },
  { id: '1372', code_postal: '42026', nom: 'Bou Ismail', wilaya_id: '42' },
  { id: '1373', code_postal: '42027', nom: 'Ahmer El Ain', wilaya_id: '42' },
  { id: '1374', code_postal: '42030', nom: 'Bou Haroun', wilaya_id: '42' },
  { id: '1375', code_postal: '42032', nom: 'Sidi Ghiles', wilaya_id: '42' },
  { id: '1376', code_postal: '42033', nom: 'Messelmoun', wilaya_id: '42' },
  { id: '1377', code_postal: '42034', nom: 'Sidi Rached', wilaya_id: '42' },
  { id: '1378', code_postal: '42035', nom: 'Kolea', wilaya_id: '42' },
  { id: '1379', code_postal: '42036', nom: 'Attatba', wilaya_id: '42' },
  { id: '1380', code_postal: '42040', nom: 'Sidi Semiane', wilaya_id: '42' },
  { id: '1381', code_postal: '42041', nom: 'Beni Milleuk', wilaya_id: '42' },
  { id: '1382', code_postal: '42042', nom: 'Hadjerat Ennous', wilaya_id: '42' },
  { id: '1383', code_postal: '43001', nom: 'Mila', wilaya_id: '43' },
  { id: '1384', code_postal: '43002', nom: 'Ferdjioua', wilaya_id: '43' },
  { id: '1385', code_postal: '43003', nom: 'Chelghoum Laid', wilaya_id: '43' },
  { id: '1386', code_postal: '43004', nom: 'Oued Athmenia', wilaya_id: '43' },
  { id: '1387', code_postal: '43005', nom: 'Ain Mellouk', wilaya_id: '43' },
  { id: '1388', code_postal: '43006', nom: 'Telerghma', wilaya_id: '43' },
  { id: '1389', code_postal: '43007', nom: 'Oued Seguen', wilaya_id: '43' },
  { id: '1390', code_postal: '43008', nom: 'Tadjenanet', wilaya_id: '43' },
  { id: '1391', code_postal: '43009', nom: 'Benyahia Abderrahmane', wilaya_id: '43' },
  { id: '1392', code_postal: '43010', nom: 'Oued Endja', wilaya_id: '43' },
  { id: '1393', code_postal: '43011', nom: 'Ahmed Rachedi', wilaya_id: '43' },
  { id: '1394', code_postal: '43012', nom: 'Ouled Khalouf', wilaya_id: '43' },
  { id: '1395', code_postal: '43013', nom: 'Tiberguent', wilaya_id: '43' },
  { id: '1396', code_postal: '43014', nom: 'Bouhatem', wilaya_id: '43' },
  { id: '1397', code_postal: '43015', nom: 'Rouached', wilaya_id: '43' },
  { id: '1398', code_postal: '43016', nom: 'Tessala Lamatai', wilaya_id: '43' },
  { id: '1399', code_postal: '43017', nom: 'Grarem Gouga', wilaya_id: '43' },
  { id: '1400', code_postal: '43018', nom: 'Sidi Merouane', wilaya_id: '43' },
  { id: '1401', code_postal: '43019', nom: 'Tassadane Haddada', wilaya_id: '43' },
  { id: '1402', code_postal: '43020', nom: 'Derradji Bousselah', wilaya_id: '43' },
  { id: '1403', code_postal: '43021', nom: 'Minar Zarza', wilaya_id: '43' },
  { id: '1404', code_postal: '43022', nom: 'Amira Arras', wilaya_id: '43' },
  { id: '1405', code_postal: '43023', nom: 'Terrai Bainen', wilaya_id: '43' },
  { id: '1406', code_postal: '43024', nom: 'Hamala', wilaya_id: '43' },
  { id: '1407', code_postal: '43025', nom: 'Ain Tine', wilaya_id: '43' },
  { id: '1408', code_postal: '43026', nom: 'El Mechira', wilaya_id: '43' },
  { id: '1409', code_postal: '43027', nom: 'Sidi Khelifa', wilaya_id: '43' },
  { id: '1410', code_postal: '43028', nom: 'Zeghaia', wilaya_id: '43' },
  { id: '1411', code_postal: '43029', nom: 'Elayadi Barbes', wilaya_id: '43' },
  { id: '1412', code_postal: '43030', nom: 'Ain Beida Harriche', wilaya_id: '43' },
  { id: '1413', code_postal: '43031', nom: 'Yahia Beniguecha', wilaya_id: '43' },
  { id: '1414', code_postal: '43032', nom: 'Chigara', wilaya_id: '43' },
  { id: '1415', code_postal: '44001', nom: 'Ain Defla', wilaya_id: '44' },
  { id: '1416', code_postal: '44002', nom: 'Miliana', wilaya_id: '44' },
  { id: '1417', code_postal: '44003', nom: 'Boumedfaa', wilaya_id: '44' },
  { id: '1418', code_postal: '44004', nom: 'Khemis Miliana', wilaya_id: '44' },
  { id: '1419', code_postal: '44005', nom: 'Hammam Righa', wilaya_id: '44' },
  { id: '1420', code_postal: '44006', nom: 'Arib', wilaya_id: '44' },
  { id: '1421', code_postal: '44007', nom: 'Djelida', wilaya_id: '44' },
  { id: '1422', code_postal: '44008', nom: 'El Amra', wilaya_id: '44' },
  { id: '1423', code_postal: '44009', nom: 'Bourached', wilaya_id: '44' },
  { id: '1424', code_postal: '44010', nom: 'El Attaf', wilaya_id: '44' },
  { id: '1425', code_postal: '44011', nom: 'El Abadia', wilaya_id: '44' },
  { id: '1426', code_postal: '44012', nom: 'Djendel', wilaya_id: '44' },
  { id: '1427', code_postal: '44013', nom: 'Oued Chorfa', wilaya_id: '44' },
  { id: '1428', code_postal: '44014', nom: 'Ain Lechiakh', wilaya_id: '44' },
  { id: '1429', code_postal: '44015', nom: 'Oued Djemaa', wilaya_id: '44' },
  { id: '1430', code_postal: '44016', nom: 'Rouina', wilaya_id: '44' },
  { id: '1431', code_postal: '44017', nom: 'Zeddine', wilaya_id: '44' },
  { id: '1432', code_postal: '44018', nom: 'El Hassania', wilaya_id: '44' },
  { id: '1433', code_postal: '44019', nom: 'Bir Ouled Khelifa', wilaya_id: '44' },
  { id: '1434', code_postal: '44020', nom: 'Ain Soltane', wilaya_id: '44' },
  { id: '1435', code_postal: '44021', nom: 'Tarik Ibn Ziad', wilaya_id: '44' },
  { id: '1436', code_postal: '44022', nom: 'Bordj Emir Khaled', wilaya_id: '44' },
  { id: '1437', code_postal: '44023', nom: 'Ain Torki', wilaya_id: '44' },
  { id: '1438', code_postal: '44024', nom: 'Sidi Lakhdar', wilaya_id: '44' },
  { id: '1439', code_postal: '44025', nom: 'Ben Allal', wilaya_id: '44' },
  { id: '1440', code_postal: '44026', nom: 'Ain Benian', wilaya_id: '44' },
  { id: '1441', code_postal: '44027', nom: 'Hoceinia', wilaya_id: '44' },
  { id: '1442', code_postal: '44028', nom: 'Barbouche', wilaya_id: '44' },
  { id: '1443', code_postal: '44029', nom: 'Djemaa Ouled Chikh', wilaya_id: '44' },
  { id: '1444', code_postal: '44030', nom: 'Mekhatria', wilaya_id: '44' },
  { id: '1445', code_postal: '44031', nom: 'Bathia', wilaya_id: '44' },
  { id: '1446', code_postal: '44032', nom: 'Tachta Zegagha', wilaya_id: '44' },
  { id: '1447', code_postal: '44033', nom: 'Ain Bouyahia', wilaya_id: '44' },
  { id: '1448', code_postal: '44034', nom: 'El Maine', wilaya_id: '44' },
  { id: '1449', code_postal: '44035', nom: 'Tiberkanine', wilaya_id: '44' },
  { id: '1450', code_postal: '44036', nom: 'Belaas', wilaya_id: '44' },
  { id: '1451', code_postal: '45001', nom: 'Naama', wilaya_id: '45' },
  { id: '1452', code_postal: '45002', nom: 'Mechria', wilaya_id: '45' },
  { id: '1453', code_postal: '45003', nom: 'Ain Sefra', wilaya_id: '45' },
  { id: '1454', code_postal: '45004', nom: 'Tiout', wilaya_id: '45' },
  { id: '1455', code_postal: '45005', nom: 'Sfissifa', wilaya_id: '45' },
  { id: '1456', code_postal: '45006', nom: 'Moghrar', wilaya_id: '45' },
  { id: '1457', code_postal: '45007', nom: 'Assela', wilaya_id: '45' },
  { id: '1458', code_postal: '45008', nom: 'Djeniane Bourzeg', wilaya_id: '45' },
  { id: '1459', code_postal: '45009', nom: 'Ain Ben Khelil', wilaya_id: '45' },
  { id: '1460', code_postal: '45010', nom: 'Makman Ben Amer', wilaya_id: '45' },
  { id: '1461', code_postal: '45011', nom: 'Kasdir', wilaya_id: '45' },
  { id: '1462', code_postal: '45012', nom: 'El Biod', wilaya_id: '45' },
  { id: '1463', code_postal: '46001', nom: 'Ain Temouchent', wilaya_id: '46' },
  { id: '1464', code_postal: '46002', nom: 'Chaabet El Ham', wilaya_id: '46' },
  { id: '1465', code_postal: '46003', nom: 'Ain Kihal', wilaya_id: '46' },
  { id: '1466', code_postal: '46004', nom: 'Hammam Bouhadjar', wilaya_id: '46' },
  { id: '1467', code_postal: '46005', nom: 'Bou Zedjar', wilaya_id: '46' },
  { id: '1468', code_postal: '46006', nom: 'Oued Berkeche', wilaya_id: '46' },
  { id: '1469', code_postal: '46007', nom: 'Aghlal', wilaya_id: '46' },
  { id: '1470', code_postal: '46008', nom: 'Terga', wilaya_id: '46' },
  { id: '1471', code_postal: '46009', nom: 'Ain El Arbaa', wilaya_id: '46' },
  { id: '1472', code_postal: '46010', nom: 'Tamzoura', wilaya_id: '46' },
  { id: '1473', code_postal: '46011', nom: 'Chentouf', wilaya_id: '46' },
  { id: '1474', code_postal: '46012', nom: 'Sidi Ben Adda', wilaya_id: '46' },
  { id: '1475', code_postal: '46013', nom: 'Aoubellil', wilaya_id: '46' },
  { id: '1476', code_postal: '46014', nom: 'El Malah', wilaya_id: '46' },
  { id: '1477', code_postal: '46015', nom: 'Sidi Boumediene', wilaya_id: '46' },
  { id: '1478', code_postal: '46016', nom: 'Oued Sabah', wilaya_id: '46' },
  { id: '1479', code_postal: '46017', nom: 'Ouled Boudjemaa', wilaya_id: '46' },
  { id: '1480', code_postal: '46018', nom: 'Ain Tolba', wilaya_id: '46' },
  { id: '1481', code_postal: '46019', nom: 'El Amria', wilaya_id: '46' },
  { id: '1482', code_postal: '46020', nom: 'Hassi El Ghella', wilaya_id: '46' },
  { id: '1483', code_postal: '46021', nom: 'Hassasna', wilaya_id: '46' },
  { id: '1484', code_postal: '46022', nom: 'Ouled Kihal', wilaya_id: '46' },
  { id: '1485', code_postal: '46023', nom: 'Beni Saf', wilaya_id: '46' },
  { id: '1486', code_postal: '46024', nom: 'Sidi Safi', wilaya_id: '46' },
  { id: '1487', code_postal: '46025', nom: 'Oulhaca El Gheraba', wilaya_id: '46' },
  { id: '1488', code_postal: '46026', nom: 'Tadmaya', wilaya_id: '46' },
  { id: '1489', code_postal: '46027', nom: 'El Emir Abdelkader', wilaya_id: '46' },
  { id: '1490', code_postal: '46028', nom: 'El Messaid', wilaya_id: '46' },
  { id: '1491', code_postal: '47001', nom: 'Ghardaia', wilaya_id: '47' },
  { id: '1492', code_postal: '47002', nom: 'El Meniaa', wilaya_id: '47' },
  { id: '1493', code_postal: '47003', nom: 'Dhayet Bendhahoua', wilaya_id: '47' },
  { id: '1494', code_postal: '47004', nom: 'Berriane', wilaya_id: '47' },
  { id: '1495', code_postal: '47005', nom: 'Metlili', wilaya_id: '47' },
  { id: '1496', code_postal: '47006', nom: 'El Guerrara', wilaya_id: '47' },
  { id: '1497', code_postal: '47007', nom: 'El Atteuf', wilaya_id: '47' },
  { id: '1498', code_postal: '47008', nom: 'Zelfana', wilaya_id: '47' },
  { id: '1499', code_postal: '47009', nom: 'Sebseb', wilaya_id: '47' },
  { id: '1500', code_postal: '47010', nom: 'Bounoura', wilaya_id: '47' },
  { id: '1501', code_postal: '47011', nom: 'Hassi Fehal', wilaya_id: '47' },
  { id: '1502', code_postal: '47012', nom: 'Hassi Gara', wilaya_id: '47' },
  { id: '1503', code_postal: '47013', nom: 'Mansoura', wilaya_id: '47' },
  { id: '1504', code_postal: '48001', nom: 'Relizane', wilaya_id: '48' },
  { id: '1505', code_postal: '48002', nom: 'Oued Rhiou', wilaya_id: '48' },
  { id: '1506', code_postal: '48003', nom: 'Belaassel Bouzegza', wilaya_id: '48' },
  { id: '1507', code_postal: '48004', nom: 'Sidi Saada', wilaya_id: '48' },
  { id: '1508', code_postal: '48005', nom: 'Ouled Aiche', wilaya_id: '48' },
  { id: '1509', code_postal: '48006', nom: 'Sidi Lazreg', wilaya_id: '48' },
  { id: '1510', code_postal: '48007', nom: 'El Hamadna', wilaya_id: '48' },
  { id: '1511', code_postal: '48008', nom: 'Sidi Mhamed Ben Ali', wilaya_id: '48' },
  { id: '1512', code_postal: '48009', nom: 'Mediouna', wilaya_id: '48' },
  { id: '1513', code_postal: '48010', nom: 'Sidi Khettab', wilaya_id: '48' },
  { id: '1514', code_postal: '48011', nom: 'Ammi Moussa', wilaya_id: '48' },
  { id: '1515', code_postal: '48012', nom: 'Zemmoura', wilaya_id: '48' },
  { id: '1516', code_postal: '48013', nom: 'Beni Dergoun', wilaya_id: '48' },
  { id: '1517', code_postal: '48014', nom: 'Djidiouia', wilaya_id: '48' },
  { id: '1518', code_postal: '48015', nom: 'El Guettar', wilaya_id: '48' },
  { id: '1519', code_postal: '48016', nom: 'Hamri', wilaya_id: '48' },
  { id: '1520', code_postal: '48017', nom: 'El Matmar', wilaya_id: '48' },
  { id: '1521', code_postal: '48018', nom: 'Sidi Mhamed Ben Aouda', wilaya_id: '48' },
  { id: '1522', code_postal: '48019', nom: 'Ain Tarek', wilaya_id: '48' },
  { id: '1523', code_postal: '48020', nom: 'Oued Essalem', wilaya_id: '48' },
  { id: '1524', code_postal: '48021', nom: 'Ouarizane', wilaya_id: '48' },
  { id: '1525', code_postal: '48022', nom: 'Mazouna', wilaya_id: '48' },
  { id: '1526', code_postal: '48023', nom: 'Kalaa', wilaya_id: '48' },
  { id: '1527', code_postal: '48024', nom: 'Ain Rahma', wilaya_id: '48' },
  { id: '1528', code_postal: '48025', nom: 'Yellel', wilaya_id: '48' },
  { id: '1529', code_postal: '48026', nom: 'Oued El Djemaa', wilaya_id: '48' },
  { id: '1530', code_postal: '48027', nom: 'Ramka', wilaya_id: '48' },
  { id: '1531', code_postal: '48028', nom: 'Mendes', wilaya_id: '48' },
  { id: '1532', code_postal: '48029', nom: 'Lahlef', wilaya_id: '48' },
  { id: '1533', code_postal: '48030', nom: 'Beni Zentis', wilaya_id: '48' },
  { id: '1534', code_postal: '48031', nom: 'Souk El Haad', wilaya_id: '48' },
  { id: '1535', code_postal: '48032', nom: 'Dar Ben Abdellah', wilaya_id: '48' },
  { id: '1536', code_postal: '48033', nom: 'El Hassi', wilaya_id: '48' },
  { id: '1537', code_postal: '48034', nom: 'Had Echkalla', wilaya_id: '48' },
  { id: '1538', code_postal: '48035', nom: 'Bendaoud', wilaya_id: '48' },
  { id: '1539', code_postal: '48036', nom: 'El Ouldja', wilaya_id: '48' },
  { id: '1540', code_postal: '48037', nom: 'Merdja Sidi Abed', wilaya_id: '48' },
  { id: '1541', code_postal: '48038', nom: 'Ouled Sidi Mihoub', wilaya_id: '48' },
];

export default communes;
