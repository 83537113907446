import PropTypes from 'prop-types';
import React, { useContext } from 'react';
// @mui
import { Box, Card, Link, Typography, Stack, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { ProductQuantity } from '../../../components/productQuantity';
import { CartContext } from '../../../context/CartContext';
import Label from '../../../components/label';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const navigate = useNavigate();
  const { refreshCartLenght } = useContext(CartContext);

 

  const { title, price, quantity, images, reference, id } = product;

  const addToCart = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const existingProductIndex = cart.findIndex((item) => item.id === id);
    if(quantity>=1){
      if (existingProductIndex !== -1) {
      const newQuantity = cart[existingProductIndex].quantity + 1;
      if (newQuantity <= quantity) {
        cart[existingProductIndex].quantity = newQuantity;
      } else {
        cart[existingProductIndex].quantity = quantity;
      }
      } else {
        cart.push({ id, quantity: 1 });
      }
      localStorage.setItem('cart', JSON.stringify(cart));
    }

    refreshCartLenght()
  };

  return (
    <Card sx={{ borderRadius: 4 }}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <Label
          sx={{
            zIndex: 9,
            top: 16,
            right: 16,
            position: 'absolute',
            backgroundColor: "rgba(202, 202, 202, 0.77)",
          }}
        ><ProductQuantity quantity={quantity} /></Label>
        <StyledProductImg
          alt={title}
          src={
            images.length > 0
              ? `${process.env.REACT_APP_ADDRESS}/thumbnail/${images[0].path}`
              : `/assets/illustrations/indisponible.png`
          }
        />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link
          sx={{ '&:hover': { cursor: "pointer" } }}
          onClick={() => { navigate(`/product/${reference}`); }}
          color="inherit"
          underline="hover"
        >
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button disabled={quantity<1} onClick={addToCart} color='secondary' variant="contained" size="small">
            Ajouter au panier
          </Button>

          <Typography variant="subtitle1">
            {price} DA
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
