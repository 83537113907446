import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [orderList, setOrderList] = useState([]);
  const [open, setOpen] = useState(null);
  const [openNotification, setOpenNotification] = useState(null);

  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
    setOpenNotification(true);
  };

  const handleClose = () => {
    setOpen(null);
    setOpenNotification(false);
  };

  const handleMarkAllAsRead = () => {
    const nouvelleOrderIds = newNotifications.map((order) => order.id);

    axios
      .put(`${process.env.REACT_APP_ADDRESS}/order/updateState`, { orderIds: nouvelleOrderIds },{headers: { accessToken: localStorage.getItem("accessToken") }})
      .then((response) => {
        if (response.data.message === "État des commandes mis à jour avec succès") {
          setOrderList((prevOrderList) =>
            prevOrderList.map((order) =>
              nouvelleOrderIds.includes(order.id) ? { ...order, state: "en attente", isUnRead: false } : order
            )
          );
        } else {
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour des états des commandes :", error);
      });
  };

  useEffect(() => {
    const fetchNotifications = () => {
      axios
        .get(`${process.env.REACT_APP_ADDRESS}/order`, { headers: { accessToken: localStorage.getItem("accessToken") } })
        .then((response) => {
          if (response.data.error) {
            console.error(response.data.error);
          } else {
            const orders = response.data.map((order) => ({
              id: order.id,
              name: `${order.firstName} ${order.lastName}`,
              wilaya: order.wilaya,
              commune: order.commune,
              createdAt: new Date(order.createdAt),
              state: order.state,
              isUnRead: order.state === 'nouvelle',
            }));
            setOrderList(orders);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    // Récupérer les notifications immédiatement au montage
    fetchNotifications();

    if (openNotification === true) {
      fetchNotifications();
    }

    const interval = setInterval(() => {
      fetchNotifications();
    }, 10000);

    return () => clearInterval(interval);
  }, [openNotification]);

  const newNotifications = orderList
    .filter(order => order.state === 'nouvelle')
    .sort((a, b) => b.createdAt - a.createdAt);

  const beforeNotifications = orderList
    .filter(order => order.state === 'en attente')
    .sort((a, b) => b.createdAt - a.createdAt)
    .slice(0, 3);

  const totalUnRead = newNotifications.length;

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Vous avez {totalUnRead} {totalUnRead<=1 ? "nouvelle commande" : "nouvelles commandes"} 
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Tout marquer comme lu">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Nouveau
              </ListSubheader>
            }
          >
            {newNotifications.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Avant cela
              </ListSubheader>
            }
          >
            {beforeNotifications.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button onClick={() => {navigate("/dashboard/commande");handleClose()}} fullWidth disableRipple>
            Voir tout
          </Button>
        </Box>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.number,
    isUnRead: PropTypes.bool,
    name: PropTypes.string,
    wilaya: PropTypes.string,
    commune: PropTypes.string,
    state: PropTypes.string,
  }),
};

function NotificationItem({ notification }) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>
          <img alt={notification.name} src="/assets/icons/ic_notification_package.svg" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.name}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.wilaya}, {notification.commune}
      </Typography>
    </Typography>
  );

  return {
    avatar: <img alt={notification.name} src="/assets/icons/ic_notification_package.svg" />,
    title,
  };
}
