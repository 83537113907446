import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import { Container, Typography, Grid, Breadcrumbs, Link, Paper, Pagination } from '@mui/material';
// components
import { ProductList } from '../sections/@dashboard/products';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const { type, category, page } = useParams(); // Use useParams to get category, subcategory and page
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const productsPerPage = 8; // Adjust the number of products per page as needed

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ADDRESS}/subcategory/product/${type}/${category}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          setProductList(response.data); 
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle the error, e.g., redirect to an error page or show a relevant message to the Product.
      });
  }, [type, category]);

  // Initialize currentPage based on the page parameter from the URL
  const currentPage = page ? parseInt(page, 10) : 1;

  // Calculate the products to be displayed on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = productList.slice(indexOfFirstProduct, indexOfLastProduct);

  // Handle page change
  const handlePageChange = (event, value) => {
    navigate(`/products/${type}/${category}/${value}`);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Products | Minimal UI </title>
      </Helmet>

      <Container sx={{ mt: 2, pl: 0, pr: 0 }}>
        <Paper sx={{ p: 2 }} elevation={3}>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', pt: 2 }} lg={12} xs={12}>
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit">
                  Produits
                </Link>
                <Link underline="hover" color="inherit">
                  {type}
                </Link>
                <Typography color="text.primary">{category}</Typography>
              </Breadcrumbs>
            </div>
          </Grid>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Products
          </Typography>

          {/* ProductList component with current products */}
          <ProductList products={currentProducts} />

          {/* Pagination component */}
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Pagination
              count={Math.ceil(productList.length / productsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="secondary"
            />
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
