import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Grid from "@mui/material/Grid";
import FacebookIcon from '@mui/icons-material/Facebook';
import { CardActionArea } from '@mui/material';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ArrowBackIos,ArrowForwardIos, ArrowCircleLeft,ArrowCircleRight, ArrowBackIosNew } from '@mui/icons-material';

const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Beach',
    imgPath:
      'https://www.robin-noorda.com/uploads/1/6/8/3/16830688/3347022_orig.jpg',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  },
  {
    label: 'Goč, Serbia',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
];
function Home() {
  


  return (
    <Container maxWidth="xl" sx={{pl:0,pr:0}}>
      <Paper sx={{borderTopLeftRadius:0, borderTopRightRadius:0,ml:0}} elevation={3}>
      <Carousel  prevIcon={<Box sx={{color: "white",backgroundColor: "#00000080",padding: "8px",borderRadius: 1}}><ArrowBackIosNew /></Box>}
       nextIcon={<Box sx={{color: "white",backgroundColor: "#00000080",padding: "8px",borderRadius: 1}}><ArrowForwardIos /></Box>}>
      <Carousel.Item>
      <svg
        className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
        width="800"
        height="400"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label={`Placeholder: text`}
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
      >
        <title>title</title>
        <rect width="100%" height="100%" fill="#555" />
        <text x="50%" y="50%" fill="#333" dy=".3em">
          text
        </text>
      </svg> 
             <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <svg
        className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
        width="800"
        height="400"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label={`Placeholder: text`}
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
      >
        <title>title</title>
        <rect width="100%" height="100%" fill="#555" />
        <text x="50%" y="50%" fill="#333" dy=".3em">
          text
        </text>
      </svg> 
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <svg
        className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
        width="800"
        height="400"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label={`Placeholder: text`}
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
      >
        <title>title</title>
        <rect width="100%" height="100%" fill="#555" />
        <text x="50%" y="50%" fill="#333" dy=".3em">
          text
        </text>
      </svg> 
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </Paper>
    </Container>
  );
}



export default Home;