import PropTypes from 'prop-types';
import { useState, forwardRef, useEffect } from 'react';
import {
  Dialog,
  ListItemText,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Container,
  ListItem,
  ListItemIcon,
  Stack,
  Grid,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

import Label from '../../../components/label';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

ConsultOrder.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idOrder: PropTypes.number,
  snackbarSeverity: PropTypes.func,
  handleOpenSnackbar: PropTypes.func,
  snackbarContent: PropTypes.func,
  setNewList: PropTypes.func,
};

export default function ConsultOrder({ open, handleClose, idOrder, snackbarSeverity, snackbarContent, handleOpenSnackbar, setNewList }) {
  const [orderProducts, setOrderProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [order, setOrder] = useState(0);

  useEffect(() => {
    if (open) {
      axios
        .get(`${process.env.REACT_APP_ADDRESS}/order/${idOrder}`,{ headers: { accessToken: localStorage.getItem("accessToken") } })
        .then((response) => {
          if (response.data.error) {
            console.error(response.data.error);
          } else {
            const fetchedProducts = response.data.result;
            setOrderProducts(fetchedProducts); // Set the fetched data to state
            setOrder(response.data.Order);
            // Calculate the total price
            const total = fetchedProducts.reduce(
              (acc, product) => acc + product.quantity * product.product.price,
              0
            );
  
            setTotalPrice(total);
  
            if (response.data.Order.state === 'nouvelle') {
              // Update the state to 'en attente'
              axios
                .put(`${process.env.REACT_APP_ADDRESS}/order/updateState`, {
                  orderIds: [idOrder],
                },{headers: { accessToken: localStorage.getItem("accessToken") }} )
                .then((updateResponse) => {
                  if (updateResponse.data.message) {
                    console.log(updateResponse.data.message);
                    setNewList({ id: idOrder, etat: 'en attente' });
                  } else {
                    console.error('Failed to update order state');
                  }
                })
                .catch((updateError) => {
                  console.error('Error updating order state:', updateError);
                });
  
              
            }
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle the error, e.g., redirect to an error page or show a relevant message to the user.
        });
    }
    // eslint-disable-next-line
  }, [open, idOrder]);

  const handlePayment = () => {
    console.log(localStorage.getItem("accessToken"))
    axios
      .put(`${process.env.REACT_APP_ADDRESS}/order/validate`,{id:idOrder},{headers: { accessToken: localStorage.getItem("accessToken") }})
      .then((response) => {
        if (response.data.error) {
          snackbarSeverity("error") 
          snackbarContent(response.data.error) 
          handleOpenSnackbar()
        } else {
          // Optionally, you can show a success message or close the dialog here
          handleClose();
          snackbarSeverity("success") 
          snackbarContent(response.data.message) 
          handleOpenSnackbar()
          setNewList({id:idOrder,etat: 'terminé'})

        }
      })
      .catch((error) => {
        console.error(error);
        snackbarSeverity("error") 
        snackbarContent(error) 
        handleOpenSnackbar()

      });
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar color="inherit" sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Commande N°{idOrder}
            </Typography>
            {order.state !== "terminé" &&
            <Button autoFocus variant='contained' color="primary" onClick={handlePayment}>
              Payment effectuer
            </Button>
            }
          </Toolbar>
        </AppBar>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: 1, width: '100%' }}
              >
                <Typography variant="h6" sx={{ mr: 1 }} gutterBottom>
                  Numéro de téléphone:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {order.phone}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: 1, width: '100%' }}
              >
                <Typography variant="h6" sx={{ mr: 1 }} gutterBottom>
                  Email:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {order.email}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <List>
            {orderProducts.map((product) => (
              <div key={product.id_product}>
                <ListItem>
                  <ListItemIcon>
                    <Box
                      component="img"
                      alt={product.product.title}
                      src={
                        product.product.images.length > 0
                          ? `${process.env.REACT_APP_ADDRESS}/thumbnail/${product.product.images[0]}`
                          : `/assets/illustrations/indisponible.png`
                      }
                      sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={product.product.title} secondary={`Ref: ${product.product.reference}/ Prix unitaire: ${product.product.price}DA`} />
                  <Stack
                    sx={{ mb: 1, mr: 2 }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    edge="end"
                  >
                    <Typography variant="h6" gutterBottom>
                      Quantité:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {product.quantity}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{ mb: 1 }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    edge="end"
                  >
                    <Typography variant="h6" gutterBottom>
                      Totale:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {product.quantity * product.product.price}
                    </Typography>
                  </Stack>
                </ListItem>
                <Divider />
              </div>
            ))}
            <ListItem>
              <ListItemText />
              <Label color='success'>
                <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                  <Typography variant="h6" sx={{ mr: 1 }} gutterBottom>
                    Totale:
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {totalPrice} DA
                  </Typography>
                </Stack>
              </Label>
            </ListItem>
          </List>
        </Container>
      </Dialog>
    </>
  );
}
