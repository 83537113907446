// client/src/layouts/client/nav/index.js
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Box, Drawer, List, ListItemButton, ListItemText, Button, Popover, Grid, Divider, useMediaQuery, useTheme, Alert, Snackbar, Slide } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import CloseIcon from '@mui/icons-material/Close';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import axios from 'axios';
import { CartContext } from '../../../context/CartContext'; // Import the CartContext
import { ProductCartIcon, ProductCartSidebar } from '../../../sections/@dashboard/products';

const drawerWidth = 240;


function NavBar() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [snackbarContent, setSnackbarContent] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { openCart, handleOpenCart, handleCloseCart } = useContext(CartContext); // Use the context
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ADDRESS}/type`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          setTypeList(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleTypeOpen = (id) => {
    axios
      .get(`${process.env.REACT_APP_ADDRESS}/category/subcategory/${id}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          setCategoryList(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSnackbarContent = (state) => {
    setSnackbarContent(state)
  }
  const handleSnackbarSeverity = (state) => {
    setSnackbarSeverity(state)
  }
  const handleOpenSnackbar = (state) => {
    setOpenSnackbar(state)
  }
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }
  return (
    <>
      <AppBar component="nav" color="secondary">
      <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h6" component="div" sx={{ fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem' }}>
              LOGO
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="subtitle1">
              IMAGE
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
  <Typography color="secondary" variant="subtitle1">
    .
  </Typography>
</Grid>

        </Grid>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 3,
                display: 'flex',
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
              }}
            >
              LOGO
            </Typography>
          </Link>

          <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
            {typeList.map((type) => (
              <PopupState key={type.id} variant="popover" popupId="demo-popup-popover-1">
                {(popupState) => (
                  <Box onClick={() => handleTypeOpen(type.id)}>
                    <Button color='secondary' {...bindTrigger(popupState)}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 500,
                          textTransform: 'none',
                          color: 'white',
                          fontSize: 18,
                          whiteSpace: 'nowrap', // Prevents text from wrapping
                          overflow: 'hidden', // Keeps the overflow content hidden
                          textOverflow: 'ellipsis', // Adds an ellipsis to truncated text
                         }}
                      >
                        {type.name}
                      </Typography>
                    </Button>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Box sx={{ p: 2, width: '100vw', position: 'relative' }}>
                        <Button
                          sx={{
                            position: 'absolute',
                            right: 20,
                            top: 0,
                            color: 'black',
                            zIndex: 1,
                          }}
                          onClick={popupState.close}
                        >
                          <CloseIcon />
                        </Button>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={3}>
                            {categoryList.map((category) => (
                              <Grid key={category.id} item lg={4} md={6} sm={6} xs={12}>
                                <Box>
                                  <Link to={`/products/${type.name}/${category.name}/1`} onClick={popupState.close} style={{ color: 'inherit' }}>
                                    {category.name}
                                  </Link>
                                  {category.subCategories.map((subCategory) => (
                                    <Typography key={subCategory.id} component="div">
                                      {subCategory.name}
                                    </Typography>
                                  ))}
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </Popover>
                  </Box>
                )}
              </PopupState>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <AdbIcon sx={{ display: { sm: 'flex' }, mr: 1 }} />
            <Typography variant="h6" sx={{ display: { sm: 'flex' } }}>
              LOGO
            </Typography>
            <Divider />
            <List>
              {typeList.map((type) => (
                <PopupState key={type.id} variant="popover" popupId="home-popover">
                  {(popupState) => (
                    <Box onClick={() => handleTypeOpen(type.id)}>
                      <ListItemButton
                        aria-describedby={popupState.id}
                        onClick={popupState.open}
                        sx={{
                          textAlign: 'center',
                          width: '100%', // Set width to 100% of the sidebar
                        }}
                      >
                        <ListItemText primary={type.name} />
                      </ListItemButton>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                      >
                        <Box sx={{ p: 2, width: '100vw', position: 'relative' }}>
                          <Button
                            sx={{
                              position: 'absolute',
                              right: 30,
                              top: 0,
                              color: 'black',
                              zIndex: 1,
                            }}
                            onClick={popupState.close}
                          >
                            <CloseIcon />
                          </Button>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={3}>
                              {categoryList.map((category) => (
                                <Grid key={category.id} item lg={4} md={6} sm={6} xs={12}>
                                  <Box>
                                    <Link to={`/products/${type.name}/${category.name}/1`} onClick={popupState.close} style={{ color: 'inherit' }}>
                                      {category.name}
                                    </Link>
                                    {category.subCategories.map((subCategory) => (
                                      <Typography key={subCategory.id} component="div">
                                        {subCategory.name}
                                      </Typography>
                                    ))}
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Box>
                      </Popover>
                    </Box>
                  )}
                </PopupState>
              ))}
            </List>
          </Box>
        </Drawer>
      </nav>

        <Drawer
        anchor="right"
        open={openCart}
        onClose={handleCloseCart}
        PaperProps={{
          sx: { width: isXs ? '100%' : 300, // Set width to 100% for xs screen size
             border: 'none',
              overflow: 'hidden' 
            },
        }}
      >
        <ProductCartSidebar onCloseCart={handleCloseCart} setSnackbarContent={handleSnackbarContent} setSnackbarSeverity={handleSnackbarSeverity} setOpenSnackbar={handleOpenSnackbar} />
      </Drawer>
      <Snackbar open={openSnackbar} TransitionComponent={Slide} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
        <ProductCartIcon onOpenCart={handleOpenCart} />
      </Box>
    </>
  );
}

export default NavBar;
