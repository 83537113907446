import React, { forwardRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'; // Importer PropTypes
import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, TextField, Autocomplete, DialogContent, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';

import { CartContext } from '../../../context/CartContext';
import wilaya from '../../../_mock/wilaya';
import communes from '../../../_mock/communes';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AppOrder({ handleClose, open, handleSnackbarContent, handleSnackbarSeverity, handleCloseCart, handleOpenSnackbar }) {
  const [selectedWilaya, setSelectedWilaya] = useState(null);
  const [filteredCommunes, setFilteredCommunes] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false); // État de chargement
  const { refreshCartLenght } = useContext(CartContext);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, [open]);

  const handleWilayaChange = (event, value) => {
    setSelectedWilaya(value);
    if (value) {
      const filtered = communes.filter((commune) => commune.wilaya_id === value.id);
      setFilteredCommunes(filtered);
    } else {
      setFilteredCommunes([]);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      wilaya: null,
      commune: null,
      email: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Requis'),
      lastName: Yup.string().required('Requis'),
      phone: Yup.number().required('Requis'),
      wilaya: Yup.object().required('Requis'),
      commune: Yup.object().required('Requis'),
      email: Yup.string().email('Adresse e-mail invalide').required('Requis'),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true); // Définir l'état de chargement à true lorsque le formulaire est soumis
      axios.post(`${process.env.REACT_APP_ADDRESS}/order`, { orderDetails: values, cart},{ headers: { accessToken: localStorage.getItem("accessToken") } })
        .then((response) => {
          setLoading(false); // Définir l'état de chargement à false après réception de la réponse
          if (response.data.error) {
            handleSnackbarContent(response.data.error);
            handleSnackbarSeverity('error');
            handleCloseCart();
            handleClose();
            handleOpenSnackbar(true);
          } else {
            resetForm();
            handleCloseCart();
            handleClose();
            handleSnackbarContent(response.data.message);
            handleSnackbarSeverity('success');
            handleOpenSnackbar(true);
            localStorage.removeItem('cart')
            refreshCartLenght()
          }
        }).catch((error) => {
          setLoading(false); // Définir l'état de chargement à false après une erreur
          console.error('Erreur lors de l\'ajout de la commande:', error);
          handleSnackbarContent(error.message);
          handleSnackbarSeverity('error');
          handleClose();
          handleCloseCart();
          handleOpenSnackbar(true);
        });
    },
  });

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar color="inherit" sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => { handleClose(); formik.resetForm() }} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Passer une commande
          </Typography>
          {!loading ?
            <Button autoFocus variant="contained" color="secondary" onClick={formik.handleSubmit}>
              Commander
            </Button>
            :
            <LoadingButton sx={{ mr: 1, mb: 1 }} loading variant="contained" color="inherit">
              Commander
            </LoadingButton>
          }
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label="Prénom*"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            margin="normal"
          />
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            label="Nom*"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            margin="normal"
          />
          <TextField
            fullWidth
            id="phone"
            name="phone"
            label="Numéro de téléphone*"
            type='number'
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            margin="normal"
          />
          <Autocomplete
            disablePortal
            id="wilaya-combo-box"
            options={wilaya}
            getOptionLabel={(option) => `${option.id} - ${option.nom}`}
            value={selectedWilaya}
            onChange={(event, value) => {
              handleWilayaChange(event, value);
              formik.setFieldValue('wilaya', value);
              formik.setFieldValue('commune', null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Wilaya"
                error={formik.touched.wilaya && Boolean(formik.errors.wilaya)}
                helperText={formik.touched.wilaya && formik.errors.wilaya}
                margin="normal"
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="commune-combo-box"
            options={filteredCommunes}
            getOptionLabel={(option) => option.nom}
            value={formik.values.commune}
            onChange={(event, value) => formik.setFieldValue('commune', value)}
            disabled={!selectedWilaya}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Commune"
                error={formik.touched.commune && Boolean(formik.errors.commune)}
                helperText={formik.touched.commune && formik.errors.commune}
                margin="normal"
              />
            )}
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email*"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

AppOrder.propTypes = {
  handleClose: PropTypes.func.isRequired, // Ajouter la validation de prop type pour handleClose
  open: PropTypes.bool.isRequired, // Ajouter la validation de prop type pour open
  handleSnackbarContent: PropTypes.func.isRequired, // Ajouter la validation de prop type pour handleSnackbarContent
  handleSnackbarSeverity: PropTypes.func.isRequired, // Ajouter la validation de prop type pour handleSnackbarSeverity
  handleCloseCart: PropTypes.func.isRequired, // Ajouter la validation de prop type pour handleCloseCart
  handleOpenSnackbar: PropTypes.func.isRequired, // Ajouter la validation de prop type pour handleOpenSnackbar
};
