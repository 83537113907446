import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
// @mui
import {
  Box,
  Stack,
  Button,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { CartContext } from '../../../context/CartContext';

// ----------------------------------------------------------------------
import AppNewsUpdate from '../app/AppNewsUpdate'; // Adjust import if necessary
import AppOrder from '../app/AppOrder';
// ----------------------------------------------------------------------

ShopCartSidebar.propTypes = {
  onCloseCart: PropTypes.func,
  setSnackbarContent: PropTypes.func,
  setSnackbarSeverity: PropTypes.func,
  setOpenSnackbar: PropTypes.func,
};

export default function ShopCartSidebar({ onCloseCart, setSnackbarContent, setSnackbarSeverity, setOpenSnackbar }) {
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0); // Define totalPrice and setTotalPrice
  const [refresh, setRefresh] = useState(false); // Define refresh state
  const [openDialog, setOpenDialog] = useState(false);
  const { getCartCount } = useContext(CartContext);
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const refreshCart = () => {
    setRefresh(prev => !prev); // Toggle refresh state to trigger useEffect
  };
  const handleSnackbarContent = (state) => {
    setSnackbarContent(state);
  };
  const handleSnackbarSeverity = (state) => {
    setSnackbarSeverity(state);
  };
  const handleOpenSnackbar = (state) => {
    setOpenSnackbar(state);
  };

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    if (cart.length !== 0) {
      axios
        .post(`${process.env.REACT_APP_ADDRESS}/product/cart`, { input: cart },{ headers: { accessToken: localStorage.getItem("accessToken") } })
        .then((response) => {
          if (response.data.error) {
            console.error(response.data.error);
          } else {
            localStorage.setItem('cart', JSON.stringify(response.data.modifiedInput));
            setProducts(response.data.products);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setProducts([]);
      setTotalPrice(0); // Reset products and total price if cart is empty
    }
  }, [refresh]); // Add refresh state to dependency array

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
        <Typography variant="h5" sx={{ ml: 1 }}>
          Panier
        </Typography>
        <IconButton onClick={onCloseCart}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>

      <Divider />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          <AppNewsUpdate
            title="News Update"
            list={products}
            totalPrice={totalPrice}  // Pass total price state
            setTotalPrice={setTotalPrice}  // Pass total price setter
            refreshCart={refreshCart}  // Pass refreshCart function
          />
        </Stack>
      </Scrollbar>
      <Divider />
      <Box sx={{ p: 3 }}>
        <Stack sx={{ mb: 1 }} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            Total:
          </Typography>
          <Typography variant="body1">
            {totalPrice} DA
          </Typography>
        </Stack>

        <Button
          fullWidth
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
          onClick={handleOpenDialog}
          disabled={!getCartCount()}
        >
          Valider la commande
        </Button>
        <AppOrder 
          handleClose={handleCloseDialog} 
          open={openDialog} 
          handleSnackbarContent={handleSnackbarContent}
          handleSnackbarSeverity={handleSnackbarSeverity} 
          handleCloseCart={onCloseCart} 
          handleOpenSnackbar={handleOpenSnackbar} 
        />
      </Box>
    </>
  );
}
