import { Navigate, useRoutes } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from "./helpers/AuthContext";

// layouts
import DashboardLayout from './layouts/dashboard';
import ClientLayout from './layouts/client';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import ProductsAdmin from './pages/ProductsAdmin';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import Products from './pages/Products';
import DashboardAppPage from './pages/DashboardAppPage';
import ProductsTest from './pages/Products-test';
import Home from './pages/Home';
import Navigation from './pages/Navigation';
import Brand from './pages/Brand';
import ProductPage from './pages/ProductPage';
import OrderPage from './pages/OrderPage'
// ----------------------------------------------------------------------

export default function Router() {
  const { authState } = useContext(AuthContext);

  const isAuthenticated = authState.isAuthenticated;
  const routes = useRoutes([
    {
      path: '/',
      element: <ClientLayout />,
      children: [
        { element: <Home />, index: true }, // Renders Home component when path is '/'
        { path: 'products/:type/:category/:page', element: <Products /> },
        { path: 'product/:reference', element: <ProductPage /> },

      ],
    },
    {
      path: '/dashboard',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: isAuthenticated ? [
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'product', element: <ProductsAdmin /> },
        { path: 'commande', element: <OrderPage /> },
        { path: 'products', element: <ProductsTest /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'navigation', element: <Navigation /> },
        { path: 'marque', element: <Brand /> },
      ] : [],
    },
    // Redirect authenticated users away from the login page
    {
      path: 'login',
      element: isAuthenticated ? <Navigate to="/dashboard/app" /> : <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={isAuthenticated ? "/dashboard/app" : "/login"} />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
