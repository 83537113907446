import React from 'react';
import { Typography, Grid, Container, Paper, Box } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';

function Footer() {
  return (
    <Box >
      <Paper
        elevation={3}
        sx={{
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          background: 'linear-gradient(180deg, rgba(215,207,192,1) 0%, rgba(238,233,222,1) 100%)',
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
              }}
            >
              LOGO
            </Typography>
          </Grid>
          <Grid item sx={{ display: { sm: 'flex' }, gap: 2, mt: 3 }} xs={12}>
            <Typography color="textSecondary" variant="subtitle1">
              React Router
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              React Router
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              React Router
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              React Router
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              React Router
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              React Router
            </Typography>
          </Grid>
          <Grid item sx={{ display: { sm: 'flex' }, gap: 2, mt: 3 }} xs={12}>
            <FacebookIcon />
            <FacebookIcon />
            <FacebookIcon />
            <FacebookIcon />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default Footer;
