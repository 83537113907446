import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Pagination from '@mui/material/Pagination';
import { CardActionArea } from '@mui/material';
import axios from 'axios';

function Products() {
    const { type, category } = useParams(); // Use useParams to get category and subcategory
    const [page, setPage] = useState(1);
    const [productList, setProductList] = useState([]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    console.log("type:", type);
    console.log("Category:", category);
    useEffect(() => {
        axios
      .get(`${process.env.REACT_APP_ADDRESS}/subcategory/product/${type}/${category}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
        } else {
            setProductList(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle the error, e.g., redirect to an error page or show a relevant message to the Product.
      });
    }, [])
    return (
        <Box>
            <Grid item sx={{display: 'flex',justifyContent: 'center', pt:2}} lg={12} xs={12}>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit">
                            Produits
                        </Link>
                        <Link underline="hover" color="inherit">
                            {type}
                        </Link>
                        <Typography color="text.primary">{category} (<b>Page: {page}</b>)</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid item sx={{pb:2}} lg={12} xs={12}>
                <Typography color="text.primary">Résultats: <b>30 éléments</b></Typography>
            </Grid>
            <Grid container spacing={3}>
                {productList.map((product) => (
                    <Grid key={product.id} item sx={{display: 'flex',justifyContent: 'center'}} lg={4} md={6} sm={6} xs={12}>
                        <Card sx={{ maxWidth: 345, borderRadius: "15px" }}>
                            <CardActionArea sx={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
                                <CardMedia
                                    sx={{ height: 300 }}
                                    image="/assets/images/test/car.jpg"
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {product.title}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="secondary">
                                        {product.price} DA
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {product.brand} 
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions sx={{ borderTop: 1, borderColor: 'grey.500', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Stack direction="row" spacing={1}>
                                    <IconButton color='primary' aria-label="decrease quantity">
                                        <RemoveIcon />
                                    </IconButton>
                                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>2</Typography>
                                    <IconButton color='primary' aria-label="increase quantity">
                                        <AddIcon />
                                    </IconButton>
                                </Stack>
                                <Button size="small" color='secondary' variant="contained">Ajouter au panier</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
                <Grid item sx={{display: 'flex',justifyContent: 'center'}} lg={12} xs={12}>
                    <Pagination count={10} page={page} onChange={handleChange} variant="outlined" color="primary" />
                </Grid>
            </Grid>
        </Box>
    );
}

export default Products;