import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState, forwardRef } from 'react';
import axios from 'axios';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Slide,
  Snackbar,
  Alert
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { OrderListHead, OrderListToolbar, ConsultOrder } from '../sections/@dashboard/order';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const TABLE_HEAD = [
  { id: 'name', label: 'Nom complet', alignRight: false },
  { id: 'wilaya', label: 'Wilaya', alignRight: false },
  { id: 'commune', label: 'Commune', alignRight: false },
  { id: 'createdAt', label: 'Date', alignRight: false },
  { id: 'state', label: 'Etat', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

const stateOrder = ['nouvelle', 'en attente', 'terminé'];

function stateComparator(a, b, order) {
  if (order === 'asc') {
    return stateOrder.indexOf(a.state) - stateOrder.indexOf(b.state);
  }
  return stateOrder.indexOf(b.state) - stateOrder.indexOf(a.state);
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'createdAt') {
    return new Date(b[orderBy]) - new Date(a[orderBy]);
  }
  if (orderBy === 'state') {
    return stateComparator(a, b, 'desc');
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (orderBy === 'state') {
    return order === 'desc'
      ? (a, b) => stateComparator(a, b, 'desc')
      : (a, b) => stateComparator(a, b, 'asc');
  }
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderList, setOrderList] = useState([]); // State to store fetched data
  const [openOrder, setOpenOrder] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);
  const [snackbarContent, setSnackbarContent] = useState(null);

  const handleOpenOrder = () => {
    setOpenOrder(true);
  };

  const handleCloseOrder = () => {
    setOpenOrder(false);
  };
  
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const setNewList = ({id, etat}) => setOrderList((prevOrderList) =>
    prevOrderList.map((order) =>
      order.id === id
        ? { ...order, state: etat }
        : order
    )
  );

  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orderList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleDelete = () => {
    console.log(selected)
    axios
      .post(`${process.env.REACT_APP_ADDRESS}/order/delete`, {
        data: { orderIds: selected }
      },{ headers: { accessToken: localStorage.getItem("accessToken") } })
      .then((response) => {
        if (response.data.error) {
          setSnackbarContent(response.data.error);
          handleOpenSnackbar();
          setSnackbarSeverity("error");
        } else {
          setSnackbarContent(response.data.message);
          handleOpenSnackbar();
          setSnackbarSeverity("success");
          setOrderList(orderList.filter(order => !selected.includes(order.id))); // Filter out deleted orders
          setSelected([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setSnackbarContent(error);
        handleOpenSnackbar();
        setSnackbarSeverity("error");
      });
  };
  

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList.length) : 0;

  const filteredUsers = applySortFilter(orderList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ADDRESS}/order`,{ headers: { accessToken: localStorage.getItem("accessToken") } })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          const orders = response.data.map(order => ({
            id: order.id, // Assuming there's an id field in your response
            name: `${order.firstName} ${order.lastName}`,
            wilaya: order.wilaya,
            commune: order.commune,
            createdAt: order.createdAt,
            state: order.state
          }));
          setOrderList(orders); // Set the fetched data to state
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle the error, e.g., redirect to an error page or show a relevant message to the user.
      });
  }, []);

  return (
    <>
      <Helmet>
        <title> Commandes </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Commandes
          </Typography>
        </Stack>

        <Card>
          <OrderListToolbar deleteSelected={handleDelete} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orderList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, commune, state, wilaya, avatarUrl, createdAt } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" onClick={() => {setOrderId(id)}} selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{wilaya}</TableCell>

                        <TableCell align="left">{commune}</TableCell>

                        <TableCell align="left">
                            {new Date(createdAt).toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })} {new Date(createdAt).toLocaleTimeString('en-GB', {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                        </TableCell>

                        <TableCell align="left">
                          <Label color={state === 'nouvelle' ? 'info' :(state === 'en attente' ? 'secondary' : 'success')}>{state}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenOrder}>
                            <Iconify icon={'eva:eye-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={orderList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Snackbar open={openSnackbar} TransitionComponent={Transition} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
      <ConsultOrder open={openOrder} snackbarSeverity={setSnackbarSeverity} setNewList={setNewList} snackbarContent={setSnackbarContent} handleOpenSnackbar={handleOpenSnackbar} handleClose={handleCloseOrder} idOrder={orderId}/>
    </>
  );
}
