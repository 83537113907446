import PropTypes from 'prop-types';

// @mui
import { Grid, Paper } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};
export default function ProductList({ products, ...other }) {

  

  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              borderRadius: 4,
              transition: 'all 0.3s ease',
              '&:hover': {
                boxShadow: 10
              },
            }}
            elevation={2}
          >
            <ShopProductCard product={product} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
