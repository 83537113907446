import PropTypes from 'prop-types';
import React from 'react';
import { Typography, Stack } from '@mui/material';
import { Icon } from '@iconify/react';

ProductQuantity.propTypes = {
    quantity: PropTypes.number,
};

export default function ProductQuantity({ quantity }) {
    if (quantity >= 8) {
        return (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Icon icon="carbon:battery-full" width="1.8em" height="1.8em" style={{ color: 'green' }} />
            <Typography variant="caption" display="block" gutterBottom>
              Plein
            </Typography>
          </Stack>
        );
    }
    if (quantity >= 4) {
        return (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Icon icon="carbon:battery-half" width="1.8em" height="1.8em" style={{ color: '#7bc404' }} />
            <Typography variant="caption" display="block" gutterBottom>
              {quantity} restants
            </Typography>
          </Stack>
        );
    }
    if (quantity >= 1) {
        return (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Icon icon="carbon:battery-low" width="1.8em" height="1.8em" style={{ color: "#f77000" }} />
            <Typography variant="caption" display="block" gutterBottom>
              {quantity} restant{quantity > 1 ? 's' : ''}
            </Typography>
          </Stack>
        );
    }
    return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Icon icon="carbon:battery-empty" width="1.8em" height="1.8em" style={{ color: 'red' }} />
        <Typography variant="caption" display="block" gutterBottom>
          Indisponible
        </Typography>
      </Stack>
    );
}
