import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import axios from 'axios';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
// helpers
import { AuthContext } from "./helpers/AuthContext";
// context
import { CartProvider } from './context/CartContext';

// ----------------------------------------------------------------------

export default function App() {
  const [loading, setLoading] = useState(true);
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    data: {
      id: 0,
      firstName: '',
      lastName: '',
      email: ''
    }
  });


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_ADDRESS}/user`, { headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {
      if (response.data.error) {
        setAuthState({ isAuthenticated: false, username: ""});
      } else {
        setAuthState({ isAuthenticated: true, 
          data: {
            id: response.data.id,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email
          }  
        });
      }
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching authentication status:", error);
      // Handle the error, e.g., redirect to an error page or show a relevant message to the user.
      setLoading(false);
    });
}, []);

  return (
    <CartProvider>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <HelmetProvider>
          <BrowserRouter>
            <ThemeProvider>
              <ScrollToTop />
              <StyledChart />
              {!loading && (  
              <Router />
              )}
            </ThemeProvider>
          </BrowserRouter>
        </HelmetProvider>
      </AuthContext.Provider>
    </CartProvider>
  );
}
