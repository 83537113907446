import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { AppType, AppCategory, AppSubCategory } from '../sections/@dashboard/app';

function Navigation() {
    const [selectedTypeId, setSelectedTypeId] = useState(0);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);

    const handleTypeSelected = (TypeId) => {
        setSelectedTypeId(TypeId);
    };

    const handleCategorySelected = (CategoryId) => {
        setSelectedCategoryId(CategoryId);
    };

    return (
        <Container maxWidth="xl">
            <Typography variant="h4" sx={{ mb: 5 }}>
                Navigation
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <AppType
                        title="Les types"
                        subheader="Les types dans la barre de navigation"
                        onItemSelected={handleTypeSelected}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <AppCategory
                        title="Les Categories"
                        subheader="Les categories dans la barre de navigation"
                        selectedTypeId={selectedTypeId}
                        onItemSelected={handleCategorySelected}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <AppSubCategory
                        title="Les sous categories"
                        subheader="Les sous categories dans la barre de navigation"
                        selectedCategoryId={selectedCategoryId}
                        selectedTypeId={selectedTypeId}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Navigation;
