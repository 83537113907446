import PropTypes from 'prop-types'; // Import PropTypes
import { useState, useEffect, forwardRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import axios from 'axios';
// @mui
import {
  Card,
  Stack,
  Button,
  Popover,
  MenuItem,
  IconButton,
  CardHeader,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------
AppBrand.propTypes = {
  title: PropTypes.string.isRequired, // Add prop brand validation
  subheader: PropTypes.string.isRequired, // Add prop brand validation
};

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function AppBrand({ title, subheader, ...other }) {
  const [selectedTask, setSelectedTask] = useState(null);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [list, setList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  const handleTaskClick = (taskId) => {
    setSelectedTask(taskId === selectedTask ? null : taskId);
  };

  const handleClickOpenDialogCreate = () => {
    setOpenDialogCreate(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogCreate(false);
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_ADDRESS}/brand`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          setList(response.data);
        }
      }).catch((error) => {
        console.error(error);
        // Handle the error, e.g., redirect to an error page or show a relevant message to the user.
      });
  }, []);

  // Validation schema
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required('Le nom est requis'), // Ensure nom is not empty
  });

  const handleSubmit = (values, { resetForm }) => {
    axios.post(`${process.env.REACT_APP_ADDRESS}/brand`, { name: values.nom},{ headers: { accessToken: localStorage.getItem("accessToken") } })
      .then((response) => {
        if (response.data.error) {
          setSnackbarContent(response.data.error);
          setSnackbarSeverity("error");
          handleCloseDialog();
          setOpenSnackbar(true);
        } else {
          console.log('Marque ajouté avec succès:', response.data.newBrand);
          // Reset form fields
          resetForm();
          // Close the dialog
          handleCloseDialog();
          // Update the list of brands
          setList([...list, response.data.newBrand]);
          setSnackbarContent(response.data.message);
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        }
      }).catch((error) => {
        console.error('Erreur lors de l\'ajout du marque:', error);
        setSnackbarContent(error);
        setSnackbarSeverity("error");
        handleCloseDialog();
        setOpenSnackbar(true);
      });
  };

  const updateList = (updatedTask) => {
    setList(prevList => {
      const updatedList = prevList.map(task => (task.id === updatedTask.id ? updatedTask : task));
      return updatedList;
    });
  };

  const deleteFromList = (deletedTask) => {
    // Filter out the deleted task from the list
    const updatedList = list.filter(task => task.id !== deletedTask.id);
    // Update the list state
    setList(updatedList);
  };

  const responseContent = (content) => {
    setOpenSnackbar(true);
    setSnackbarContent(content);
  };

  const severity = (content) => {
    setSnackbarSeverity(content);
  };

  return (
    <Card {...other}>
      <>
        <CardHeader
          sx={{ mb: 2 }}
          title={title}
          subheader={subheader}
          action={<IconButton onClick={handleClickOpenDialogCreate} aria-label="settings"><Iconify icon={'fa6-solid:plus'} /></IconButton>}
        />
        <Dialog
          TransitionComponent={Transition} 
          open={openDialogCreate}
          onClose={handleCloseDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Ajouter une marque</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {/* Formik wrapper */}
              <Formik
                initialValues={{ nom: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form>
                    {/* Text field for nom */}
                    <Field
                      name="nom"
                      as={TextField}
                      label="Nom"
                      fullWidth
                      autoFocus
                      variant="outlined"
                      margin="normal"
                      error={formik.touched.nom && formik.errors.nom}
                      helperText={<ErrorMessage name="nom" />}
                    />
                    <DialogActions>
                      {/* Cancel button */}
                      <Button onClick={handleCloseDialog} color="primary">Annuler</Button>
                      {/* Submit button, disabled if form is invalid */}
                      <Button type="submit" color="primary" disabled={!formik.isValid || formik.isSubmitting}>Ajouter</Button>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
      <Box sx={{ maxHeight: 400, overflowY: 'auto', pb: 1.5 }}>
        {list.map((task) => (
          <TaskItem
            key={task.id}
            task={task}
            selected={selectedTask === task.id}
            onClick={handleTaskClick}
            updateList={updateList} // Pass the updateList function
            deleteFromList={deleteFromList}
            responseContent={responseContent}
            severity={severity}
          />
        ))}
      </Box>
      <Snackbar open={openSnackbar} TransitionComponent={Slide} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
    </Card>
  );
}

function TaskItem({ task, selected, onClick, updateList, deleteFromList, responseContent, severity }) {
  const [open, setOpen] = useState(null);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required('Le nom est requis'), // Ensure nom is not empty
  });

  const handleClickOpenDialogEdit = () => {
    setOpenDialogEdit(true);
  };

  const handleCloseDialog = () => {
    handleCloseMenu();
    setOpenDialogEdit(false);
    setOpenDialogDelete(false);
  };

  const handleItemClick = () => {
    if (!selected) { // Only call onClick if the item is not already selected
      onClick(task.id); // Notify parent component of the clicked task id
    }
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEdit = () => {
    handleClickOpenDialogEdit();
  };

  const handleDelete = () => {
    setOpenDialogDelete(true);
  };

  const handleUpdateList = (updatedTask) => {
    updateList(updatedTask); // Call the updateList function with the updated task
  };

  const handleConfirmDelete = () => {
    axios.delete(`${process.env.REACT_APP_ADDRESS}/brand/${task.id}`,{ headers: { accessToken: localStorage.getItem("accessToken") } })
      .then((response) => {
        if (response.data.error) {
          responseContent(response.data.error);
          severity("error");
          handleCloseDialog();
        } else {
          console.log('Marque supprimé avec succès');
          // Close the dialog
          handleCloseDialog();
          // Update the list by removing the deleted task
          deleteFromList(task);
          responseContent(response.data.message);
          severity("success");
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la suppression du marque:', error);
        responseContent(error);
        severity("error");
        handleCloseDialog();
      });
  };

  return (
    <Box
      onClick={handleItemClick} // Call handleItemClick when clicked
      sx={{
        px: 2,
        py: 0.75,
      }}
    >
      <Stack direction="row" sx={{
        px: 2,
        py: 0.75,
        backgroundColor: selected ? 'grey.200' : 'transparent', // Change background color if selected
        alignItems: 'center', // Vertically center the content
        cursor: 'pointer',
        borderRadius: 1.5,
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: selected ? 'rgba(207, 207, 207, 0.5)' : 'rgba(240, 240, 240, 0.5)', // Darken the background color on hover
        },
      }}>
        <Typography sx={{ flexGrow: 1, m: 0 }}>
          {task.name}
        </Typography>

        <IconButton size="large" color="inherit" sx={{ opacity: 0.48 }} onClick={handleOpenMenu}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <>
            <MenuItem onClick={handleEdit}>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Modifier
            </MenuItem>
            <Dialog open={openDialogEdit} TransitionComponent={Transition} keepMounted onClose={handleCloseDialog} aria-describedby="alert-dialog-slide-description">
              <DialogTitle>Modifier la marque</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {/* Formik wrapper */}
                  <Formik
                    initialValues={{ nom: task.name }} // Set initial values to the task name
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      axios.put(`${process.env.REACT_APP_ADDRESS}/brand/${task.id}`, { name: values.nom},{ headers: { accessToken: localStorage.getItem("accessToken") } })
                        .then((response) => {
                          if (response.data.error) {
                            responseContent(response.data.error);
                            severity("error");
                            handleCloseDialog();
                          } else {
                            console.log('Marque mis à jour avec succès:', response.data);
                            // Close the dialog
                            handleCloseDialog();
                            // Update the list with the edited task
                            handleUpdateList(response.data.newBrand); // Call the updateList function with the updated task
                            responseContent(response.data.message);
                            severity("success");
                          }
                        })
                        .catch((error) => {
                          console.error('Erreur lors de la mise à jour du marque:', error);
                          responseContent(error);
                          severity("error");
                          handleCloseDialog();
                        });
                    }}
                  >
                    {(formik) => (
                      <Form>
                        {/* Text field for nom */}
                        <Field
                          name="nom"
                          as={TextField}
                          label="Nom"
                          fullWidth
                          autoFocus
                          variant="outlined"
                          margin="normal"
                          error={formik.touched.nom && formik.errors.nom}
                          helperText={<ErrorMessage name="nom" />}
                        />
                        <DialogActions>
                          {/* Cancel button */}
                          <Button onClick={handleCloseDialog}>Annuler</Button>
                          {/* Submit button, disabled if form is invalid */}
                          <Button type="submit" color="primary" disabled={!formik.isValid || formik.isSubmitting}>Modifier</Button>
                        </DialogActions>
                      </Form>
                    )}
                  </Formik>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </>
          <>
            <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Supprimer
            </MenuItem>
            <Dialog
              open={openDialogDelete}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseDialog}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>Supprimer la marque</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Etes-vous sûr de vouloir supprimer cette marque?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Annuler</Button>
                <Button color="error" onClick={() => {
                  handleCloseDialog();
                  handleConfirmDelete();
                }}>Supprimer</Button>
              </DialogActions>
            </Dialog>
          </>
        </Popover>
      </Stack>
    </Box>
  );
}

TaskItem.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  deleteFromList: PropTypes.func.isRequired,
  responseContent: PropTypes.func.isRequired,
  severity: PropTypes.func.isRequired,
};
