import React from 'react';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet-async';

import { AppBrand } from '../sections/@dashboard/app';

function Brand() {

    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>Marque</title>
            </Helmet>
            <AppBrand
                title="Les marques"
                subheader="Les marques des produits"
            />
        </Container>
    );
}

export default Brand;
